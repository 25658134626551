import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import Button from '../../components/button/Button';
import {
  UpdateBrandInfo,
  UpdateBankInfo,
  GetBankInfo,
} from '../../features/partnerSlice';
import { fetchUserDetails, setUserStatus } from '../../features/authSlice';
import { useAppDispatch } from '../../app/hooks';
import { RootState } from '../../app/store';
import { useSelector } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';
import Spinner from '../spinner/Spinner';
import RedStar from '../RedStar';

interface FormData {
  orgDisplayName: string;
  partnerId: string | null;
  primaryColor: string;
  secondaryColor: string;
  websiteLink?: string | null;
  facebookLink?: string | null;
  instagramLink?: string | null;
  other?: string | null;
  logo: FileList;
  logoMobile?: FileList;
  logoMonochrome?: FileList;
  icon?: FileList;
  tmCertificate?: FileList;
  additionalDocument?: FileList;
  reEnterAccountNumber: string;
  einNumber: string;
  accountHolderName: string;
  bankName: string;
  routingNumber: string;
  accountNumber: string;
  swiftCode?: string | null;
  bankEmail?: string | null;
  bankPhone?: string | null;
  iban?: string | null;
  tin?: string | null;
  socialMedia?: string;
  brandInfo?: string;
}

const BrandingInformation = ({
  brandInfoPage = false,
  payoutAccountPage = false,
}: {
  brandInfoPage?: boolean;
  payoutAccountPage?: boolean;
}) => {
  const [partnerId, setPartnerId] = useState<string | null>(null);
  const [isLogoUpdated, setIsLogoUpdated] = useState(false);
  const [isLogoMobileUpdated, setIsLogoMobileUpdated] = useState(false);
  const [isLogoMonochromeUpdated, setIsLogoMonochromeUpdated] = useState(false);
  const [isTMCertificateUpdated, setIsTMCertificateUpdated] = useState(false);
  const [isAdditionalDocumentUpdated, setIsAdditionalDocumentUpdated] =
    useState(false);
  const isLoading = useSelector((state: RootState) => state.partner.loading);
  const userId = useSelector((state: RootState) => state.auth.user?.id);
  interface ModalProps {
    isLoading: boolean;
    children: React.ReactNode;
  }
  const Modal: React.FC<ModalProps> = ({ children, isLoading }) => {
    if (!isLoading) return null;

    return (
      <div className='fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50'>
        <div
          className={`bg-[#fff0] p-8 rounded-lg  relative w-[full] md:w-[27rem] px-4 md:px-8 py-12 text-center m-4 flex justify-center`}
        >
          <p className='text-base text-gray-700'>{children}</p>
        </div>
      </div>
    );
  };

  //Fetch Brand Info and bank Info
  const BrandInfoState = useSelector(
    (state: RootState) => state.partner.brandInfo
  );
  const BankInfoState = useSelector(
    (state: RootState) => state.partner.paymentInfo
  );

  const userDetails: any = localStorage.getItem('userDetails');
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      partnerId,
    },
  });

  const extractFileName = (url: any) => {
    return url.split('/').pop();
  };

  const [primaryColorValue, setPrimaryColorValue] = useState('#1133BB');
  const handlePrimaryColorChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPrimaryColorValue(event.target.value);
  };

  const [secondaryColorValue, setSecondaryColorValue] = useState('#00C389');
  const handleSecondaryColorChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSecondaryColorValue(event.target.value);
  };

  useEffect(() => {
    dispatch(GetBankInfo());
  }, [dispatch]);

  useEffect(() => {
    if (BrandInfoState) {
      // Set values for brand info

      // setValue('primaryColor', BrandInfoState.primaryColor);
      setValue('orgDisplayName', BrandInfoState.orgDisplayName);
      setValue(
        'einNumber',
        BrandInfoState.einNumber === 0 ? '' : BrandInfoState.einNumber
      );
      // setValue('secondaryColor', BrandInfoState.secondaryColor);
      setValue('websiteLink', BrandInfoState.websiteLink || '');
      setValue('facebookLink', BrandInfoState.facebookLink || '');
      setValue('instagramLink', BrandInfoState.instagramLink || '');
      setValue('other', BrandInfoState.other || '');

      // Set file upload fields with the filename extracted from URL
      if (BrandInfoState.primaryColor)
        setPrimaryColorValue(BrandInfoState.primaryColor);
      if (BrandInfoState.secondaryColor)
        setSecondaryColorValue(BrandInfoState.secondaryColor);
      if (BrandInfoState.logo) {
        setLogoFileName(extractFileName(BrandInfoState.logo));
        setValue('logo', extractFileName(BrandInfoState.logo));
      }
      if (BrandInfoState.logoMobile) {
        setMobileLogoFileName(extractFileName(BrandInfoState.logoMobile));
        setValue('logoMobile', extractFileName(BrandInfoState.logoMobile));
      }
      if (BrandInfoState.logoMonochrome) {
        setMonochromeLogoFileName(
          extractFileName(BrandInfoState.logoMonochrome)
        );
        setValue(
          'logoMonochrome',
          extractFileName(BrandInfoState.logoMonochrome)
        );
      }
      if (BrandInfoState.tmCertificate) {
        setTMCertificateFileName(extractFileName(BrandInfoState.tmCertificate));
        setValue(
          'tmCertificate',
          extractFileName(BrandInfoState.tmCertificate)
        );
      }
      if (BrandInfoState.additionalDocument) {
        setAdditionalDocumentFileName(
          extractFileName(BrandInfoState.additionalDocument)
        );
        setValue(
          'additionalDocument',
          extractFileName(BrandInfoState.additionalDocument)
        );
      }
    }

    if (BankInfoState) {
      setValue('accountHolderName', BankInfoState.accountHolderName || '');
      setValue('bankName', BankInfoState.bankName || '');
      setValue('routingNumber', BankInfoState.routingNumber || '');
      setValue('accountNumber', BankInfoState.accountNumber || '');
      if (BankInfoState.accountNumber) {
        setValue('reEnterAccountNumber', BankInfoState.accountNumber || '');
      }
      setValue('swiftCode', BankInfoState.swiftCode || '');
      setValue('bankEmail', BankInfoState.email || '');
      setValue('bankPhone', BankInfoState.phone || '');
      setValue('tin', BankInfoState.tin || '');
      setValue('iban', BankInfoState.iban || '');
    }
  }, [BrandInfoState, BankInfoState, setValue]);

  useEffect(() => {
    if (userDetails) {
      const userObject = JSON.parse(userDetails);
      setPartnerId(userObject.partnerId);
    }
  }, [userDetails]);

  const [isDraft, setIsDraft] = useState(true);

  const handleSaveDraft = async () => {
    clearErrors();
    setIsDraft(true);
    console.log('getValuesgetValues', getValues());
    const formData = getValues();
    SaveForm(formData);
  };

  const handleSaveSubmit = async () => {
    setIsDraft(false);
    await new Promise(resolve => {
      setTimeout(resolve, 0);
    });

    handleSubmit(onSubmit)();
  };

  // const formDataToObject = (formData:any) => {
  //   const obj:any = {};
  //   formData.forEach((value:any, key:any) => {
  //     obj[key] = value;
  //   });
  //   return obj;
  // };

  const SaveForm = async (data: FormData) => {
    try {
      if (!partnerId) return;

      const brandFormData = new FormData();
      brandFormData.append('partnerId', partnerId);
      brandFormData.append('orgDisplayName', data.orgDisplayName || '');
      brandFormData.append('einNumber', data.einNumber);
      brandFormData.append('primaryColor', primaryColorValue);
      brandFormData.append('secondaryColor', secondaryColorValue);
      brandFormData.append('websiteLink', data.websiteLink || '');
      brandFormData.append('facebookLink', data.facebookLink || '');
      brandFormData.append('instagramLink', data.instagramLink || '');
      brandFormData.append('other', data.other || '');

      for (const key in data) {
        if (key === 'logo') {
          if (isLogoUpdated && data.logo) {
            brandFormData.append('logo', data[key][0]);
          }
        }
        if (key === 'logoMobile') {
          if (isLogoMobileUpdated && data.logoMobile) {
            brandFormData.append('logoMobile', data.logoMobile[0]);
          }
        }
        if (key === 'logoMonochrome') {
          if (isLogoMonochromeUpdated && data.logoMonochrome) {
            brandFormData.append('logoMonochrome', data.logoMonochrome[0]);
          }
        }
        if (key === 'tmCertificate') {
          if (isTMCertificateUpdated && data.tmCertificate) {
            brandFormData.append('tmCertificate', data.tmCertificate[0]);
          }
        }
        if (key === 'additionalDocument') {
          if (isAdditionalDocumentUpdated && data.additionalDocument) {
            brandFormData.append(
              'additionalDocument',
              data.additionalDocument[0]
            );
          }
        }
      }

      const bankFormData = new FormData();
      bankFormData.append('partnerId', partnerId);
      bankFormData.append('accountHolderName', data.accountHolderName || '');
      bankFormData.append('bankName', data.bankName || '');
      bankFormData.append('routingNumber', data.routingNumber || '');
      bankFormData.append('accountNumber', data.accountNumber || '');
      bankFormData.append('swiftCode', data.swiftCode || '');
      bankFormData.append('email', data.bankEmail || '');
      bankFormData.append('phone', data.bankPhone || '');
      bankFormData.append('tin', data.tin || '');
      bankFormData.append('iban', data.iban || '');

      let isBrandFormEmpty = true;
      let isBankFormEmpty = true;

      for (let pair of brandFormData.entries() as any) {
        console.log(pair);
        isBrandFormEmpty = false;
        break;
      }
      for (let pair of bankFormData.entries() as any) {
        console.log(pair);
        isBankFormEmpty = false;
        console.log('🚀 ~ SaveForm ~ isBankFormEmpty:', isBankFormEmpty);
        break;
      }
      if (!isBrandFormEmpty) dispatch(UpdateBrandInfo(brandFormData));
      dispatch(UpdateBankInfo(partnerId, bankFormData));
      dispatch(fetchUserDetails(userId as any));
    } catch (error) {
      console.error(error);
      alert('Error submitting form. Please try again later.');
    } finally {
      reset(); // Reset the form after saving
    }
  };

  const onSubmit: SubmitHandler<FormData> = async data => {
    try {
      if (!partnerId) return;

      const brandFormData = new FormData();
      brandFormData.append('partnerId', partnerId);
      brandFormData.append('orgDisplayName', data.orgDisplayName || '');
      brandFormData.append('einNumber', data.einNumber);
      brandFormData.append('primaryColor', primaryColorValue);
      brandFormData.append('secondaryColor', secondaryColorValue);
      brandFormData.append('websiteLink', data.websiteLink || '');
      brandFormData.append('facebookLink', data.facebookLink || '');
      brandFormData.append('instagramLink', data.instagramLink || '');
      brandFormData.append('other', data.other || '');
      brandFormData.append('brandInfo', 'completed');
      for (const key in data) {
        if (key === 'logo') {
          if (isLogoUpdated && data.logo) {
            brandFormData.append('logo', data[key][0]);
          }
        }
        if (key === 'logoMobile') {
          if (isLogoMobileUpdated && data.logoMobile) {
            brandFormData.append('logoMobile', data.logoMobile[0]);
          }
        }
        if (key === 'logoMonochrome') {
          if (isLogoMonochromeUpdated && data.logoMonochrome) {
            brandFormData.append('logoMonochrome', data.logoMonochrome[0]);
          }
        }
        if (key === 'tmCertificate') {
          if (isTMCertificateUpdated && data.tmCertificate) {
            brandFormData.append('tmCertificate', data.tmCertificate[0]);
          }
        }
        if (key === 'additionalDocument') {
          if (isAdditionalDocumentUpdated && data.additionalDocument) {
            brandFormData.append(
              'additionalDocument',
              data.additionalDocument[0]
            );
          }
        }
      }
      const bankFormData = new FormData();
      bankFormData.append('partnerId', partnerId);
      bankFormData.append('accountHolderName', data.accountHolderName);
      bankFormData.append('bankName', data.bankName);
      bankFormData.append('routingNumber', data.routingNumber);
      bankFormData.append('accountNumber', data.accountNumber);
      bankFormData.append('swiftCode', data.swiftCode || '');
      bankFormData.append('email', data.bankEmail || '');
      bankFormData.append('phone', data.bankPhone || '');
      bankFormData.append('tin', data.tin || '');
      bankFormData.append('iban', data.iban || '');

      let isBrandFormEmpty = true;
      let isBankFormEmpty = true;

      for (let pair of brandFormData.entries() as any) {
        console.log(pair);
        isBrandFormEmpty = false;
        break;
      }
      for (let pair of bankFormData.entries() as any) {
        console.log(pair);
        isBankFormEmpty = false;
        break;
      }
      if (!isBrandFormEmpty) {
        const updateBrand: any = await dispatch(UpdateBrandInfo(brandFormData));
        if (updateBrand.status === 200) {
          dispatch(fetchUserDetails(userId as any));
          dispatch(setUserStatus('inreview'));
        }
      }
      if (!isBankFormEmpty) {
        dispatch(UpdateBankInfo(partnerId, bankFormData));
      }

      // toast.success('Form submitted successfully');
    } catch (error) {
      console.error(error);
      toast.error('Error submitting form. Please try again later.');
    }
  };

  const [logoFileName, setLogoFileName] = useState('');
  const handleLogoFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setLogoFileName(event.target.files[0].name);
      setIsLogoUpdated(true);
    }
  };

  const [logoMobileFileName, setMobileLogoFileName] = useState('');
  const handleMobileLogoFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files[0]) {
      setMobileLogoFileName(event.target.files[0].name);
      setIsLogoMobileUpdated(true);
    }
  };

  const [logoMonochromeFileName, setMonochromeLogoFileName] = useState('');
  const handleMonochromeLogoFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files[0]) {
      setMonochromeLogoFileName(event.target.files[0].name);
      setIsLogoMonochromeUpdated(true);
    }
  };

  const [tmCertificateFileName, setTMCertificateFileName] = useState('');
  const handleTMCertificateFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files[0]) {
      setTMCertificateFileName(event.target.files[0].name);
      setIsTMCertificateUpdated(true);
    }
  };

  const [additionalDocumentFileName, setAdditionalDocumentFileName] =
    useState('');
  const handleAdditionalDocumentFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files[0]) {
      setAdditionalDocumentFileName(event.target.files[0].name);
      setIsAdditionalDocumentUpdated(true);
    }
  };

  return (
    <div>
      {!brandInfoPage && !payoutAccountPage && (
        <>
          <h2 className=' mt-[-8rem] mb-[7rem] text-[1.5rem] block md:hidden'>
            Program Information
          </h2>
          <h4 className='mt-[-6rem] mb-8 md:text-left font-normal text-[#808080] leading-8'>
            To complete your registration, please fill in the sections below and
            upload the required logos and documents. If you don't have all the
            information at this time, you can press 'Save' to continue later and
            submit when completed.
          </h4>
        </>
      )}
      <Toaster position='top-center' />
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Name Field */}

        {!payoutAccountPage && (
          <div className=' justify-center max-w-[full] mx-auto primary-shadow rounded-lg'>
            <div className='bg-white primary-shadow rounded-lg w-full'>
              <div className='mb-8'>
                <div className='mb-6 pb-4'>
                  <h2 className='text-white bg-primary p-4 px-8 rounded-t-lg text-lg font-semibold'>
                    Branding
                  </h2>
                  <div className='mb-0 mt-4 px-4 md:px-8 md:py-4'>
                    <p className='text-base mb-8 text-[#343434] '>
                      Enter your organization's name to displayed on the app and
                      website. Upload a high-resolution logo in either JPEG or
                      PNG format. Specify your brand's primary and secondary
                      colors using hex codes.
                    </p>
                    <div className='grid md:grid-cols-2 gap-x-8 gap-y-4 pb-4 mb-6'>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Organization Display Name <RedStar />
                        </label>
                        <input
                          type='text'
                          id='orgDisplayName'
                          className='register-input w-full'
                          {...register('orgDisplayName', {
                            ...(isDraft
                              ? {}
                              : {
                                  required:
                                    'Organization display name is required',
                                }),
                          })}
                        />

                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.orgDisplayName?.message}
                        </p>
                      </div>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Upload Logo <RedStar />
                        </label>
                        <div className='flex items-center w-full'>
                          {/* Input field to display selected file name */}
                          <input
                            type='text'
                            value={logoFileName}
                            readOnly
                            placeholder='No file selected'
                            className='register-input w-4/5 rounded-r-[0px]'
                          />

                          {/* Hidden file input */}
                          <input
                            type='file'
                            id='logo'
                            accept='image/png, image/jpeg'
                            className='hidden'
                            {...register('logo', {
                              ...(isDraft
                                ? {}
                                : {
                                    required: !logoFileName
                                      ? 'Logo is required'
                                      : false,
                                  }),
                              onChange: handleLogoFileChange,
                            })}
                          />

                          {/* Label styled as a button */}
                          <label
                            htmlFor='logo'
                            className='w-1/5 text-[0.75rem] cursor-pointer py-2 md:py-4 px-1 bg-[#aeaeae]  text-white text-center rounded-r-[4px] hover:bg-[#aeaeae]'
                          >
                            Choose File
                          </label>
                        </div>
                        <p className='text-[0.75rem] mt-1 text-[#878787] '>
                          Upload your organization's logo to be featured in the
                          app.
                        </p>
                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.logo?.message}
                        </p>
                      </div>
                    </div>
                    {/* Additional logo */}
                    <div className='grid md:grid-cols-2 gap-x-8 gap-y-4'>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Upload Mobile Logo or Favicon
                        </label>
                        <div className='flex items-center w-full'>
                          {/* Input field to display selected file name */}
                          <input
                            type='text'
                            value={logoMobileFileName}
                            readOnly
                            placeholder='No file selected'
                            className='register-input w-4/5 rounded-r-[0px]'
                          />

                          {/* Hidden file input */}
                          <input
                            type='file'
                            id='logoMobile'
                            className='hidden'
                            {...register('logoMobile', {
                              onChange: handleMobileLogoFileChange,
                            })}
                          />

                          {/* Label styled as a button */}
                          <label
                            htmlFor='logoMobile'
                            className='w-1/5 text-[0.75rem] cursor-pointer py-2 md:py-4 px-1 bg-[#aeaeae]  text-white text-center rounded-r-[4px] hover:bg-[#aeaeae]'
                          >
                            Choose File
                          </label>
                        </div>
                        <p className='text-[0.75rem] mt-1 text-[#878787] '>
                          Optionally, upload a smaller logo or icon for optimal
                          display on mobile screens.
                        </p>
                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.logoMobile?.message}
                        </p>
                      </div>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Upload Monochrome Logo
                        </label>
                        <div className='flex items-center w-full'>
                          {/* Input field to display selected file name */}
                          <input
                            type='text'
                            value={logoMonochromeFileName}
                            readOnly
                            placeholder='No file selected'
                            className='register-input w-4/5 rounded-r-[0px]'
                          />

                          {/* Hidden file input */}
                          <input
                            type='file'
                            id='logoMonochrome'
                            className='hidden'
                            {...register('logoMonochrome', {
                              onChange: handleMonochromeLogoFileChange,
                            })}
                          />

                          {/* Label styled as a button */}
                          <label
                            htmlFor='logoMonochrome'
                            className='w-1/5 text-[0.75rem] cursor-pointer py-2 md:py-4 px-1 bg-[#aeaeae]  text-white text-center rounded-r-[4px] hover:bg-[#aeaeae]'
                          >
                            Choose File
                          </label>
                        </div>
                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.logoMonochrome?.message}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* Row 2 */}
                  <div className='mb-0 mt-4 px-4 md:px-8 md:py-4'>
                    <div className='grid md:grid-cols-2 gap-x-8 gap-y-4'>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Primary Color (Hex) <RedStar />
                        </label>
                        <div className='flex items-center w-full space-x-2'>
                          {/* Text Input */}
                          <input
                            type='text'
                            id='primaryColor'
                            className='register-input w-full border border-gray-300 rounded-md px-3 py-2'
                            value={primaryColorValue}
                            {...register('primaryColor')}
                            readOnly
                          />

                          {/* Color Picker */}
                          <input
                            type='color'
                            value={primaryColorValue}
                            id='color'
                            className='w-1/5 h-10 cursor-pointer '
                            onChange={handlePrimaryColorChange}
                          />
                        </div>
                        <p className='text-[0.75rem] mt-1 text-[#878787] '>
                          Add your primary and secondary colors to be used
                          throughout your loyalty program.
                        </p>
                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.primaryColor?.message}
                        </p>
                      </div>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Secondary Color (Hex) <RedStar />
                        </label>
                        <div className='flex items-center w-full space-x-2'>
                          {/* Text Input */}
                          <input
                            type='text'
                            id='secondaryColor'
                            className='register-input w-full border border-gray-300 rounded-md px-3 py-2'
                            value={secondaryColorValue}
                            {...register('secondaryColor')}
                            readOnly
                          />

                          {/* Color Picker */}
                          <input
                            type='color'
                            value={secondaryColorValue}
                            id='color'
                            className='w-1/5 h-10  cursor-pointer'
                            onChange={handleSecondaryColorChange}
                          />
                        </div>
                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.secondaryColor?.message}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Social Media Info */}
        {!payoutAccountPage && (
          <div className='justify-center max-w-[full] mx-auto primary-shadow rounded-lg'>
            <div className='bg-white primary-shadow rounded-lg w-full'>
              <div className='mb-8'>
                <div className='mb-6 pb-4'>
                  <h2 className='text-white bg-primary p-4 px-8 rounded-t-lg text-lg font-semibold'>
                    Social Media Info
                  </h2>
                  <div className='mb-0 mt-4 px-4 md:px-8 md:py-4'>
                    <p className='text-base mb-8 text-[#878787]'>
                      Enter your organization's website and social media links
                      here.
                    </p>
                    <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[2px] mb-[5px]'>
                      {errors.socialMedia?.message}
                    </p>
                    <div className='grid md:grid-cols-2 gap-x-8 gap-y-4'>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Website <RedStar />
                        </label>
                        <input
                          type='text'
                          id='websiteLink'
                          className='register-input w-full'
                          {...register('websiteLink', {
                            ...(isDraft
                              ? {}
                              : { required: 'Website Link is required' }),
                          })}
                        />
                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.websiteLink?.message}
                        </p>
                      </div>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Facebook
                        </label>
                        <input
                          type='text'
                          id='facebookLink'
                          className='register-input w-full'
                          {...register('facebookLink')}
                        />
                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.facebookLink?.message}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* Row 2 */}
                  <div className='mb-0 mt-4 px-4 md:px-8 md:py-4'>
                    <div className='grid md:grid-cols-2 gap-x-8 gap-y-4'>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Instagram
                        </label>
                        <input
                          type='text'
                          id='instagramLink'
                          className='register-input w-full'
                          {...register('instagramLink')}
                        />
                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.instagramLink?.message}
                        </p>
                      </div>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Other
                        </label>
                        <input
                          type='text'
                          id='other'
                          className='register-input w-full'
                          {...register('other')}
                        />
                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.other?.message}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Payout Account */}
        {!brandInfoPage && (
          <div className='justify-center max-w-[full] mx-auto primary-shadow rounded-lg mt-8'>
            <div className='bg-white primary-shadow rounded-lg w-full'>
              <div className='mb-8'>
                <div className='mb-6 pb-4'>
                  <h2 className='text-white bg-primary p-4 px-8 rounded-t-lg text-lg font-semibold'>
                    Payout Account
                  </h2>
                  <div className='mb-0 mt-4 px-4 md:px-8 md:py-4'>
                    <p className='text-base mb-8 text-[#343434]'>
                      Enter your bank account details to receive payouts.
                    </p>
                    <div className='grid md:grid-cols-2 gap-x-8 gap-y-4 py-4'>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Account Holder Name <RedStar />
                        </label>
                        <input
                          type='text'
                          id='accountHolderName'
                          className='register-input w-full'
                          {...register('accountHolderName', {
                            ...(isDraft
                              ? {}
                              : {
                                  required: 'Account Holder Name is required',
                                }),
                          })}
                        />
                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.accountHolderName?.message}
                        </p>
                      </div>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Sales Tax Number
                        </label>
                        <input
                          type='text'
                          id='tin'
                          className='register-input w-full'
                          {...register('tin')}
                        />
                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.tin?.message}
                        </p>
                      </div>
                    </div>

                    <div className='grid md:grid-cols-2 gap-x-8 gap-y-4 mt-4 py-4'>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Bank Name <RedStar />
                        </label>
                        <input
                          type='text'
                          id='bankName'
                          className='register-input w-full'
                          {...register('bankName', {
                            ...(isDraft
                              ? {}
                              : { required: 'Bank Name is required' }),
                          })}
                        />
                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.bankName?.message}
                        </p>
                      </div>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Routing Number <RedStar />
                        </label>
                        <input
                          type='text'
                          id='routingNumber'
                          className='register-input w-full'
                          {...register('routingNumber', {
                            ...(isDraft
                              ? {}
                              : { required: 'Routing Number is required' }),
                          })}
                        />
                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.routingNumber?.message}
                        </p>
                      </div>
                    </div>

                    <div className='grid md:grid-cols-2 gap-x-8 gap-y-4 mt-4 py-4'>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Account Number <RedStar />
                        </label>
                        <input
                          type='text'
                          id='accountNumber'
                          className='register-input w-full'
                          {...register('accountNumber', {
                            ...(isDraft
                              ? {}
                              : { required: 'Account Number is required' }),
                          })}
                        />
                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.accountNumber?.message}
                        </p>
                      </div>

                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Re-enter Account Number <RedStar />
                        </label>
                        <input
                          type='text'
                          id='reEnterAccountNumber'
                          className='register-input w-full'
                          {...register('reEnterAccountNumber', {
                            ...(isDraft
                              ? {}
                              : {
                                  required:
                                    'Re-entering your account number is required',
                                }),
                            validate: value =>
                              value === getValues('accountNumber') ||
                              'Account numbers do not match',
                          })}
                        />
                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.reEnterAccountNumber?.message}
                        </p>
                      </div>
                    </div>

                    <div className='grid md:grid-cols-2 gap-x-8 gap-y-4 mt-4 py-4'>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Swift Code
                        </label>
                        <input
                          type='text'
                          id='swiftCode'
                          className='register-input w-full'
                          {...register('swiftCode')}
                        />
                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.swiftCode?.message}
                        </p>
                      </div>

                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          IBAN
                        </label>
                        <input
                          type='text'
                          id='iban'
                          className='register-input w-full'
                          {...register('iban')}
                        />
                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.iban?.message}
                        </p>
                      </div>
                    </div>

                    <div className='grid md:grid-cols-2 gap-x-8 gap-y-4 mt-4 py-4'>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Registered Email
                        </label>
                        <input
                          type='email'
                          id='bankEmail'
                          className='register-input w-full'
                          {...register('bankEmail')}
                        />
                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.bankEmail?.message}
                        </p>
                      </div>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Registered Contact Number
                        </label>
                        <input
                          type='text'
                          id='bankPhone'
                          className='register-input w-full'
                          {...register('bankPhone', {
                            pattern: {
                              value:
                                /^\+?[0-9]{1,4}[\s-]?\(?[0-9]{1,5}\)?[\s-]?[0-9]{1,9}[\s-]?[0-9]{1,9}$/,
                              message: 'Invalid phone number format',
                            },
                          })}
                        />
                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.bankPhone?.message}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* AdditionalInfo */}
        {!payoutAccountPage && (
          <div className=' justify-center max-w-[full] mx-auto primary-shadow rounded-lg'>
            <div className='bg-white primary-shadow rounded-lg w-full'>
              <div className='mb-8'>
                <div className='mb-6  pb-4'>
                  <h2 className='text-white bg-primary p-4 px-8 rounded-t-lg text-lg font-semibold'>
                    Additional Program Info
                  </h2>
                  <div className='mb-0 mt-4 px-4 md:px-8 md:py-4'>
                    <p className='text-base mb-8 text-[#878787] '>
                      Enter your organization's TIN/EIN (Taxpayer / Employer
                      Identification Number). Upload your organization's Company
                      Registration / Incorporation Certificate and Trademark
                      Certificate(s), if any.
                    </p>

                    {/* row 1 */}
                    <div className='grid md:grid-cols-2 gap-x-8 gap-y-4 md:mb-6 mb-4'>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Trademark Certificate
                        </label>
                        <div className='flex items-center w-full'>
                          {/* Input field to display selected file name */}
                          <input
                            type='text'
                            value={tmCertificateFileName}
                            readOnly
                            placeholder='No file selected'
                            className='register-input w-4/5 rounded-r-[0px]'
                          />

                          {/* Hidden file input */}
                          <input
                            type='file'
                            id='tmCertificate'
                            className='hidden'
                            {...register('tmCertificate', {
                              onChange: handleTMCertificateFileChange,
                            })}
                          />

                          {/* Label styled as a button */}
                          <label
                            htmlFor='tmCertificate'
                            className='w-1/5 text-[0.75rem] cursor-pointer py-2 md:py-4 px-1 bg-[#aeaeae]  text-white text-center rounded-r-[4px] hover:bg-[#aeaeae]'
                          >
                            Choose File
                          </label>
                        </div>
                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.tmCertificate?.message}
                        </p>
                      </div>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          TIN/EIN Number <RedStar />
                        </label>
                        <input
                          type='text'
                          id='einNumber'
                          className='register-input w-full'
                          {...register('einNumber', {
                            ...(isDraft
                              ? {}
                              : { required: 'TIN/EIN Number is required' }),
                          })}
                        />
                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.einNumber?.message}
                        </p>
                      </div>
                    </div>

                    {/* Additional Info - row 2 */}
                    <div className='grid md:grid-cols-2 gap-x-8 gap-y-4'>
                      <div>
                        <label className='block text-[#000000] text-sm font-[500] mb-2'>
                          Additional Documents, if any
                        </label>
                        <div className='flex items-center w-full'>
                          {/* Input field to display selected file name */}
                          <input
                            type='text'
                            value={additionalDocumentFileName}
                            readOnly
                            placeholder='No file selected'
                            className='register-input w-4/5 rounded-r-[0px]'
                          />

                          {/* Hidden file input */}
                          <input
                            type='file'
                            id='additionalDocument'
                            className='hidden'
                            {...register('additionalDocument', {
                              onChange: handleAdditionalDocumentFileChange,
                            })}
                          />

                          {/* Label styled as a button */}
                          <label
                            htmlFor='additionalDocument'
                            className='w-1/5 text-[0.75rem] cursor-pointer py-2 md:py-4 px-1 bg-[#aeaeae]  text-white text-center rounded-r-[4px] hover:bg-[#aeaeae]'
                          >
                            Choose File
                          </label>
                        </div>
                        <p className='text-[0.75rem] font-semibold text-[#d90000] mt-[5px]'>
                          {errors.additionalDocument?.message}
                        </p>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Submit Button */}
        <div className='w-full flex justify-end gap-4'>
          {!brandInfoPage && !payoutAccountPage && (
            <Button
              type='button'
              onClick={handleSaveDraft}
              className={`w-full  text-sm px-5 text-white bg-primary border-primary min-w-[7rem] py-2 md:w-[7rem] rounded-[10px] font-semibold`}
            >
              Save Draft
            </Button>
          )}
          <Button
            type='button'
            onClick={handleSaveSubmit}
            className={`w-full  text-sm px-5 text-white bg-primary border-primary min-w-[7rem] py-2 md:w-[7rem] rounded-[10px] font-semibold`}
          >
            {brandInfoPage || payoutAccountPage ? 'Save Changes' : 'Submit'}
          </Button>
          {/* <Button
           type='submit'
            disabled
            className={`w-full  text-sm px-5 text-white bg-[#1d1d6661] border-[#1d1d6661] min-w-[7rem] py-2 md:w-[7rem] rounded-[10px] font-semibold`}
          >
            Submit
          </Button> */}
        </div>
      </form>
      <Modal isLoading={isLoading}>{isLoading && <Spinner />}</Modal>
    </div>
  );
};

export default BrandingInformation;
