import { useEffect, useState } from 'react';
import BarchartComp from '../../components/barChart/BarChart';

import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../app/hooks';
import { RootState } from '../../app/store';
import Button from '../../components/button/Button';
import { FilterByDateWithFlag } from '../../components/lineChart/LineChart';
import Spinner from '../../components/spinner/Spinner';
import {
  GetCardEnrollment,
  GetDashboardInfo,
  GetGraphMemberCounts,
  GetGraphNewMembersCounts,
  setCardEnrollment,
  setGraphMemberCounts,
  setGraphNewMembersCounts,
} from '../../features/partnerSlice';
import HistoryRouting from '../../components/HistoryRouting';

const TotalCustomers = () => {
  const [activeTab, setActiveTab] = useState<'1' | '2' | '3'>('1');
  const [customDate, setCustomDate] = useState<FilterByDateWithFlag>({
    startDate: '',
    endDate: '',
    typeName: '',
  });
  const {
    graphMemberCounts,
    graphNewMembersCounts,
    cardEnrollment,
    dashboardInfo,
    loadingStates,
  } = useSelector((state: RootState) => state.partner);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const { typeName, ...date } = customDate;

    if (typeName === 'MembersCount') {
      dispatch(GetGraphMemberCounts(date));
    } else if (!graphMemberCounts && activeTab === '1') {
      dispatch(GetGraphMemberCounts());
    }
    if (typeName === 'NewMembers') {
      dispatch(GetGraphNewMembersCounts(date));
    } else if (!graphNewMembersCounts && activeTab === '2') {
      dispatch(GetGraphNewMembersCounts());
    }

    if (typeName === 'MemberCardEnrollment') {
      dispatch(GetCardEnrollment(date));
    } else if (!cardEnrollment && activeTab === '3') {
      dispatch(GetCardEnrollment());
    }
    if (typeName) setCustomDate({ ...customDate, typeName: '' });
  }, [
    activeTab,
    customDate,
    cardEnrollment,
    graphMemberCounts,
    graphNewMembersCounts,
    dispatch,
  ]);

  useEffect(() => {
    if (!dashboardInfo) dispatch(GetDashboardInfo());
    return () => {
      dispatch(setGraphNewMembersCounts(null));
      dispatch(setCardEnrollment(null));
      dispatch(setGraphMemberCounts(null));
    };
  }, [dispatch, dashboardInfo]);

  return (
    <div className='mb-8'>
      {/* Toggle Button */}
      <HistoryRouting history='Reporting/Members Data' />
      <div className='space-y-10'>
        <div className='space-y-6 border border-gray-200 primary-shadow p-4'>
          <p>
            {/* <span className='font-medium text-2xl'>Reporting / </span> */}
            <span className='font-bold text-2xl'>Total Members To Date</span>
          </p>
          {/* <p className='text-xl font-semibold'>5,972</p> */}
          {loadingStates['GetDashboardInfo'] ? (
            <Spinner />
          ) : (
            <p className='text-xl font-semibold'>{dashboardInfo?.userCount}</p>
          )}
        </div>
        <div className='border border-gray-200 shadow px-7 pt-7 pb-5'>
          <div className='flex gap-10'>
            <Button
              className={` ${
                activeTab === '1'
                  ? 'bg-[#00C389] text-white'
                  : 'text-black bg-white'
              }    w-full`}
              onClick={() => setActiveTab('1')}
            >
              Members Count
            </Button>
            <Button
              className={` ${
                activeTab === '2'
                  ? 'bg-[#00C389] text-white'
                  : 'text-black bg-white'
              }    w-full`}
              onClick={() => setActiveTab('2')}
            >
              New Members
            </Button>
            <Button
              className={` ${
                activeTab === '3'
                  ? 'bg-[#00C389] text-white'
                  : 'text-black bg-white'
              }    w-full`}
              onClick={() => setActiveTab('3')}
            >
              Member Card Enrollment
            </Button>
          </div>
          {/* Display Charts or List View */}
          <div className='grid grid-cols-1 '>
            {activeTab === '1' && (
              <BarchartComp
                data={graphMemberCounts}
                isFiltering={true}
                chartName='MembersCount'
                setCustomDate={setCustomDate}
                isLoading={loadingStates['GetGraphMemberCounts']}
                description='Active vs. Inactive Members (Active members are those that have transacted in the respective month)'
                showExport
              />
            )}
            {activeTab === '2' && (
              <BarchartComp
                data={graphNewMembersCounts}
                dataKey1='newCustomers'
                chartName='NewMembers'
                name1='New Members'
                isOneColumn={true}
                setCustomDate={setCustomDate}
                isFiltering={true}
                isLoading={loadingStates['GetGraphNewMembersCounts']}
                description=' Monthly New Members Joining the Program'
                showExport
              />
            )}
            {activeTab === '3' && (
              <BarchartComp
                data={cardEnrollment}
                dataKey1='customerWithCard'
                dataKey2='customerNoCard'
                chartName='MemberCardEnrollment'
                name1='Member With at-least 1 Card'
                name2='Member Without any Card'
                setCustomDate={setCustomDate}
                isFiltering={true}
                isLoading={loadingStates['GetCardEnrollment']}
                description='Members with at-least 1 enrolled card vs. members without any enrolled cards'
                showExport
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalCustomers;
