import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import { AppThunk } from '../app/store';
import axios from 'axios';
import envConfig from '../utils/config';
import { GetBankInfo, GetBrandInfo, GetDashboardInfo } from './partnerSlice';
import { determineUserStatus } from '../utils/generalFunctions';

interface AuthState {
  isAuthenticated: boolean;
  user: { email: string; id: string; partnerId: string } | null;
  userDetails: any | null;
  loading: boolean;
  error: string | null;
  loadingStates: { [key: string]: boolean };
  status: StatusType;
}

type StatusType =
  | 'null'
  | 'inactive'
  | 'inreview'
  | 'active'
  | 'suspended'
  | 'onhold';

const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
  userDetails: null,
  loading: false,
  loadingStates: {},
  error: null,
  status: 'null',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: state => {
      state.loading = true;
      state.error = null;
    },
    loginSuccess: (
      state,
      action: PayloadAction<{
        email: string;
        token: string;
        id: string;
        partnerId: string;
      }>
    ) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.error = null;
      state.user = {
        email: action.payload.email,
        id: action.payload.id,
        partnerId: action.payload.partnerId,
      };
    },
    loginFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    logout: state => {
      document.cookie = `${'token'}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
      localStorage.clear();
    },
    registrationStart: state => {
      state.loading = true;
      state.error = null;
    },
    registrationSuccess: (
      state,
      action: PayloadAction<{ email: string; id: string; partnerId: string }>
    ) => {
      state.loading = false;
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    registrationFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    setUser: (
      state,
      action: PayloadAction<{ email: string; id: string; partnerId: string }>
    ) => {
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    setUserDetails: (state, action: PayloadAction<any>) => {
      state.userDetails = action.payload;
    },
    setUserStatus: (state, action: PayloadAction<StatusType>) => {
      state.status = action.payload;
    },
    setAuthenticationState: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    setLoadingState: (
      state,
      action: PayloadAction<{ key: string; value: boolean }>
    ) => {
      state.loadingStates[action.payload.key] = action.payload.value;
    },
    clearError: state => {
      state.error = null;
    },
  },
});

export const {
  login,
  loginSuccess,
  loginFailure,
  logout,
  registrationStart,
  registrationSuccess,
  registrationFailure,
  setUserStatus,
  setUserDetails,
  setAuthenticationState,
  clearError,
  setUser,
  setLoadingState,
} = authSlice.actions;

export const authReducer = authSlice.reducer;

interface LoginResponse {
  access_token: string;
  partner: any;
}

export const loginUser =
  (email: string, password: string, navigate: NavigateFunction): AppThunk =>
  async dispatch => {
    dispatch(login());
    try {
      const response: any = await axios.post<any>(
        `${envConfig.REACT_APP_ADMIN_BASE_URL}/aut/a/login`,
        { email, password },
        {
          headers: {
            'x-api-key': envConfig.REACT_APP_ADMIN_X_API_KEY,
          },
        }
      );

      if (response.status === 200) {
        const { access_token, partner } = response.data;
        const userId = partner.id;
        const daysToExpire = 60;
        const date = new Date();
        date.setTime(date.getTime() + daysToExpire * 24 * 60 * 60 * 1000);
        document.cookie = `token=${access_token}; path=/; expires=${date.toUTCString()};`;

        dispatch(
          loginSuccess({
            email,
            token: access_token,
            id: userId,
            partnerId: partner.partnerId,
          })
        );
        await dispatch(fetchUserDetails(userId));
        navigate('/dashboard');
      } else if (response.status === 409) {
        dispatch(loginFailure('Login Failed'));
      }

      return response;
    } catch (error: any) {
      const errorMsg = error?.response?.data;
      console.log(errorMsg);
      if (typeof errorMsg === 'object') {
        if (errorMsg.message === 'Please verify your email') {
          dispatch(
            loginFailure(
              'Please make sure to verify your email by clicking on the link emailed to you after registration. If you can’t find the email, please check your spam folder. If you still can’t find the email, please contact us at registration@saversapp.com'
            )
          );
        } else {
          dispatch(loginFailure(errorMsg.message || 'Login failed'));
        }
      } else if (typeof errorMsg === 'string') {
        dispatch(loginFailure(errorMsg || 'Login failed'));
      } else {
        dispatch(loginFailure('Something went wrong. Please try again later'));
      }
      return error;
    }
  };

export interface PartnerAttrib {
  id: number;
  name: string;
  value: string;
  last_updated: string;
}

export const fetchUserDetails =
  (userId: string): AppThunk =>
  async (dispatch, getState) => {
    const loadingKey = 'fetchUserDetailsState';
    try {
      const { auth } = getState() as { auth: AuthState };
      if (!auth.loadingStates[loadingKey]) {
        dispatch(setLoadingState({ key: loadingKey, value: true }));
        const userDetailsResponse = await axios.post(
          `${envConfig.REACT_APP_ADMIN_BASE_URL}/pmo/u/g`,
          { id: userId },
          {
            headers: {
              'x-api-key': envConfig.REACT_APP_ADMIN_X_API_KEY,
            },
          }
        );

        localStorage.setItem(
          'userDetails',
          JSON.stringify(userDetailsResponse.data)
        );
        dispatch(setUserDetails(userDetailsResponse.data));
        if (
          userDetailsResponse.data?.partner?.partnerAttrib &&
          Array.isArray(userDetailsResponse.data.partner.partnerAttrib)
        ) {
          determineUserStatus(
            userDetailsResponse.data?.partner?.partnerAttrib,
            dispatch
          );
        }
        dispatch(GetBrandInfo());
        dispatch(GetDashboardInfo());
        // dispatch(GetGraphMemberCounts(partnerId));
        dispatch(GetBankInfo());
      }
    } catch (error: any) {
      console.error('Error fetching user details:', error);
    } finally {
      dispatch(setLoadingState({ key: loadingKey, value: false }));
    }
  };

export const EmailVerification = async (encryptedText: string) => {
  try {
    const emailVerificationResponse = await axios.post<LoginResponse>(
      `${envConfig.REACT_APP_ADMIN_BASE_URL}/usr/p/e/v`,
      { encryptedText },
      {
        headers: {
          'x-api-key': envConfig.REACT_APP_ADMIN_X_API_KEY,
        },
      }
    );

    return emailVerificationResponse;
  } catch (error: any) {
    console.error('Error verifying email:', error);
  }
};

export const ForgotPassword = async (email: string) => {
  try {
    const forgotPasswordResponse = await axios.post(
      `${envConfig.REACT_APP_ADMIN_BASE_URL}/pmo/u/p/f`,
      { email },
      {
        headers: {
          'x-api-key': envConfig.REACT_APP_ADMIN_X_API_KEY,
        },
      }
    );

    return forgotPasswordResponse;
  } catch (error: any) {
    console.error('Error with ForgotPassword:', error);
    throw error;
  }
};

export const UpdatePassword = async (payload: any) => {
  try {
    const { encryptedText, password } = payload;
    const updatePasswordResponse = await axios.post(
      `${envConfig.REACT_APP_ADMIN_BASE_URL}/pmo/u/p/u`,
      { encryptedText, password },
      {
        headers: {
          'x-api-key': envConfig.REACT_APP_ADMIN_X_API_KEY,
        },
      }
    );

    return updatePasswordResponse;
  } catch (error: any) {
    console.error('Error with UpdatePassword:', error);
    throw error;
  }
};

export const logoutAndRedirect =
  (navigate: NavigateFunction): AppThunk =>
  dispatch => {
    dispatch(logout());
    // navigate('/');
    window.location.href = '/';
  };

export const registerUser =
  (userData: any, navigate: NavigateFunction): AppThunk =>
  async dispatch => {
    dispatch(registrationStart());
    try {
      navigate('/dashboard');
    } catch (error: any) {
      dispatch(registrationFailure(error.message));
    }
  };

export default authSlice.reducer;
