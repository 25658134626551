import React from 'react';
// import QRCode from 'qrcode.react';
import { images } from '../../constants/images';
import { Icons } from '../../assets/SVG-Icons';
import toast, { Toaster } from 'react-hot-toast';
import {
  downloadImage,
  downloadImageHTTPS,
} from '../../utils/generalFunctions';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import Spinner from '../../components/spinner/Spinner';
import PageShdowTitle from '../../components/pageShdowTitle';
import HistoryRouting from '../../components/HistoryRouting';
// import QRCode from 'qrcode.react';

const QR = () => {
  const { brandInfo, loading } = useSelector(
    (state: RootState) => state.partner
  );

  // const url =
  //   'https://play.google.com/store/apps/details?id=com.madrewards.savemate&hl=en_IN';
  // const [url, setUrl] = useState('https://play.google.com/store/apps/details?id=com.madrewards.savemate&hl=en_IN');
  // const handleInputChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
  //     setUrl(e.target.value);
  //   };

  const handleCopy = (url: string) => {
    navigator.clipboard.writeText(url);
    toast.success('Link copied!');
  };

  return (
    <>
      <HistoryRouting history='Marketing Material/QR & Links' />
      <PageShdowTitle
        title={' Your Branded B2C website, QR Code and App Store Links!'}
      />
      <div className='flex flex-col bg-white rounded-lg max-w-[85rem] my-8'>
        <div>
          <p className='text-[15px] mb-5'>
            Below is the link to your branded website that serves as a powerful
            tool to highlight the unique features and exclusive rewards of your
            cashback app. This website will allow you to effectively engage your
            customer base, enhance brand visibility, and communicate the
            valuable benefits you provide. You can link to this page from your
            website, emails, and other channels.
          </p>
          <p className='text-[15px] mb-5'>
            To help increase downloads of your loyalty app and drive customer
            engagement, we&apos;ve provided a unique QR code deep-linked to your
            app. Simply copy, paste, or print your QR code, and display it
            across your marketing channels. Add it to your website, landing
            pages, flyers, brochures, or even merchandise like t-shirts.
          </p>
          <p className='text-[15px] mb-5'>
            This makes it easy for customers and new prospects to access your
            app and start enjoying the benefits of your loyalty program. The
            more exposure your QR code gets, the faster you&apos;ll grow your
            user base!
          </p>
          <p className='text-[15px] font-medium mb-10'>
            We’ve also included the App Store and Google Play icons for you to
            download. Just copy the link below the icons and hyperlink it to the
            icons wherever you use them.
          </p>
        </div>
        <p className='text-2xl font-bold leading-6'>
          Your unique website, QR code and deeplinks
        </p>
        <div className='flex items-center text-lg font-medium mt-5 gap-2'>
          <span className='font-bold'>Your custom website link: </span>
          <a
            href={`https://${brandInfo?.b2cLink}`}
            className='text-blue-700'
            target='_blank'
            rel='noreferrer'
          >
            {brandInfo?.b2cLink}
          </a>
          <button onClick={() => handleCopy(brandInfo?.b2cLink)}>
            {Icons.copy()}
          </button>
        </div>

        <div className='flex space-x-0 lg:space-x-8 lg:space-y-0 space-y-8 mt-14 flex-wrap'>
          <div className='flex flex-col items-center mr-[3rem]'>
            {loading || !brandInfo ? (
              <Spinner />
            ) : (
              <>
                <img
                  src={brandInfo?.qrCode}
                  alt='Dashboard QR code'
                  className='mb-6 w-[16.25rem] h-[16.25rem]'
                />
                {/* <QRCode
                value={brandInfo?.qrCode}
                size={256} // Size of the QR code
                bgColor='#ffffff' // Background color
                fgColor='#000000' // Foreground color
                level='Q' // Error correction level
              /> */}
                <button
                  className='border border-[#A6A6A6] py-4 w-full rounded-md font-bold'
                  onClick={() => downloadImageHTTPS(brandInfo?.qrCode)}
                >
                  Download
                </button>
              </>
            )}
          </div>
          <div className='flex flex-col justify-between'>
            <div>
              <img
                src={images.androidBlackeBG}
                alt='Get it on Google Play'
                className='w-44'
              />
              <button
                className='text-lg font-semibold pt-2.5'
                onClick={() =>
                  downloadImage(images.androidBlackeBG, 'android-black.png')
                }
              >
                Download
              </button>
              <div className='text-[#333399] flex gap-1'>
                <p className='font-semibold pt-1'>
                  {brandInfo?.googleDeepLink}
                </p>
                <button onClick={() => handleCopy(brandInfo?.googleDeepLink)}>
                  {Icons.copy()}
                </button>
              </div>
            </div>
            <div>
              <img
                src={images.iosBlackBG}
                alt='Download on the App Store'
                className='w-44'
              />
              <button
                className='text-lg font-semibold pt-2.5'
                onClick={() =>
                  downloadImage(images.iosBlackBG, 'ios-black.png')
                }
              >
                Download
              </button>
              <div className='text-[#333399] flex gap-1'>
                <p className='font-semibold pt-1'>{brandInfo?.appDeepLink}</p>
                <button onClick={() => handleCopy(brandInfo?.appDeepLink)}>
                  {Icons.copy()}
                </button>
              </div>
            </div>
          </div>
          <div className='flex flex-col justify-between lg:pl-10'>
            <div className='mb-10 lg:mb-0'>
              <img
                src={images.androidWhiteBG}
                alt='Get it on Google Play'
                className='w-44'
              />
              <button
                className='text-lg font-semibold pt-2.5'
                onClick={() =>
                  downloadImage(images.androidWhiteBG, 'android-white.png')
                }
              >
                Download
              </button>
            </div>
            <div className='mb-5'>
              <img
                src={images.iosWhiteBG}
                alt='Download on the App Store'
                className='w-44'
              />
              <button
                className='text-lg font-semibold pt-2.5'
                onClick={() =>
                  downloadImage(images.iosWhiteBG, 'ios-white.png')
                }
              >
                Download
              </button>
            </div>
          </div>
        </div>
        <Toaster />
      </div>
    </>
  );
};

export default QR;
