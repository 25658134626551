import React, { useEffect, useRef, useState } from 'react';
import {
  FaQuestionCircle,
  FaRegNewspaper,
  FaRegUserCircle,
} from 'react-icons/fa';
import { MdOutlineSupportAgent } from 'react-icons/md';
import { TbLogout2 } from 'react-icons/tb';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { RootState } from '../../app/store';
import { sideBarItems } from '../../assets/dummy';
import dashboardLogo from '../../assets/images/dashboard/logo.png';
import { images } from '../../constants/images';
import { logoutAndRedirect } from '../../features/authSlice';
import useShowModal from '../../hooks/useShowModal';
import Modal from '../modal/Modal';
import Spinner from '../spinner/Spinner';

const isMobileDevice = () => {
  return window.matchMedia('(max-width: 767px)').matches;
};

interface SideBarProps {
  isDrawerOpen: boolean;
  toggleDrawer: () => void;
}
interface MenuItem {
  id: number | string;
  name: string;
  path?: string;
  children?: MenuItem[];
}

const Sidebar: React.FC<SideBarProps> = ({ isDrawerOpen, toggleDrawer }) => {
  const [openItems, setOpenItems] = useState<{ [key: number]: number | null }>(
    {}
  );
  const userStatus = useSelector((state: RootState) => state.auth.status);
  const BrandInfo = useSelector((state: RootState) => state.partner.brandInfo);
  const loadingStates = useSelector(
    (state: RootState) => state.partner.loadingStates
  );
  const userDetails = localStorage.getItem('userDetails');
  const user = userDetails ? JSON.parse(userDetails) : '';
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const { closeModal, openModal, showModal } = useShowModal();

  useEffect(() => {
    const removeBackground = async () => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = BrandInfo?.logo || ''; // Replace with your image source

      img.onload = () => {
        if (!canvasRef.current) return;

        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        if (!ctx) return;

        // Set canvas dimensions to match the image
        canvas.width = img.width;
        canvas.height = img.height;

        // Draw the image onto the canvas
        ctx.drawImage(img, 0, 0);

        // Get image data for manipulation
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;

        // Loop through each pixel to remove the white background
        for (let i = 0; i < data.length; i += 4) {
          const r = data[i];
          const g = data[i + 1];
          const b = data[i + 2];

          // Set alpha to 0 for white-like pixels
          if (r > 200 && g > 200 && b > 200) {
            data[i + 3] = 0;
          }
        }

        // Put the modified image data back onto the canvas
        ctx.putImageData(imageData, 0, 0);
      };

      img.onerror = error => {
        console.error('Failed to load the image', error);
      };
    };

    if (BrandInfo?.logo) {
      removeBackground();
    }
  }, [BrandInfo]);

  const filteredSideBarItems =
    userStatus === 'active'
      ? sideBarItems
      : sideBarItems.filter(item => item.name === 'Dashboard');

  const toggleSubMenu = (level: number, itemId: number) => {
    setOpenItems(prevOpenItems => {
      // Copy the existing state
      const newOpenItems = { ...prevOpenItems };

      // Check if the clicked item is already open at this level
      if (newOpenItems[level] === itemId) {
        // Close the current menu if it's already open
        newOpenItems[level] = null;
      } else {
        // Open the new menu and close others at this level
        newOpenItems[level] = itemId;
      }

      // Clear all submenus at levels deeper than the current level
      const levelsToRemove = Object.keys(newOpenItems)
        .map(Number)
        .filter(l => l > level);
      levelsToRemove.forEach(l => delete newOpenItems[l]);

      return newOpenItems;
    });
  };

  const handleLogout = () => {
    dispatch(logoutAndRedirect(navigate));
  };

  // Handle item click with mobile detection
  const handleItemClick = (
    level: number,
    itemId: number,
    path?: string,
    children?: any
  ) => {
    if (children.length > 0) {
      toggleSubMenu(level, itemId);
    }

    if (path && isMobileDevice()) {
      toggleDrawer(); // Close drawer on mobile after item click
    }
  };
  function removeItemsByName(
    menu: MenuItem[],
    namesToRemove: string[]
  ): MenuItem[] {
    return menu
      .map(item => ({
        ...item,
        children: item.children
          ? removeItemsByName(item.children, namesToRemove)
          : [],
      }))
      .filter(item => !namesToRemove.includes(item.name));
  }

  // Recursive function to render sidebar items
  const renderSidebarItems = (items: any[], level: number = 0) => {
    const renderItems = (menuItems: any[]) =>
      menuItems.map(item => (
        <ul key={item.id}>
          <li
            className={`${
              item?.path === pathname
                ? 'bg-[#38C99F]'
                : 'bg-white md:bg-primary md:hover:bg-[#182f9c]'
            } ${
              item?.children.length !== 0 ? 'p-2.5' : 'px-2.5'
            } cursor-pointer flex items-center font-normal text-[0.9rem] justify-between`}
            onClick={() =>
              handleItemClick(level, item?.id, item?.path, item?.children)
            }
          >
            <div
              className={`flex items-center w-full ${
                item?.path === '/dashboard' && 'ml-[6px]'
              }`}
            >
              {item?.icon &&
                React.createElement(item.icon, {
                  className:
                    item?.path === 'marketing-material/qr'
                      ? `h-[17px] w-[30px] mr-2`
                      : `w-[33px] h-[22px] ${
                          item?.path === '/dashboard' ? '-mr-1' : 'mr-2'
                        }`,
                })}
              {item?.path ? (
                <Link to={item?.path} className='h-full w-full p-2.5'>
                  {item.name}
                </Link>
              ) : (
                <p>{item.name}</p>
              )}{' '}
            </div>
            {item.children.length > 0 && (
              <>
                {openItems[level] === item.id ? (
                  <svg
                    width='20'
                    height='20'
                    viewBox='0 0 20 20'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    className='ml-4'
                  >
                    <path
                      d='M15 12.5L10 7.5L5 12.5'
                      stroke='#fff'
                      strokeWidth='1.38889'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                ) : (
                  <svg
                    width='20'
                    height='20'
                    viewBox='0 0 20 20'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    className='ml-4'
                  >
                    <path
                      d='M5 7.5L10 12.5L15 7.5'
                      stroke='#fff'
                      strokeWidth='1.66667'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                )}
              </>
            )}
          </li>
          {openItems[level] === item.id && item.children.length > 0 && (
            <ul className='pl-4'>
              {renderSidebarItems(item.children, level + 1)}
            </ul>
          )}
        </ul>
      ));

    if (user?.admin) {
      return renderItems(items);
    } else {
      const updatedMenu = removeItemsByName(items, ['Users', 'Payout Account']);
      return renderItems(updatedMenu);
    }
  };

  return (
    <>
      <aside
        className={`h-screen max-h-screen overflow-y-auto scroll-hidden fixed md:sticky top-0 left-0 w-full min-w-[16.2rem] md:w-[22rem] bg-white text-primary md:bg-primary md:text-white z-50 ${
          isDrawerOpen ? '' : 'hidden'
        }`}
        style={{ minHeight: 'fit-content' }}
      >
        <div className='flex flex-row-reverse px-3 md:p-0  justify-between items-center md:block'>
          <div className='flex items-center h-12 px-3 md:hidden'>
            <button onClick={toggleDrawer}>
              <svg
                className='w-6 h-6'
                fill='#66CC99'
                stroke='#66CC99'
                viewBox='0 0 24 24'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M4 6h16M4 12h16M4 18h16'
                ></path>
              </svg>
            </button>
          </div>
          <div className='h-[100px] flex items-center px-4 md:bg-[#f8f9ff]'>
            <div className='w-1/5 hidden md:flex justify-left'>
              <button
                onClick={toggleDrawer}
                className='text-gray-900 focus:outline-none'
              >
                <svg
                  className='w-6 h-6'
                  fill='#333399'
                  stroke='#333399'
                  viewBox='0 0 24 24'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M4 6h16M4 12h16M4 18h16'
                  ></path>
                </svg>
              </button>
            </div>
            {loadingStates['GetBrandInfoState'] ? (
              <Spinner />
            ) : BrandInfo?.logo ? (
              <canvas
                ref={canvasRef}
                className='max-w-40 lg:max-w-48 max-h-20 cursor-pointer'
                onClick={() => navigate('/dashboard')}
              />
            ) : (
              <img
                src={dashboardLogo}
                alt='Dashboard logo'
                className='w-[12.5rem] h-10'
              />
            )}

            {/* {(!loadingStates['GetBrandInfoState'] || !isCanvasLoading) && (
              <div className='w-full md:w-4/5 flex justify-center md:justify-start items-center'>
                {BrandInfo?.logo && !isCanvasLoading ? (
                  <canvas ref={canvasRef} />
                ) : (
                  <img
                    src={dashboardLogo}
                    alt='Dashboard logo'
                    className='w-[12.5rem] h-10'
                  />
                )}
              </div>
            )} */}
          </div>
        </div>
        <div className='overflow-y-auto min-h-[80vh]'>
          <div className='py-2 md:py-5 border-b-2 border-primaryBg'>
            {renderSidebarItems(filteredSideBarItems)}
          </div>

          {userStatus === 'active' && (
            <div className='py-5 border-b-2 border-primaryBg'>
              <ul>
                <Link to={'/profile'}>
                  <li
                    className={`${
                      pathname === '/profile'
                        ? 'bg-[#38C99F]'
                        : 'bg-white md:bg-primary md:hover:bg-[#182f9c]'
                    } p-2.5 cursor-pointer flex items-center font-normal text-[0.9rem] `}
                  >
                    <div>
                      <FaRegUserCircle className='w-[33px] h-[21px] mr-2' />
                    </div>
                    Profile
                  </li>
                </Link>
                <Link to={'/support'}>
                  <li
                    className={`${
                      pathname === '/support'
                        ? 'bg-[#38C99F]'
                        : 'bg-white md:bg-primary md:hover:bg-[#182f9c]'
                    } p-2.5 cursor-pointer flex items-center font-normal text-[0.9rem] `}
                  >
                    <div>
                      <MdOutlineSupportAgent className='w-[33px] h-[22px] mr-2' />
                    </div>
                    Support
                  </li>
                </Link>
                <Link to={'/faq'}>
                  <li
                    className={`${
                      pathname === '/faq'
                        ? 'bg-[#38C99F]'
                        : 'bg-white md:bg-primary md:hover:bg-[#182f9c]'
                    } p-2.5 cursor-pointer flex items-center font-normal text-[0.9rem] `}
                  >
                    <div>
                      <FaQuestionCircle className='w-[33px] h-[20px] mr-2' />
                    </div>
                    FAQ
                  </li>
                </Link>
                <Link to={'/news'}>
                  <li
                    className={`${
                      pathname === '/news'
                        ? 'bg-[#38C99F]'
                        : 'bg-white md:bg-primary md:hover:bg-[#182f9c]'
                    } p-2.5 cursor-pointer flex items-center font-normal text-[0.9rem] `}
                  >
                    <div>
                      <FaRegNewspaper className='w-[33px] h-[20px] mr-2' />
                    </div>
                    News
                  </li>
                </Link>
              </ul>
            </div>
          )}

          <div className='pt-5 md:mb-[5rem] mb-[7rem]'>
            <button
              onClick={openModal}
              className='hover:bg-[#182f9c] p-2.5 cursor-pointer flex items-center font-normal text-[0.9rem] w-full'
            >
              <div>
                <TbLogout2 className='w-[33px] h-[22px] mr-2' />
              </div>
              Log out
            </button>
          </div>
        </div>
        <div className='relative bottom-0 -mt-14 bg-primary'>
          <div>
            <p className='text-white pt-2 pb-4 px-2 break-words whitespace-normal font-[400] text-center text-[0.75rem] '>
              By using this site, you agree to our{' '}
              <span
                className='underline cursor-pointer'
                onClick={() => navigate('/t&c-privacy#privacyPolicy')}
              >
                Privacy Policy
              </span>{' '}
              and{' '}
              <span
                className='underline cursor-pointer'
                onClick={() => navigate('/t&c-privacy#termsOfUse')}
              >
                Terms & Conditions
              </span>
            </p>
            <p className='text-white font-semibold  px-2 break-words whitespace-normal text-center text-[0.75rem] '>
              Powered by{' '}
              <span
                className='flex justify-center mt-1 cursor-pointer'
                onClick={() => navigate('/')}
              >
                {' '}
                <img
                  src={images.WhiteLogo}
                  className='mb-6'
                  alt=''
                  width={200}
                />
              </span>
            </p>
          </div>
        </div>
      </aside>

      <Modal
        isOpen={showModal}
        onClose={closeModal}
        showCloseIcon={false}
        title='Log out from Dashboard '
      >
        <div className='flex items-center justify-center'>
          <button
            className='bg-primary px-7 py-1 text-white rounded'
            onClick={handleLogout}
          >
            Yes
          </button>
          <button
            className='bg-white px-5 py-1 text-pretty'
            onClick={closeModal}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};

export default Sidebar;
