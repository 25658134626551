import React, { useState, useEffect } from 'react';
import envConfig from '../../utils/config';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js';
import { MdOutlineDone } from 'react-icons/md';


const stripeKey = envConfig.REACT_APP_STRIPE_KEY;
const xApiKey = envConfig.REACT_APP_ADMIN_X_API_KEY;


// Move fetchClientSecret outside of the component
const fetchClientSecret = async () => {
  try {
    const partnerId = localStorage.getItem('partnerId');
    const res = await fetch(`${envConfig.REACT_APP_ADMIN_BASE_URL}/pmo/p/c`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': `${xApiKey}`,
      },
      body: JSON.stringify({ partnerId }),
    });
    const data = await res.json();
    return data;
  } catch (error) {
    throw new Error('Failed to fetch client secret');
  }
};

interface PaymentProps {
  paymentSessionId: string | null;
}

const Payment: React.FC<PaymentProps> = ({ paymentSessionId }) => {
  const [stripePromise, setStripePromise] = useState<Promise<any> | null>(null);
  const [stripe, setStripe] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [status, setStatus] = useState<string | null>(null);
  const [customerEmail, setCustomerEmail] = useState<string>('');
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);

  const fetchSessionStatus = async (paymentSessionId: string) => {
    console.log('DATA', JSON.stringify({ sessionId: paymentSessionId }));

    try {
      const result = await fetch(
        `${envConfig.REACT_APP_ADMIN_BASE_URL}/pmo/p/g`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': `${xApiKey}`,
          },
          body: JSON.stringify({ sessionId: paymentSessionId }),
        }
      );

      const data = await result.json();

      if (data.status === 'complete') {
        setStatus('complete');
        setCustomerEmail(data.customerEmail);
        setShowSuccessModal(true);
      } else if (data.status === 'open') {
        setStatus('open');
      }
    } catch (error) {
      setError('Failed to fetch session status');
    }
  };

  useEffect(() => {
    if (paymentSessionId) {
      fetchSessionStatus(paymentSessionId);
    }
  }, [paymentSessionId]);

  useEffect(() => {
    const initializeStripePromise = async () => {
      const { loadStripe } = await import('@stripe/stripe-js');
      setStripePromise(loadStripe(`${stripeKey}`));
    };

    initializeStripePromise();
  }, []);

  useEffect(() => {
    const initializeStripe = async () => {
      const stripeInstance = await stripePromise;
      setStripe(stripeInstance);
    };
    initializeStripe();

    // eslint-disable-next-line
  }, [error, status, customerEmail]);

  useEffect(() => {
    const initializeCheckout = async () => {
      if (stripe) {
        try {
          const clientSecret = await fetchClientSecret();
          if (!clientSecret) throw new Error('Missing client secret');

          const checkout = await stripe.initEmbeddedCheckout({
            clientSecret,
          });

          checkout.mount('#checkout');

          checkout.on('statusChange', (event: any) => {
            if (event.status === 'complete') {
              setCustomerEmail(event.customer_email);
              setStatus('complete');
              setShowSuccessModal(true); // Show modal on success
            }
          });
        } catch (error: any) {
          setError('Failed to initialize checkout: ' + error.message);
        }
      }
    };

    initializeCheckout();
  }, [stripe]); // Remove fetchClientSecret from dependencies

  return (
    <div>
      {!showSuccessModal && (
        <>
          <div id='checkout'>
            <EmbeddedCheckoutProvider
              stripe={stripePromise}
              options={{ fetchClientSecret }}
            >
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          </div>
          {/* {error && <p style={{ color: 'red' }}>{error}</p>} */}
        </>
      )}

      {showSuccessModal && (
        <div className='md:mt-[5rem]'>
          <div
            className='py-6 md:py-[3rem] md:px-[4rem] px-4'
            style={modalContentStyle}
          >
            <MdOutlineDone style={LogoStyle} />
            <p className='mb-6 md:px-[5rem]' style={textStyle}>
              Thank you for pre-registering! We&apos;re excited to offer you the
              opportunity to be among the first to roll out your very own Savers
              App Program.
            </p>
            <p className='mb-6 md:px-[2rem]' style={textStyle}>
              Before continuing, please make sure to verify your email by
              clicking on the “Verify” link in the email we just sent you. If
              you can&apos;t find the email, please check your spam folder. If
              you still can&apos;t find the email, please contact us at{' '}
              <a
                href='mailto:registration@saversapp.com'
                className='text-blue-800'
              >
                registration@saversapp.com
              </a>
            </p>
            <p style={textStyle}>
              Once your email is verified, you can log in to your dashboard to
              complete your company profile and add your program information,
              after which we will get right onto setting up your very own Savers
              App Rewards Program.
            </p>
            <p style={textStyle}>
              We will get in touch with you once your program is ready to go
              live, which will take around 2-4 weeks.
            </p>
            <p style={textStyle}>
              The invoice for your transaction will be emailed to you by the
              Savers App Accounting Team.
            </p>
            <p className='mt-6' style={textStyle}>
              Stay tuned!
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

const LogoStyle: React.CSSProperties = {
  color: 'white',
  background: '#66CC99',
  borderRadius: '50%',
  width: '6rem',
  height: '6rem',
  padding: '1rem',
  margin: '0rem auto 2rem',
};

const modalContentStyle: React.CSSProperties = {
  border: '1px solid lightgray',

  textAlign: 'center',
};

const textStyle: React.CSSProperties = {
  fontSize: '16px',
  lineHeight: '28px',
  fontFamily: 'Montserrat, sans-serif',
  fontWeight: '500',
  color: 'black',
};

export default Payment;
