import React from 'react';
import Button from '../button/Button';
import { useNavigate } from 'react-router';

type PricingCardProps = {
  title: string;
  buttonTitle: string;
  setupFee: string;
  monthlyFee: number | string;
  features: {
    domain: string;
    countriesLanguages: string;
    enrolled: { cards: string; additionalCards: string } | string;
    forUpEnrolledCards: string | null;
    newsletters: string;
    support: string;
    cashbackFrequency: string;
    standoutFeatures: string | JSX.Element;
  };
  isMostPopular?: boolean;
  showFullFeatures: boolean;
};

function canBeNumber(value: string | number) {
  if (typeof value === 'string') {
    const sanitizedValue = value.replace(/,/g, '');
    return !isNaN(Number(sanitizedValue));
  }
  return false;
}

const PricingCard: React.FC<PricingCardProps> = ({
  title,
  setupFee,
  monthlyFee,
  features,
  isMostPopular = false,
  showFullFeatures,
  buttonTitle,
}) => {
  const navigate = useNavigate();

  return (
    <div
      className={`w-full sm:max-w-[18.1rem] px-6 pt-8 bg-white sm:rounded-lg overflow-hidden text-left ${
        showFullFeatures ? 'pb-16' : 'pb-7'
      }`}
    >
      <div className='relative'>
        {isMostPopular && (
          <div className='absolute -top-1 -right-10 px-6 py-1 text-base font-semibold text-white bg-primary rounded-full h-10 pt-2'>
            Most Popular
          </div>
        )}
        <h3 className='text-2xl font-extrabold'>{title}</h3>
      </div>
      <p
        className={`mt-4 mb-2 text-2xl ${
          canBeNumber(setupFee)
            ? 'font-extrabold'
            : 'font-semibold text-gray-600'
        }`}
      >
        {`${canBeNumber(setupFee) ? `$${setupFee}` : setupFee}`}
      </p>
      {features.enrolled !== 'Custom' && (
        <p className='text-gray-500 font-semibold'>One-time setup fee</p>
      )}
      <p
        className={`mt-8 text-2xl ${
          canBeNumber(monthlyFee.toString())
            ? 'font-extrabold'
            : 'font-semibold text-gray-600'
        }`}
      >{`${canBeNumber(monthlyFee) ? `$${monthlyFee}` : monthlyFee}`}</p>
      {features.enrolled !== 'Custom' && (
        <p className='text-gray-500 font-semibold text-sm mb-3 mt-2'>
          Monthly service fee
        </p>
      )}
      {features.forUpEnrolledCards && (
        <p className='text-gray-500 font-semibold text-sm'>
          For up to {features.forUpEnrolledCards} enrolled cards
        </p>
      )}
      <Button
        className={`w-[13.3rem] sm:w-full text-[#00C389] border-[2px] border-[#00C389] text-sm font-bold ${
          features.enrolled === 'Custom' ? 'mt-28 sm:mt-20 mb-10' : 'mt-6 mb-10'
        }`}
        onClick={() => navigate('/signup')}
        disabled={buttonTitle !== 'Get started'}
      >
        {buttonTitle}
      </Button>
      {showFullFeatures && (
        <div className='mt-6'>
          <h4 className='text-lg font-extrabold text-[#00C389]'>
            {title} includes:
          </h4>
          <ul className='mt-8 space-y-2'>
            <li>
              <div className='space-y-3 mb-5'>
                <p className='font-bold text-sm'>Domain:</p>
                <p className='text-gray-500 font-semibold text-sm'>
                  {features.domain}
                </p>
              </div>
            </li>
            <li>
              <div className='space-y-3 mb-9'>
                <p className='font-bold text-sm'>Countries/languages:</p>
                <p className='text-gray-500 font-semibold text-sm'>
                  {features.countriesLanguages}
                </p>
              </div>
            </li>
            <li>
              <div className='space-y-3 mb-5'>
                <p className='font-bold text-sm'>Number of enrolled cards:</p>
                {features.enrolled !== 'Custom' ? (
                  <p className='text-gray-500 font-semibold text-sm'>
                    Up to{' '}
                    {typeof features.enrolled === 'object' &&
                      features.enrolled.cards}{' '}
                    cards, additional{' '}
                    {typeof features.enrolled === 'object' &&
                      features.enrolled.additionalCards}{' '}
                    cards +$250/m
                  </p>
                ) : (
                  <p className='text-gray-500 font-semibold text-sm pb-5'>
                    {features.enrolled}
                  </p>
                )}
              </div>
            </li>
            <li>
              <div className='space-y-3 mb-5'>
                <p className='font-bold text-sm'>Promotional newsletters:</p>
                <p className='text-gray-500 font-semibold text-sm'>
                  {features.newsletters}
                </p>
              </div>
            </li>
            <li>
              <div
                className={`space-y-3 ${
                  features.support.length < 30 ? 'mb-9' : 'mb-4'
                }`}
              >
                <p className='font-bold text-sm'>Partner support:</p>
                <p className='text-gray-500 font-semibold text-sm'>
                  {features.support}
                </p>
              </div>
            </li>
            <li>
              <div className='space-y-3'>
                <p className='font-bold text-sm'>Cashback payout frequency:</p>
                <p className='text-gray-500 font-semibold text-sm'>
                  {features.cashbackFrequency}
                </p>
              </div>
            </li>
          </ul>
          <p className='mt-6 text-[#00C389] font-semibold text-base'>
            Standout features:
          </p>
          <p className='text-gray-900 font-bold text-sm mt-5'>
            {features.standoutFeatures}
          </p>
        </div>
      )}
    </div>
  );
};

export default PricingCard;
