import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { BsFiletypeCsv } from 'react-icons/bs';
import { useSearchParams } from 'react-router-dom';
import useExportCSV from '../../hooks/useExportCSV';
import useShowModal from '../../hooks/useShowModal';
import Modal from '../modal/Modal';
import Spinner from '../spinner/Spinner';

interface Props<T extends Record<string, any>> {
  data: T[];
  config: {
    headers: { key: string; label: string }[];
  };
  showExportButton?: boolean;
  onEdit?: () => void;
  title?: string;
  selectCheckbox?: boolean;
  showActions?: boolean;
  allDataUrl?: string;
  width?: string;
  dataFilter?: boolean;
  deleteAction?: (userId: number | string) => void;
}

const Table = <T extends Record<string, any>>({
  data,
  config,
  showExportButton = true,
  onEdit,
  title,
  selectCheckbox,
  dataFilter = false,
  showActions,
  width,
  deleteAction,
}: Props<T>) => {
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: 'ascending' | 'descending' | 'none';
  } | null>(null);
  const [dateFilter, setDateFilter] = useState<string>('3 months');
  const [customDateRange, setCustomDateRange] = useState<{
    from: Date;
    to: Date;
  }>({
    from: new Date('2000-01-01'),
    to: new Date('3073-12-31'),
  });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const handleExportCSV = useExportCSV(config, data);
  const { closeModal, showModal, openModal } = useShowModal();
  const [searchParams, setSearchParams] = useSearchParams();
  const handleDateFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setDateFilter(e.target.value);
  };

  const handleCustomDateChange = (from: Date | null, to: Date | null) => {
    setCustomDateRange({
      from: from || new Date('2000-01-01'),
      to: to || new Date('3023-12-31'),
    });
  };

  const filteredData = React.useMemo(() => {
    const filterDate = (dateStr: string): Date => {
      if (!dateStr) return new Date(); // Fallback to the current date if dateStr is empty or invalid
      const [day, month, year] = dateStr.split('/').map(Number);
      if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
        return new Date(year, month - 1, day);
      }
      return new Date(); // Fallback to the current date if parsing fails
    };

    let filtered = [...data];

    if (dateFilter !== 'Custom') {
      const dateThreshold = new Date();
      switch (dateFilter) {
        case '3 months':
          dateThreshold.setMonth(dateThreshold.getMonth() - 3);
          break;
        case '6 months':
          dateThreshold.setMonth(dateThreshold.getMonth() - 6);
          break;
        case '9 months':
          dateThreshold.setMonth(dateThreshold.getMonth() - 9);
          break;
        case '1 year':
          dateThreshold.setFullYear(dateThreshold.getFullYear() - 1);
          break;
        default:
          break;
      }

      if (filterDate) {
        filtered = filtered.filter(item => {
          const itemDate = filterDate(item.date);
          return itemDate >= dateThreshold;
        });
      }
    } else if (customDateRange.from && customDateRange.to) {
      filtered = filtered.filter(item => {
        const itemDate = filterDate(item.date);
        return (
          itemDate >= customDateRange.from && itemDate <= customDateRange.to
        );
      });
    }

    return filtered;
  }, [data, dateFilter, customDateRange]);

  const confirmDelete = () => {
    if (selectedRow) {
      if (showActions) deleteAction?.(selectedRow);
      setSelectedRow(null);
    }
    closeModal();
  };

  const sortedData = React.useMemo(() => {
    if (sortConfig !== null && sortConfig.direction !== 'none') {
      return [...filteredData].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return filteredData;
  }, [filteredData, sortConfig]);

  const handleSort = (key: string) => {
    let direction: 'ascending' | 'descending' | 'none' = 'ascending';
    if (sortConfig && sortConfig.key === key) {
      if (sortConfig.direction === 'ascending') {
        direction = 'descending';
      } else if (sortConfig.direction === 'descending') {
        direction = 'none';
      }
    }
    setSortConfig({ key, direction });
  };

  const handleEdit = (email: string) => {
    console.log('edit id: ', email);
    setSearchParams({ ...Object.fromEntries(searchParams), email: email });
    onEdit?.();
  };

  const handleDownload = async () => {
    setIsLoading(true);
    await handleExportCSV();
    setIsLoading(false);
  };

  if (data.length === 0) return <></>;

  return (
    <div className='relative overflow-x-auto sm:rounded-lg'>
      <div className='flex justify-between items-center'>
        {dataFilter && (
          <div className='flex items-center'>
            <label className='mr-2'>Date Filter:</label>
            <select
              value={dateFilter}
              onChange={handleDateFilterChange}
              className='border rounded p-2'
            >
              <option value='3 months'>Last 3 months</option>
              <option value='6 months'>Last 6 months</option>
              <option value='9 months'>Last 9 months</option>
              <option value='1 year'>Last 1 year</option>
              <option value='Custom'>Custom</option>
            </select>
          </div>
        )}

        {dateFilter === 'Custom' && (
          <div className='flex items-center'>
            <div className='flex items-center mr-4'>
              <label className='mr-2'>From:</label>
              <DatePicker
                selected={customDateRange.from}
                onChange={date =>
                  handleCustomDateChange(date, customDateRange.to)
                }
                dateFormat='dd/MM/yyyy'
                className='border rounded p-2'
              />
            </div>
            <div className='flex items-center'>
              <label className='mr-2'>To:</label>
              <DatePicker
                selected={customDateRange.to}
                onChange={date =>
                  handleCustomDateChange(customDateRange.from, date)
                }
                dateFormat='dd/MM/yyyy'
                className='border rounded p-2'
              />
            </div>
          </div>
        )}

        <div className='flex items-center'>
          {showExportButton && (
            <button
              onClick={handleDownload}
              className='rounded-lg w-[7rem] text-white bg-[#333399] flex px-2 py-2 items-center justify-center mb-6'
              disabled={isLoading}
            >
              {isLoading ? (
                <Spinner />
              ) : (
                <>
                  <span>Export </span>{' '}
                  <BsFiletypeCsv className='mx-2' size={22} />
                </>
              )}
            </button>
          )}
        </div>
      </div>

      {title && <p className='text-xl my-3 font-bold'>{title}</p>}

      <table
        className={`${
          width ?? 'w-fit'
        } text-sm text-left rtl:text-right text-gray-500`}
      >
        <thead className='text-xs text-gray-700 bg-gray-50'>
          <tr>
            {selectCheckbox && (
              <th scope='col' className='p-4'>
                <div className='flex items-center'>
                  <input
                    id='checkbox-all-search'
                    type='checkbox'
                    className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2'
                  />
                  <label htmlFor='checkbox-all-search' className='sr-only'>
                    checkbox
                  </label>
                </div>
              </th>
            )}
            {config.headers.map(header => (
              <th
                key={String(header.key)}
                scope='col'
                className='px-6 py-3 cursor-pointer'
                onClick={() => handleSort(header.key)}
              >
                <div className='flex items-center justify-start gap-2'>
                  <span>{header.label}</span>
                  <span>
                    {sortConfig?.key === header.key
                      ? sortConfig.direction === 'ascending'
                        ? ' ↑'
                        : sortConfig.direction === 'descending'
                        ? ' ↓'
                        : ' ↕'
                      : ' ↕'}
                  </span>
                </div>
              </th>
            ))}
            {showActions && (
              <th scope='col' className='px-6 py-3'>
                Action
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {sortedData.map((row, rowIndex) => (
            <tr
              key={rowIndex}
              className='bg-white border-b hover:bg-gray-50'
              onMouseEnter={() => setSelectedRow(row.id)}
            >
              {selectCheckbox && (
                <td className='w-4 p-4'>
                  <div className='flex items-center'>
                    <input
                      id={`checkbox-${rowIndex}`}
                      type='checkbox'
                      className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2'
                    />
                    <label htmlFor={`checkbox-${rowIndex}`} className='sr-only'>
                      checkbox
                    </label>
                  </div>
                </td>
              )}
              {config.headers.map(header => (
                <td
                  key={String(header.key)}
                  className='px-6 py-4 max-w-32 truncate'
                >
                  {row[header.key]}
                </td>
              ))}
              {showActions && (
                <td className='px-6 py-4 text-right flex gap-2 justify-center items-center'>
                  <button
                    className='text-primary'
                    onClick={() => handleEdit(row.email)}
                  >
                    <BiEdit size={20} />
                  </button>
                  <button className='text-red-600' onClick={openModal}>
                    <BiTrash size={20} />
                  </button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>

      {showModal && <Modal onClose={closeModal} />}
      <Modal
        isOpen={showModal}
        onClose={closeModal}
        showCloseIcon={false}
        title='Are you sure you want to delete this user?'
      >
        <div className='flex items-center justify-center'>
          <button
            className='bg-red-700 px-7 py-1 text-white rounded'
            onClick={confirmDelete}
          >
            Yes
          </button>
          <button
            className='bg-white px-5 py-1 text-pretty'
            onClick={closeModal}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Table;
