import HistoryRouting from '../../components/HistoryRouting';
import { images } from '../../constants/images';

const ProgramPlan = () => {
  // const [activeTab, setActiveTab] = useState<'1' | '2'>('1');
  // const searchTerm = useAppSelector(
  //   (state: RootState) => state.search.searchTerm,
  // );

  // const filteredData = data.filter(item =>
  //   Object.values(item).some(val =>
  //     String(val).toLowerCase().includes(searchTerm.toLowerCase()),
  //   ),
  // );

  return (
    <>
      <HistoryRouting history='Program Management/Billing/Program Plan' />
      {/* <PageShdowTitle title={'Program Plan'} /> */}

      <form className='rounded-b-lg primary-shadow mt-24'>
        <div className=''>
          <h2 className='text-white bg-primary p-4 px-8 rounded-t-lg text-lg font-semibold shadow-sm'>
            Program Plan
          </h2>
        </div>
        <div className='mx-auto max-w-[35rem] py-[7rem]'>
          <img src={images.comingSoon} alt='Coming soon' />
        </div>
      </form>
      {/* <div className='p-6 bg-gray-100'> */}
      {/* <h2 className='text-2xl font-semibold mb-4'>Program Plan</h2> */}
      {/* <div className='flex space-x-4 mb-8'>
          <div className='bg-[#ffffff] px-12 py-8 rounded-lg shadow-md w-[22rem]'>
            <h3 className='text-lg font-semibold text-[#000000]'>
              Current Plan
            </h3>
            <p className='text-[#656363] text-[0.85rem]'>12 days remaining</p>
            <p className='font-semibold my-4'>
              Monthly service: $250 for 1000 enrolled cards
            </p>
            <button className='mt-4 w-full bg-[#bc7777] text-white py-2 rounded-md font-semibold'>
              Cancel Subscription
            </button>
          </div>

          <div className='bg-white px-12 py-8 rounded-lg shadow-md w-[22rem]'>
            <h3 className='text-lg font-semibold'>Yearly Plan</h3>
            <p className='text-gray-600 text-[0.85rem]'>
              Save 10% on the yearly package
            </p>
            <p className='text-gray-800 font-semibold my-4'>
              Yearly service: $2500 for 1000 enrolled cards
            </p>
            <button className='mt-4 w-full bg-[#38c99f] text-white py-2 rounded-md font-semibold'>
              Upgrade
            </button>
          </div>
        </div> */}
      {/* <h2 className='text-2xl font-semibold mb-4'>Payment Method</h2> */}
      {/* <div className='bg-white px-12 py-8 rounded-lg shadow-md w-[22rem]'>
        <div className='flex align-middle gap-4 mb-6'>
            <TbCreditCard className='text-[1.5rem]' />
            <h3 className='text-lg font-semibold'> Credit Card</h3>
          </div>
            <p className='text-gray-600 text-[0.85rem]'>
            Account Number: **** **** **** 3451
            </p>
            <p className='text-gray-800 font-semibold my-4'>
            Auto-debit enabled for 3rd of every month
            </p>
            <button className='mt-4 w-full bg-[#38c99f] text-white py-2 rounded-md font-semibold'>
            Modify
            </button>
          </div> */}
      {/* <div className='bg-white px-8 py-6 rounded-lg shadow-md w-[22rem]'>
          <div className='flex align-middle gap-4 mb-4'>
            <TbCreditCard className='text-[1.5rem]' />
            <h3 className='text-lg font-semibold'> Credit Card</h3>
          </div>

          <p className='text-gray-600'>Account Number: **** **** **** 3451</p>
          <p className='mt-4 text-[0.85rem] font-semibold'>
            {' '}
            Auto-debit enabled for 3rd of every month
          </p>
          <button className='mt-4 w-full bg-[#000000] text-white py-2 rounded-md font-semibold'>
            Modify
          </button>
        </div> */}
      {/* <div className='flex gap-10 mt-12'>
          <Button
            className={` ${
              activeTab === '1'
                ? 'bg-[#00C389] text-white'
                : 'text-black bg-white'
            }    w-full`}
            onClick={() => setActiveTab('1')}
          >
            Subscription Payments
          </Button>
          <Button
            className={` ${
              activeTab === '2'
                ? 'bg-[#00C389] text-white'
                : 'text-black bg-white'
            }    w-full`}
            onClick={() => setActiveTab('2')}
          >
            Invoices
          </Button>
        </div> */}
      {/* </div> */}

      {/* {activeTab === '1' && (
        <div className='mt-8'>
          <h2 className='font-bold text-2xl mb-4'>Subscription Payments</h2>

          <Table
            data={subscriptionPaymentsData}
            config={subscriptionPaymentsConfig}
            showExportButton={false}
            selectCheckbox={false}
            allDataUrl='subscription-payments'
            width='w-full'
          />
        </div>
      )}

      {activeTab === '2' && (
        <div className='mt-8'>
          <h2 className='font-bold text-2xl mb-4'>Invoices</h2>

          <Table
            data={invoicesData}
            config={invoicesConfig}
            showExportButton={false}
            selectCheckbox={false}
            allDataUrl='subscription-payments'
            width='w-full'
          />
        </div>
      )} */}
    </>
  );
};

export default ProgramPlan;
