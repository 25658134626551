import React from 'react';

function PageShdowTitle({ title }: { title: JSX.Element | string }) {
  return (
    <div className='space-y-6 border border-gray-200 primary-shadow p-4'>
      <h1 className=' font-bold text-2xl'>{title}</h1>
    </div>
  );
}

export default PageShdowTitle;
