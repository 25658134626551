import React from 'react';
import ImageHoverCard from '../../components/imageHoverCard';
import shutterstock_2274892619 from '../../assets/images/dashboard/product-images/shutterstock_2274892619.jpg';
import shutterstock_598990760 from '../../assets/images/dashboard/product-images/shutterstock_598990760.jpg';
import shutterstock_1437805796 from '../../assets/images/dashboard/product-images/shutterstock_1437805796.jpg';
import shutterstock_1854409381 from '../../assets/images/dashboard/product-images/shutterstock_1854409381.jpg';
import shutterstock_2138874521 from '../../assets/images/dashboard/product-images/shutterstock_2138874521.jpg';
import shutterstock_2244005477 from '../../assets/images/dashboard/product-images/shutterstock_2244005477.jpg';
import shutterstock_2349014269 from '../../assets/images/dashboard/product-images/shutterstock_2349014269.jpg';
import shutterstock_2383450641 from '../../assets/images/dashboard/product-images/shutterstock_2383450641.jpg';
import shutterstock_2390088981 from '../../assets/images/dashboard/product-images/shutterstock_2390088981.jpg';
import shutterstock_2401097807 from '../../assets/images/dashboard/product-images/shutterstock_2401097807.jpg';
import shutterstock_2413955101 from '../../assets/images/dashboard/product-images/shutterstock_2413955101.jpg';
import shutterstock_2422947503 from '../../assets/images/dashboard/product-images/shutterstock_2422947503.jpg';
import shutterstock_2423774009 from '../../assets/images/dashboard/product-images/shutterstock_2423774009.jpg';
import shutterstock_2475095811 from '../../assets/images/dashboard/product-images/shutterstock_2475095811.jpg';
import shutterstock_2477174817 from '../../assets/images/dashboard/product-images/shutterstock_2477174817.jpg';
import shutterstock_2487419519 from '../../assets/images/dashboard/product-images/shutterstock_2487419519.jpg';
import shutterstock_2491715627 from '../../assets/images/dashboard/product-images/shutterstock_2491715627.jpg';
import shutterstock_2492372015 from '../../assets/images/dashboard/product-images/shutterstock_2492372015.jpg';
import PageShdowTitle from '../../components/pageShdowTitle';
import HistoryRouting from '../../components/HistoryRouting';

const ProductImages = () => {
  const images = [
    shutterstock_2274892619,
    shutterstock_598990760,
    shutterstock_1437805796,
    shutterstock_1854409381,
    shutterstock_2138874521,
    shutterstock_2244005477,
    shutterstock_2349014269,
    shutterstock_2383450641,
    shutterstock_2390088981,
    shutterstock_2401097807,
    shutterstock_2413955101,
    shutterstock_2422947503,
    shutterstock_2423774009,
    shutterstock_2475095811,
    shutterstock_2477174817,
    shutterstock_2487419519,
    shutterstock_2491715627,
    shutterstock_2492372015,
  ];
  return (
    <section>
      <HistoryRouting history='Marketing Material/Product Images' />
      <div className='space-y-8'>
        <PageShdowTitle title={'Product Images'} />
        <p className='text-[15px]'>
          We’ve selected a variety of images that are available for download to
          support your marketing and promotional efforts. These images will be
          refreshed regularly, so make sure to check back for new additions.
        </p>
      </div>
      <div className='flex gap-9 mt-14 flex-wrap'>
        {images.map((img, index) => (
          <ImageHoverCard
            key={index}
            imageUrl={img}
            altText={`Product Image ${index + 1}`}
          />
        ))}
      </div>
    </section>
  );
};

export default ProductImages;
