import { useEffect, useRef, PropsWithChildren } from 'react';
import { Icons } from '../../assets/SVG-Icons';
import Container from '../container/Container';

interface Props extends PropsWithChildren {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  showHeader: boolean;
  showCloseIcon: boolean;
}

function Modal({
  isOpen,
  onClose,
  title,
  showHeader = true,
  children,
  showCloseIcon = true,
}: Partial<Props>) {
  const modalRef = useRef<HTMLDivElement>(null);

  // const closeModal = () => {
  //   if (onClose) onClose();
  // };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        if (onClose) onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen, onClose]);

  return (
    <>
      {isOpen && (
        <div className='fixed inset-0 flex items-center justify-center z-50'>
          <div className='fixed inset-0 bg-gray-500 bg-opacity-70 backdrop-blur-sm'></div>
          <div
            ref={modalRef}
            className='bg-white overflow-hidden rounded primary-shadow z-10 px-4 pt-2'
          >
            {showHeader && (
              <header className='flex justify-between items-center bg-light-gray-blue pl-4 pr-2 py-2 mb-4'>
                <h3 className='text-left text-xl font-semibold'>{title}</h3>
                {showCloseIcon && (
                  <button
                    className='right-2 text-black hover:text-gray-600 ml-5'
                    onClick={onClose}
                    aria-label='close'
                  >
                    {Icons.closeIcon()}
                  </button>
                )}
              </header>
            )}
            <Container>{children}</Container>
          </div>
        </div>
      )}
    </>
  );
}

export default Modal;
