import React from 'react';
import GetInTouchFormPage from '../b2b/GetInTouchFormPage';

const Support: React.FC = () => {
  return (
    <GetInTouchFormPage
      title='Need Help?'
      paragraph={`We're here for you! Contact us with any questions or technical issues, and we'll be happy to assist`}
    />
  );
};

export default Support;
