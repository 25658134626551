import Button from '../../components/button/Button';
import { Link } from 'react-router-dom';
import FooterLogo from '../../assets/images/Savers App_Horizontal_BW.svg';
import { Icons } from '../../assets/SVG-Icons';
import { FaFacebookF } from 'react-icons/fa6';
import { FaInstagram } from 'react-icons/fa';
import { TiSocialLinkedin } from 'react-icons/ti';
import { useNavigate, useLocation } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <div className='w-full bg-primary text-white'>
      <div className='flex flex-col md:flex-row justify-center md:justify-between p-12 items-start text-center md:text-left'>
        <div className='flex flex-col justify-center md:justify-start mx-auto md:mx-0'>
          {/* <img src={images.WhiteLogo} className='mb-6' alt='' /> */}
          <img
            src={FooterLogo}
            alt=''
            className='mb-6'
            width={188}
            height={30}
          />
          <Link
            className='py-2 font-semibold hover:underline'
            to={'/about-us'}
            onClick={() => {
              if (pathname === '/about-us') {
                window.scrollTo(0, 0);
              }
            }}
          >
            About
          </Link>
          <Link
            className='py-2 font-semibold hover:underline'
            to={'/business-faq'}
            onClick={() => {
              if (pathname === '/business-faq') {
                window.scrollTo(0, 0);
              }
            }}
          >
            FAQ
          </Link>
          {/* <p
              onClick={() => {
                document
                  .getElementById('contact-form')
                  ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
              }}
            className='py-2 font-semibold hover:underline cursor-pointer'
            
          >
            Contact us
          </p> */}
        </div>
        <div className='w-full md:w-auto'>
          <Button
            className='bg-white text-primary px-10 mx-auto my-10 md:my-0 mb-8'
            onClick={() => navigate('/signup')}
          >
            <span className='stroke-white hover:stroke-primary'>
              {Icons.rocket('currentColor')}
            </span>
            <span>Get Started</span>
          </Button>
        </div>
      </div>

      <div className='flex flex-col-reverse mx-auto md:mx-0 md:flex-row justify-between px-4 md:px-12 font-[400] items-center pb-12 md:pb-8'>
        <div>
          <div className='flex flex-col-reverse md:flex-row md:text-[14px] text-center'>
            <p className='font-[400]'>
              © {new Date().getFullYear()} Savers App. All rights reserved.
            </p>
            <div className=' md:mx-0 mb-4 md:mb-0'>
              <Link
                to='/t&c-privacy#termsOfUse'
                className='font-[400] mx-4 underline'
                onClick={() => {
                  if (pathname.includes('t&c-privacy')) {
                    document.getElementById('termsOfUse')?.scrollIntoView({
                      behavior: 'smooth',
                      block: 'start',
                    });
                  }
                }}
              >
                Terms of use
              </Link>
              <Link
                to='/t&c-privacy#privacyPolicy'
                className='font-[400] mx-4 underline'
                onClick={() => {
                  if (pathname.includes('t&c-privacy')) {
                    document.getElementById('privacyPolicy')?.scrollIntoView({
                      behavior: 'smooth',
                      block: 'start',
                    });
                  }
                }}
              >
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>
        <div className='flex flex-row mb-8 md:mb-0' style={{ display: 'none' }}>
          <div className='w-12 h-12 bg-white rounded-full flex items-center justify-center mx-2'>
            <FaFacebookF size={24} className='text-primary' />
          </div>
          <div className='w-12 h-12 bg-white rounded-full flex items-center justify-center mx-2'>
            <FaInstagram size={24} className='text-primary' />
          </div>
          <div className='w-12 h-12 bg-white rounded-full flex items-center justify-center mx-2'>
            <TiSocialLinkedin size={24} className='text-primary' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
