import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Notification } from '../../utils/type';
import {
  FiCheckCircle,
  FiXCircle,
  FiInfo,
  FiAlertCircle,
} from 'react-icons/fi';
import Button from '../../components/button/Button';

// Sample notifications data (could be fetched from an API or passed as props)
const notifications: Notification[] = [
  {
    id: 1,
    title: 'System Update',
    message: 'Your system has been successfully updated.',
    timestamp: '2024-10-10 12:45',
    isRead: false,
    type: 'info',
  },
  {
    id: 2,
    title: 'New Message',
    message:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos quaerat obcaecati quibusdam, id consectetur suscipit assumenda? Commodi atque, maiores temporibus vitae suscipit totam dignissimos deleniti fuga distinctio ullam explicabo fugit odio harum iure culpa tempora laborum ipsum ex necessitatibus debitis vel illum eaque! Commodi, optio et? In quis error vero debitis rerum quo ex nihil laudantium ipsa, explicabo cumque, harum beatae. Officia ut, minima dolor ratione velit ducimus autem earum, minus eaque quod quas sequi quam corporis id reprehenderit accusantium!',
    timestamp: '2024-10-11 09:30',
    isRead: true,
    type: 'success',
  },
  {
    id: 3,
    title: 'Password Expiration',
    message: 'Your password will expire in 5 days.',
    timestamp: '2024-10-12 07:20',
    isRead: false,
    type: 'warning',
  },
];

const NotificationIcon: React.FC<{ type: Notification['type'] }> = ({
  type,
}) => {
  switch (type) {
    case 'success':
      return <FiCheckCircle className='text-green-500 w-6 h-6' />;
    case 'error':
      return <FiXCircle className='text-red-500 w-6 h-6' />;
    case 'info':
      return <FiInfo className='text-blue-500 w-6 h-6' />;
    case 'warning':
      return <FiAlertCircle className='text-yellow-500 w-6 h-6' />;
    default:
      return null;
  }
};

const NotificationDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const notification = notifications.find(n => n.id === parseInt(id || '', 10));

  if (!notification) {
    return (
      <div className='p-6 bg-[#F8F9FF] min-h-screen flex items-center justify-center'>
        <div className='bg-white p-8 rounded-lg primary-shadow text-center'>
          <h2 className='text-2xl font-semibold text-gray-800 mb-4'>
            Notification not found
          </h2>
          <p className='text-gray-600 mb-6'>
            We couldn&apos;t find the notification you&apos;re looking for.
          </p>
          <Button primary onClick={() => navigate(-1)}>
            Go Back
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className='p-6 bg-[#F8F9FF] min-h-screen'>
      <div className='max-w-2xl mx-auto bg-white p-8 rounded-lg primary-shadow'>
        {/* Notification Header */}
        <div className='flex items-center mb-4'>
          <NotificationIcon type={notification.type} />
          <h2 className='text-2xl font-semibold text-gray-800 ml-4'>
            {notification.title}
          </h2>
        </div>

        {/* Notification Body */}
        <p className='text-gray-700 mb-4'>{notification.message}</p>
        <span className='block text-sm text-gray-500 mb-6'>
          {notification.timestamp}
        </span>

        {/* Status */}
        <div className='flex items-center justify-between'>
          <span
            className={`px-3 py-1 rounded-full text-xs font-medium ${
              notification.isRead
                ? 'bg-green-100 text-green-700'
                : 'bg-yellow-100 text-yellow-700'
            }`}
          >
            {notification.isRead ? 'Read' : 'Unread'}
          </span>
          <div></div>
        </div>

        {/* Go back button */}
        <div className='mt-6'>
          <Button primary onClick={() => navigate(-1)}>
            Go Back
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NotificationDetails;
