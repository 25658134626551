import React, { useState } from 'react';
import { images } from '../../constants/images';
import Button from '../button/Button';
import PricingCard from '../PricingCard';
import { Icons } from '../../assets/SVG-Icons';

type PlanKey = 'Pro' | 'Premium' | 'Business+' | 'Enterprise';

type PlanType = {
  setupFee: string;
  monthlyFee: number | string;
  buttonTitle: string;
  isMostPopular?: boolean;
  features: {
    domain: string;
    countriesLanguages: string;
    forUpEnrolledCards: string | null;
    enrolled: { cards: string; additionalCards: string } | string;
    newsletters: string;
    support: string;
    cashbackFrequency: string;
    standoutFeatures: string | JSX.Element;
  };
};

const pricingPlans: Record<PlanKey, PlanType> = {
  'Pro': {
    setupFee: '4,995',
    monthlyFee: '250',
    isMostPopular: true,
    buttonTitle: 'Get started',
    features: {
      domain: 'Yourbrand.Saversapp.com',
      countriesLanguages: 'USA / English',
      forUpEnrolledCards: '1,000',
      enrolled: {
        cards: '1,000',
        additionalCards: '1,000',
      },
      newsletters: '2 / week',
      support: 'Email during business hours',
      cashbackFrequency: 'Monthly',
      standoutFeatures: 'Analytics dashboard',
    },
  },
  'Premium': {
    setupFee: '9,995',
    monthlyFee: '995',
    buttonTitle: 'Available in 2025',
    features: {
      domain: 'Yourbrand.Saversapp.com',
      countriesLanguages: 'USA / English - Spanish',
      forUpEnrolledCards: '10,000',
      enrolled: {
        cards: '10,000',
        additionalCards: '1,000',
      },
      newsletters: '4 / week',
      support: 'Email 24/7',
      cashbackFrequency: 'Monthly',
      standoutFeatures: (
        <div className='space-y-3'>
          <p>Analytics dashboard</p>
          <p>Integrated partner loyalty function</p>
        </div>
      ),
    },
  },
  'Business+': {
    setupFee: '14,995',
    monthlyFee: '1,495',
    buttonTitle: 'Available in 2025',
    features: {
      domain: 'Yourbrand.Saversapp.com',
      countriesLanguages: 'USA / English',
      forUpEnrolledCards: '50,000',
      enrolled: {
        cards: '50,000',
        additionalCards: '1,000',
      },
      newsletters: 'Daily',
      support: 'Dedicated account manager by phone',
      cashbackFrequency: 'Weekly',
      standoutFeatures: (
        <div className='space-y-3'>
          <p>Analytics dashboard</p>
          <p>Integrated partner loyalty function</p>
          <p>Customer segmentation</p>
        </div>
      ),
    },
  },
  'Enterprise': {
    setupFee: 'Requirements based fee',
    monthlyFee: 'Custom',
    buttonTitle: 'Available in 2025',
    features: {
      domain: 'www.your.domain',
      countriesLanguages: 'Custom',
      enrolled: 'Custom',
      forUpEnrolledCards: null,
      newsletters: 'Custom',
      support: 'Dedicated account manager 24/7',
      cashbackFrequency: 'Weekly',
      standoutFeatures: (
        <div className='space-y-3'>
          <p>Analytics dashboard</p>
          <p>Integrated partner loyalty function</p>
          <p>Customer segmentation</p>
          <p>Customized marketing material</p>
        </div>
      ),
    },
  },
};

function PricingSection() {
  const [showFullFeatures, setShowFullFeatures] = useState(false);
  const [activeTab, setActiveTab] = useState('Pro');

  const planKey = activeTab as keyof typeof pricingPlans;

  const handleShowFeatures = () => {
    if (showFullFeatures)
      document.getElementById('pricing')?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    setShowFullFeatures(!showFullFeatures);
  };
  return (
    <>
      <div
        className='bg-cover md:bg-cover md:bg-center hidden sm:block sm:bg-[#38c99f]'
        style={{
          backgroundImage: `url(${
            showFullFeatures ? images.PricingBG : images.PricingSmallBG
          })`,
        }}
      >
        <div className='container-fluid md:container md:text-center md:mx-auto md:py-[2.8rem] lg:px-14'>
          <p
            className='pl-1 text-white text-2xl md:text-[2.375rem] font-semibold md:font-bold sm:uppercase mt-6 md:mt-0'
            style={{ fontFamily: 'Mulish' }}
          >
            Pricing
          </p>
          <p
            className='hidden sm:block  text-white text-[1.25rem] md:text-base pt-3 pb-6 lg:pt-5 lg:pb-16 font-medium'
            style={{ fontFamily: 'Mulish' }}
          >
            Choose the best subscription for your business, with the flexibility
            to change as you grow.
          </p>
          {/* Cards */}
          <div className='grid grid-cols-2 xmd:grid-cols-3 xl:grid-cols-4 gap-6 justify-items-center'>
            {Object.keys(pricingPlans).map(planKey => {
              const plan = pricingPlans[planKey as keyof typeof pricingPlans];
              return (
                <PricingCard
                  key={planKey}
                  title={planKey}
                  buttonTitle={plan.buttonTitle}
                  setupFee={plan.setupFee}
                  monthlyFee={plan.monthlyFee}
                  features={plan.features}
                  showFullFeatures={showFullFeatures}
                  isMostPopular={plan.isMostPopular}
                />
              );
            })}
          </div>
          <Button
            primary
            className='py-4 my-8 md:mb-0 mt-12 md:mx-auto w-[17.3rem] h-[3.75rem] flex gap-4'
            // onClick={() => navigate('/signup')}
            onClick={handleShowFeatures}
          >
            {showFullFeatures ? (
              <p className='flex gap-4'>
                Hide list of features{Icons.upArrow2()}
              </p>
            ) : (
              <p className='flex gap-4'>
                Full list of features{Icons.downArrow2()}
              </p>
            )}
          </Button>
        </div>
      </div>
      {/* Pricing For Mobile */}
      <div className='block sm:hidden'>
        <div className='bg-[#F6F6F6] py-1'>
          <div className='flex flex-col gap-3 px-5 py-6'>
            <h2 className='text-2xl font-bold text-left sm:mb-4'>Pricing</h2>
            <p className='font-normal text-base text-black'>
              Choose the best subscription for your business, with the
              flexibility to change as you grow.
            </p>
          </div>
          <div className='bg-[#f6f6f6]'>
            <div className='grid grid-cols-4 w-full border-b border-[#F6F6F6s] gap-[1px]'>
              {Object.keys(pricingPlans).map(tab => (
                <button
                  key={tab}
                  className={`py-2 px-1 xs:px-4 text-xs xs:text-sm font-bold border-t-2 transition-colors duration-300 ${
                    activeTab === tab
                      ? 'text-black border-primary'
                      : 'text-gray-500 border-transparent bg-white'
                  }`}
                  onClick={() => setActiveTab(tab)}
                >
                  {tab}
                </button>
              ))}
            </div>
          </div>
          <PricingCard
            title={activeTab}
            setupFee={pricingPlans[planKey].setupFee}
            monthlyFee={pricingPlans[planKey].monthlyFee}
            features={pricingPlans[planKey].features}
            showFullFeatures={showFullFeatures}
            isMostPopular={pricingPlans[planKey].isMostPopular}
            buttonTitle={pricingPlans[planKey].buttonTitle}
          />

          <Button
            primary
            className='py-4  my-6 md:mx-auto w-10/12 h-[3.75rem] mx-auto'
            // onClick={() => navigate('/signup')}
            onClick={handleShowFeatures}
          >
            <span>
              {showFullFeatures ? (
                <p className='flex gap-4'>
                  Hide list of features{Icons.upArrow2()}
                </p>
              ) : (
                <p className='flex gap-4'>
                  Full list of features{Icons.downArrow2()}
                </p>
              )}
            </span>
          </Button>
        </div>
      </div>
    </>
  );
}

export default PricingSection;
