import SignUp from '../signup/Main';

const InReviewDashboard = () => {
  return (
    <>
      <SignUp />
      <div className='container flex justify-center text-[1.25rem] md:text-[1.5rem] leading-8 text-center m-auto flex-col gap-8'>
        <h1>
          We have received your details and your program information is being
          reviewed. We will get in touch with you in case any additional
          information is required. Once your program is ready to go live, in
          around 2-4 weeks, we will contact you to get you ready for the launch
          of the program.
        </h1>
        <h1>Stay tuned!</h1>
      </div>
    </>
  );
};
export default InReviewDashboard;
