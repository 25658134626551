import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import toast, { Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../app/hooks';
import { RootState } from '../../app/store'; // Adjust the path to your store
import HistoryRouting from '../../components/HistoryRouting';
import RequiredAsterisk from '../../components/RequiredAsterisk';
import Button from '../../components/button/Button';
import Spinner from '../../components/spinner/Spinner';
import { UpdatePartnerInfo } from '../../features/partnerSlice';
import { setUserDetails } from '../../features/authSlice';

interface CompanyData {
  companyName: string;
  addressOne: string;
  addressTwo: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
  contactEmail: string;
  companyPhone: string;
  salesAgentId: string;
  id: string;
}

const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const phonePattern =
  /^\+?[0-9]{1,4}[\s-]?\(?[0-9]{1,5}\)?[\s-]?[0-9]{1,9}[\s-]?[0-9]{1,9}$/;
const salesIdPattern = /^[0-9]{5}$/;
interface ModalProps {
  isLoading: boolean;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ children, isLoading }) => {
  if (!isLoading) return null;

  return (
    <div className='fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50'>
      <div
        className={`bg-[#fff0] p-8 rounded-lg  relative w-[full] md:w-[27rem] px-4 md:px-8 py-12 text-center m-4 flex justify-center`}
      >
        <p className='text-base text-gray-700'>{children}</p>
      </div>
    </div>
  );
};

const CompanyInfo = () => {
  const { userDetails } = useSelector((state: RootState) => state.auth);
  const dispatch = useAppDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const isLoading = useSelector((state: RootState) => state.partner.loading);
  const userId: any = userDetails?.id;
  const partnerId: any = userDetails?.partner?.id;
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<CompanyData>();

  const onSubmit: SubmitHandler<CompanyData> = async data => {
    try {
      const companyFormData = new FormData();

      companyFormData.append('id', partnerId as string);

      Object.entries(data).forEach(([key, value]) => {
        if (value && value !== null) {
          companyFormData.append(key, value as string);
        } else {
          companyFormData.append(key, '');
        }
      });

      for (let [key, value] of companyFormData.entries() as any) {
        console.log(`${key}: ${value}`);
      }

      toast.success('Company information updated successfully');

      dispatch(UpdatePartnerInfo(userId, companyFormData));
      handleEditToggle();
    } catch (error) {
      console.error('Error updating company information:', error);
      toast.error('Failed to update company information. Please try again.');
    }
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  useEffect(() => {
    const localUserData: any = localStorage.getItem('userDetails');
    if (localUserData) {
      const userDetailsss = JSON.parse(localUserData);
      setUserDetails(userDetailsss);
      dispatch(setUserDetails(userDetailsss));
    }
  }, [dispatch]);

  useEffect(() => {
    if (userDetails?.partner) {
      setValue('companyName', userDetails.partner.name);
      setValue('addressOne', userDetails.partner.address_1);
      setValue('addressTwo', userDetails.partner.address_2);
      setValue('city', userDetails.partner.city);
      setValue('state', userDetails.partner.state);
      setValue('zipcode', userDetails.partner.zipcode);
      setValue('country', userDetails.partner.country);
      setValue('contactEmail', userDetails.partner.email);
      setValue('companyPhone', userDetails.partner.phone);
      setValue('salesAgentId', userDetails.partner.salesAgentId);
    }
  }, [userDetails, setValue, isEditing]);

  if (!userDetails) return null;

  return (
    <>
      <HistoryRouting history='Program Management/Company Information' />
      {/* <PageShdowTitle title={'Company Information'} /> */}
      {userDetails.admin && (
        <div className='my-6 text-right'>
          <button
            type='button'
            onClick={handleEditToggle}
            className='text-[#d50000] text-[1.25rem] pr-1 font-semibold underline'
          >
            {isEditing ? 'Cancel' : 'Edit'}
          </button>
        </div>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='rounded-b-lg primary-shadow'>
          <div className=''>
            <h2 className='text-white bg-primary p-4 px-8 rounded-t-lg text-lg font-semibold'>
              Company Information
            </h2>

            <div className='p-8'>
              <div className='mb-6'>
                <label className='block text-[#000000] text-sm font-[500] mb-2'>
                  Company Name <RequiredAsterisk isEditing={isEditing} />
                </label>
                <input
                  type='text'
                  className='register-input w-full'
                  {...register('companyName', {
                    required: 'Company name is required',
                  })}
                  disabled={!isEditing}
                />
                {errors.companyName && (
                  <span className='text-red-600 text-[0.75rem] font-semibold'>
                    {errors.companyName.message}
                  </span>
                )}
              </div>

              <div className='mb-6 mt-4 grid md:grid-cols-2 gap-x-8 gap-y-4'>
                <div>
                  <label className='block text-[#000000] text-sm font-[500] mb-2'>
                    Address Line 1 <RequiredAsterisk isEditing={isEditing} />
                  </label>
                  <input
                    type='text'
                    className='register-input w-full'
                    {...register('addressOne')}
                    disabled={!isEditing}
                  />
                  {errors.addressOne && (
                    <span className='text-red-600 text-[0.75rem] font-semibold'>
                      {errors.addressOne.message}
                    </span>
                  )}
                </div>
                <div>
                  <label className='block text-[#000000] text-sm font-[500] mb-2'>
                    Address Line 2
                  </label>
                  <input
                    type='text'
                    className='register-input w-full'
                    {...register('addressTwo')}
                    disabled={!isEditing}
                  />
                </div>
              </div>

              <div className='mb-6 mt-4 grid md:grid-cols-2 gap-x-8 gap-y-4'>
                <div>
                  <label className='block text-[#000000] text-sm font-[500] mb-2'>
                    City <RequiredAsterisk isEditing={isEditing} />
                  </label>
                  <input
                    type='text'
                    className='register-input w-full'
                    {...register('city')}
                    disabled={!isEditing}
                  />
                  {errors.city && (
                    <span className='text-red-600 text-[0.75rem] font-semibold'>
                      {errors.city.message}
                    </span>
                  )}
                </div>

                <div>
                  <label className='block text-[#000000] text-sm font-[500] mb-2'>
                    State <RequiredAsterisk isEditing={isEditing} />
                  </label>
                  <input
                    type='text'
                    className='register-input w-full'
                    {...register('state')}
                    disabled={!isEditing}
                  />
                  {errors.state && (
                    <span className='text-red-600 text-[0.75rem] font-semibold'>
                      {errors.state.message}
                    </span>
                  )}
                </div>
              </div>

              <div className='mb-6 mt-4 grid md:grid-cols-2 gap-x-8 gap-y-4'>
                <div>
                  <label className='block text-[#000000] text-sm font-[500] mb-2'>
                    Zip Code <RequiredAsterisk isEditing={isEditing} />
                  </label>
                  <input
                    type='text'
                    className='register-input w-full'
                    {...register('zipcode')}
                    disabled={!isEditing}
                  />
                  {errors.zipcode && (
                    <span className='text-red-600 text-[0.75rem] font-semibold'>
                      {errors.zipcode.message}
                    </span>
                  )}
                </div>

                <div>
                  <label className='block text-[#000000] text-sm font-[500] mb-2'>
                    Country <RequiredAsterisk isEditing={isEditing} />
                  </label>
                  <input
                    type='text'
                    className='register-input w-full'
                    {...register('country')}
                    disabled={!isEditing}
                  />
                  {errors.country && (
                    <span className='text-red-600 text-[0.75rem] font-semibold'>
                      {errors.country.message}
                    </span>
                  )}
                </div>
              </div>

              <div className='mb-6 mt-4 grid md:grid-cols-2 gap-x-8 gap-y-4'>
                <div>
                  <label className='block text-[#000000] text-sm font-[500] mb-2'>
                    Contact Email <RequiredAsterisk isEditing={isEditing} />
                  </label>
                  <input
                    type='email'
                    className='register-input w-full'
                    {...register('contactEmail', {
                      required: 'Contact email is required',
                      pattern: {
                        value: emailPattern,
                        message: 'Invalid email address',
                      },
                    })}
                    disabled={!isEditing}
                  />
                  {errors.contactEmail && (
                    <span className='text-red-600 text-[0.75rem] font-semibold'>
                      {errors.contactEmail.message}
                    </span>
                  )}
                </div>

                <div>
                  <label className='block text-[#000000] text-sm font-[500] mb-2'>
                    Company Phone <RequiredAsterisk isEditing={isEditing} />
                  </label>
                  <input
                    type='text'
                    className='register-input w-full'
                    {...register('companyPhone', {
                      required: 'Company phone is required',
                      pattern: {
                        value: phonePattern,
                        message: 'Invalid phone number',
                      },
                    })}
                    disabled={!isEditing}
                  />
                  {errors.companyPhone && (
                    <span className='text-red-600 text-[0.75rem] font-semibold'>
                      {errors.companyPhone.message}
                    </span>
                  )}
                </div>
              </div>

              <div className='mb-6 mt-4 grid md:grid-cols-2 gap-x-8 gap-y-4'>
                <div>
                  <label className='block text-[#000000] text-sm font-[500] mb-2'>
                    Sales Agent ID <RequiredAsterisk isEditing={isEditing} />
                  </label>
                  <input
                    type='number'
                    className='register-input w-full'
                    {...register('salesAgentId', {
                      required: 'Sales Agent ID is required',
                      pattern: {
                        value: salesIdPattern,
                        message: 'Invalid Sales Agent ID',
                      },
                    })}
                    disabled={!isEditing}
                    readOnly
                  />
                  {errors.salesAgentId && (
                    <span className='text-red-600 text-[0.75rem] font-semibold'>
                      {errors.salesAgentId.message}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Toaster position='top-center' />
          <Modal isLoading={isLoading}>{isLoading && <Spinner />}</Modal>
        </div>
        {isEditing && (
          <div className='mt-8 w-full flex justify-end gap-4'>
            <Button
              type='submit'
              className={`w-full  text-sm px-5 text-white bg-primary border-primary min-w-[7rem] py-2 md:w-[7rem] rounded-[10px] font-semibold`}
            >
              Save Changes
            </Button>
          </div>
        )}
      </form>
    </>
  );
};

export default CompanyInfo;
