import axios from 'axios';
import { PartnerAttrib, setUserStatus } from '../features/authSlice';

export const downloadImage = (img: string, fileName: string = '') => {
  const link = document.createElement('a');
  link.href = img;

  if (img.startsWith('data:')) {
    link.download = fileName; // Use provided or default filename
  } else {
    // Extract filename if it's a standard URL
    link.download = img.substring(img.lastIndexOf('/') + 1).split('.')[0];
  }

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadImageHTTPS = async (url: string): Promise<void> => {
  try {
    const response = await axios.get(url, {
      responseType: 'blob',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Cache-Control': 'no-cache',
      },
    });
    const urlBlob = window.URL.createObjectURL(new Blob([response.data]));

    const link = document.createElement('a');
    link.href = urlBlob;
    link.setAttribute('download', 'qr-code.png');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    console.log('Image downloaded successfully.');
  } catch (error) {
    console.error('Failed to download image:', error);
  }
};

export const getStartAndEndDates = (): {
  startDate: string;
  endDate: string;
} => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  let endDate = today;
  let startDate;
  startDate = new Date(
    tomorrow.getFullYear(),
    tomorrow.getMonth() - 8,
    tomorrow.getDate()
  );

  return {
    startDate: startDate.toISOString().split('T')[0],
    endDate: endDate.toISOString().split('T')[0],
  };
};

export function determineUserStatus(
  partnerAttrib: PartnerAttrib[],
  dispatch: (action: any) => void
) {
  const brandInfoAttrib = partnerAttrib.find(
    (attrib: PartnerAttrib) => attrib.name === 'brandInfo'
  );

  const brandGoLiveAttrib = partnerAttrib.find(
    (attrib: PartnerAttrib) => attrib.name === 'goLive'
  );

  if (brandGoLiveAttrib?.value === 'completed') {
    dispatch(setUserStatus('active'));
  } else if (brandInfoAttrib?.value === 'completed') {
    dispatch(setUserStatus('inreview'));
  } else {
    dispatch(setUserStatus('inactive'));
  }
}
