import { useEffect, useState } from 'react';
import LineChartComp, {
  FilterByDateWithFlag,
} from '../../components/lineChart/LineChart';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import Button from '../../components/button/Button';
import {
  GetDashboardInfo,
  GetGraphTransactionsCounts,
  setGraphTransactionsCounts,
} from '../../features/partnerSlice';
import {
  GetGraphTransactionValues,
  setGraphTransactionValues,
} from '../../features/reportingSlice';
import HistoryRouting from '../../components/HistoryRouting';
import Spinner from '../../components/spinner/Spinner';

const TotalTransactions = () => {
  const [activeTab, setActiveTab] = useState<'1' | '2' | '3'>('1');
  const [customDate, setCustomDate] = useState<FilterByDateWithFlag>({
    startDate: '',
    endDate: '',
    typeName: '',
  });

  const { dashboardInfo, graphTransactionCounts, loadingStates } =
    useAppSelector((state: RootState) => state.partner);
  const { graphTransactionValues, loadingStates: LoadingTransaction } =
    useAppSelector((state: RootState) => state.reporting);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!dashboardInfo) {
      dispatch(GetDashboardInfo());
    }
  }, [dashboardInfo, dispatch]);

  useEffect(() => {
    const { typeName, ...date } = customDate;

    if (typeName === 'TransactionCount') {
      dispatch(GetGraphTransactionsCounts(date));
    } else if (!graphTransactionCounts && activeTab === '1') {
      dispatch(GetGraphTransactionsCounts());
    }
    if (typeName === 'TransactionValue') {
      dispatch(GetGraphTransactionValues(date));
    } else if (!graphTransactionValues && activeTab === '2') {
      dispatch(GetGraphTransactionValues());
    }

    if (typeName) {
      setCustomDate(prev => ({ ...prev, typeName: '' }));
    }
  }, [
    activeTab,
    customDate,
    dispatch,
    graphTransactionCounts,
    graphTransactionValues,
  ]);

  useEffect(() => {
    return () => {
      dispatch(setGraphTransactionsCounts(null));
      dispatch(setGraphTransactionValues(null));
    };
  }, [dispatch]);

  return (
    <div className='mb-8'>
      {/* Toggle Button */}
      <HistoryRouting history='Reporting/Transactions Data' />
      <div className='space-y-10'>
        <div className='space-y-6 border border-gray-200 primary-shadow p-4'>
          <p>
            {/* <span className='font-medium text-2xl'>Reporting / </span> */}
            <span className='font-bold text-2xl'>
              Current Month Transaction Count
            </span>
          </p>
          {loadingStates['GetDashboardInfo'] ? (
            <Spinner />
          ) : (
            <p className='text-xl font-semibold'>
              {' '}
              {dashboardInfo?.txnCount ?? '0'}
            </p>
          )}
        </div>
        <div className='border border-gray-200 primary-shadow px-7 pt-7 pb-5'>
          <div className='flex gap-10'>
            <Button
              className={` ${
                activeTab === '1'
                  ? 'bg-[#00C389] text-white'
                  : 'text-black bg-white'
              }    w-full`}
              onClick={() => setActiveTab('1')}
            >
              Transactions Count
            </Button>
            <Button
              className={` ${
                activeTab === '2'
                  ? 'bg-[#00C389] text-white'
                  : 'text-black bg-white'
              }    w-full`}
              onClick={() => setActiveTab('2')}
            >
              Transactions Value
            </Button>
          </div>
          {/* Display Charts or List View */}
          <div className='grid grid-cols-1 '>
            {activeTab === '1' && (
              <LineChartComp
                data={graphTransactionCounts}
                dataKey='txnCount'
                isFiltering={true}
                name='Transaction Count'
                description='Monthly count of transactions completed by active customers'
                chartName='TransactionCount'
                setCustomDate={setCustomDate}
                isLoading={loadingStates['GetGraphTransactionsCounts']}
                showExport
              />
            )}
            {activeTab === '2' && (
              <LineChartComp
                data={graphTransactionValues}
                isFiltering={true}
                name='Transaction Value'
                dollar={true}
                dataKey='valTransactions'
                description='Monthly value of transactions completed by active customers'
                chartName='TransactionValue'
                setCustomDate={setCustomDate}
                isLoading={LoadingTransaction['GetGraphTransactionValues']}
                showExport
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalTransactions;
