import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import toast, { Toaster } from 'react-hot-toast';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { usersConfig, UserType } from '../../assets/dummy';
import Button from '../../components/button/Button';
import HistoryRouting from '../../components/HistoryRouting';
import Modal from '../../components/modal/Modal';
import RedStar from '../../components/RedStar';
import Table from '../../components/table/Table';
import {
  DeleteUserManagement,
  GetUsersManagement,
  UpdateUserManagement,
} from '../../features/partnerSlice';
import useShowModal from '../../hooks/useShowModal';
import Spinner from '../../components/spinner/Spinner';

export type IUpdateUserManagement = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password: string;
  admin: boolean;
  companyName: string;
  dateCreated?: string;
};
const passwordPattern =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

const initialState: IUpdateUserManagement = {
  id: 0,
  firstName: '',
  lastName: '',
  password: '',
  email: '',
  phone: '',
  companyName: '',
  admin: false,
  dateCreated: '',
};

const UserManagement: React.FC = () => {
  const { closeModal, openModal, showModal } = useShowModal();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showPassword, setshowPassword] = useState(false);
  const userEmail = searchParams.get('email');
  const dispatchApp = useAppDispatch();
  const { userManagement, loadingStates } = useAppSelector(
    (state: RootState) => state.partner
  );
  const { userDetails } = useSelector((state: RootState) => state.auth);

  const [users, setUsers] = useState<UserType[]>([]);
  const userToUpdate = users.find((user: any) => user.email === userEmail);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IUpdateUserManagement>();

  const onDeleteHandler = async (userEmail: number | string) => {
    const result: any = await dispatchApp(DeleteUserManagement(userEmail));
    if (result?.status === 200) {
      toast.success('User deleted successfully');
    }
  };

  const onCloseModal = () => {
    closeModal();
    reset(initialState);
    setshowPassword(false);
  };

  const onSubmit: SubmitHandler<IUpdateUserManagement> = async data => {
    if (!userEmail) {
      const result: any = await dispatchApp(
        UpdateUserManagement({
          ...data,
          companyName: userDetails?.partner?.name,
        })
      );
      if (result?.status === 200) {
        toast.success('User added successfully');
        onCloseModal();
      } else {
        toast.error(result?.response?.data?.message);
      }
    } else {
      const result: any = await dispatchApp(
        UpdateUserManagement({
          ...data,
          companyName: userDetails?.partner?.name,
          id: +users.find(user => user.email === userEmail)?.id!,
        })
      );
      if (result?.status === 200) {
        toast.success('User updated successfully');
        onCloseModal();
        dispatchApp(GetUsersManagement());
        setSearchParams('');
      } else {
        toast.error(result?.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    dispatchApp(GetUsersManagement());
  }, [dispatchApp]);

  useEffect(() => {
    if (userManagement && !userEmail) {
      const updatedUsers = userManagement?.map((user: any) => ({
        id: user.id, // Renumber IDs starting from 1
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phone.replace(/\s|\(|\)|-/g, ''), // Format phone number to remove spaces, parentheses, and hyphens
        dateCreated: new Date(user.created).toLocaleDateString('en-US'), // Format date to MM/DD/YYYY
        role: user.admin ? 'Admin' : 'Regular', // Transform admin to role
        status: user.emailVerification === 1 ? 'Active' : 'Inactive', // Transform emailVerification to status
      }));
      setUsers(updatedUsers);
    }
    if (userEmail && userManagement) {
      if (userToUpdate) {
        reset({
          id: userToUpdate.id,
          firstName: userToUpdate.firstName,
          lastName: userToUpdate.lastName,
          email: userToUpdate.email,
          phone: userToUpdate.phone,
          password: '',
          admin: userToUpdate.role === 'Admin',
          companyName: userDetails?.partner?.name,
        });
      }
    } else {
      reset(initialState); // Reset to initial values when there's no userEmail
    }
  }, [
    userEmail,
    userManagement,
    reset,
    userDetails?.partner?.name,
    userToUpdate,
  ]);

  return (
    <div className='mb-8'>
      <HistoryRouting history='Program Management/Users' />
      <div className='space-y-10 overflow-x-auto overflow-y-hidden'>
        {/* <PageShdowTitle title={'Users'} /> */}
        <div className='flex justify-end items-center mb-4 gap-2 mx-4'>
          {/* <Button onClick={handleExportCSV} primary>
          <span>Export </span> <BsFiletypeCsv size={20} />
        </Button> */}
          <Button onClick={openModal} primary>
            Add new user
          </Button>
        </div>
        {loadingStates['GetUserManagement'] ? (
          <div className='flex justify-center pt-10 min-w-60'>
            <Spinner />
          </div>
        ) : (
          <div className='rounded-b-lg primary-shadow mt-24 w-full'>
            <div className=''>
              <h2 className='text-white bg-primary p-4 px-8 rounded-t-lg text-lg font-semibold shadow-sm'>
                Users
              </h2>
            </div>
            <Table
              data={users}
              config={usersConfig}
              showExportButton={false}
              onEdit={openModal}
              showActions
              width='w-full'
              deleteAction={onDeleteHandler}
            />
          </div>
        )}
      </div>

      <Modal
        isOpen={showModal}
        onClose={() => {
          onCloseModal();
          setSearchParams('');
        }}
        title={userEmail ? 'Update user' : 'Create new user'}
      >
        <div className='p-4 max-w-xl mx-auto w-[20rem] md:w-[50rem]'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-3'>
              <div className='w-full'>
                <label
                  htmlFor='firstName'
                  className='block text-sm font-medium mb-1'
                >
                  First Name <RedStar />
                </label>
                <input
                  type='text'
                  id='firstName'
                  {...register('firstName', {
                    required: 'First Name is required',
                  })}
                  className={`w-full px-3 py-2 border border-gray-300 rounded ${
                    errors.firstName && 'border-red-500'
                  }`}
                  placeholder='First Name'
                />
                {errors.firstName && (
                  <p className='text-red-400 text-sm'>
                    {errors.firstName.message}
                  </p>
                )}
              </div>
              <div className='w-full'>
                <label
                  htmlFor='lastName'
                  className='block text-sm font-medium mb-1'
                >
                  Last Name <RedStar />
                </label>
                <input
                  type='text'
                  {...register('lastName', {
                    required: 'Last Name is required',
                  })}
                  placeholder='Last Name'
                  id='lastName'
                  className='w-full px-3 py-2 border border-gray-300 rounded'
                />{' '}
                {errors.lastName && (
                  <p className='text-red-400 text-sm'>
                    {errors.lastName.message}
                  </p>
                )}
              </div>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-3'>
              <div className='w-full'>
                <label
                  htmlFor='email'
                  className='block text-sm font-medium mb-1'
                >
                  Email <RedStar />
                </label>
                <input
                  type='email'
                  id='email'
                  defaultValue=''
                  placeholder='E-Mail'
                  disabled={!!userEmail}
                  {...register('email', {
                    required: 'Email is required',
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: 'Invalid email address',
                    },
                  })}
                  // value={state.email}
                  // onChange={handleInputChange}
                  className={`w-full px-3 py-2 border border-gray-300 rounded ${
                    errors.email && 'border-red-500'
                  }`}
                />
                {errors.email && (
                  <p className='text-red-400 text-sm'>
                    {errors.email?.message}
                  </p>
                )}
              </div>
              <div className='w-full'>
                <label
                  htmlFor='phone'
                  className='block text-sm font-medium mb-1'
                >
                  Phone <RedStar />
                </label>
                <input
                  type='tel'
                  id='phone'
                  className={`w-full px-3 py-2 border border-gray-300 rounded ${
                    errors.phone && 'border-red-500'
                  }`}
                  disabled={!!userEmail}
                  placeholder='Phone Number'
                  {...register('phone', {
                    required: 'Phone Number is required',
                  })}
                  // value={state.phone}
                  // onChange={handleInputChange}
                />
                {errors.phone && (
                  <p className='text-red-400 text-sm'>{errors.phone.message}</p>
                )}
              </div>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-3'>
              <div className='w-full'>
                <label
                  htmlFor='password'
                  className='block text-sm font-medium mb-1'
                >
                  Password {!userEmail && <RedStar />}
                </label>
                <div className='relative'>
                  <input
                    type={!showPassword ? 'password' : 'text'}
                    id='password'
                    defaultValue=''
                    placeholder='Password'
                    {...register('password', {
                      required: !userEmail ? 'Password is required' : false,
                      pattern: {
                        value: passwordPattern,
                        message:
                          ' Your password must be at least 8 characters long andinclude at least one uppercase letter, one number,and one special character.',
                      },
                    })}
                    // onChange={handleInputChange}
                    className={`w-full px-3 py-2 border border-gray-300 rounded pr-8 ${
                      errors.password && 'border-red-500'
                    }`}
                  />
                  <button
                    type='button'
                    onClick={() => setshowPassword(!showPassword)}
                    className='absolute inset-y-0 right-0 flex items-center px-3 text-gray-500'
                    aria-label='Toggle password visibility'
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
                {errors.password && (
                  <p className='text-red-400 text-sm'>
                    {errors.password.message}
                  </p>
                )}
              </div>
              {/* <div className='w-full'>
                <label
                  htmlFor='confirmPassword'
                  className='block text-sm font-medium mb-1'
                >
                  Confirm Password <RedStar />
                </label>
                <input
                  type='password'
                  id='confirmPassword'
                  value={state.confirmPassword}
                  placeholder='Confirm Password'
                  {...register('confirmPassword', {
                    required: 'Confirm Password Number is required',
                  })}
                  onChange={handleInputChange}
                  className={`w-full px-3 py-2 border border-gray-300 rounded ${
                    errors.confirmPassword && 'border-red-500'
                  }`}
                />
                {errors.confirmPassword && (
                  <p className='text-red-400 text-sm'>
                    {errors.confirmPassword.message}
                  </p>
                )}
              </div> */}
            </div>
            <div className='mb-4 w-full mt-2'>
              <label className='block mt-6 text-md font-normal'>
                <input
                  type='checkbox'
                  {...register('admin')}
                  // name='isAdmin'
                  id='isAdmin'
                  // checked={state.isAdmin}
                  // onChange={handleInputChange}
                  className='mr-2 accent-primary'
                />
                Admin
              </label>
            </div>
            <div className='flex justify-end'>
              <Button primary type='submit'>
                {userEmail ? 'Update' : 'Create user'}
              </Button>
            </div>
          </form>
        </div>
      </Modal>
      <Toaster position='top-center' />
    </div>
  );
};

export default UserManagement;
