import React from 'react';
import { Icons } from '../../assets/SVG-Icons';
import toast, { Toaster } from 'react-hot-toast';

interface SocialMediaCardProps {
  message: string;
  ctaText: string;
  pointText: string;
  onCopy?: () => void;
}

const SocialMediaCard: React.FC<Partial<SocialMediaCardProps>> = ({
  pointText,
  message,
  ctaText,
}) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(`${pointText}: ${message}\nCTA: ${ctaText}`);
    toast.success('Text copied!');
  };
  return (
    <>
      <div className='border border-[#D8D8D8] bg-[#FBFBFB] rounded-md py-5 px-6 text-sm w-full flex flex-col justify-between gap-2'>
        <p className=''>
          <span className='font-semibold'>{pointText}: </span>
          {message}
        </p>
        <div className='flex justify-between items-center'>
          <p className='font-semibold'>CTA: {ctaText}</p>
          <button
            className='bg-[#000000B2] border py-2 px-2.5 rounded '
            onClick={handleCopy}
            aria-label='Copy'
          >
            {Icons.copy2()}
          </button>
        </div>
      </div>
      <Toaster />
    </>
  );
};

export default SocialMediaCard;
