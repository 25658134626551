import axios from 'axios';
import { getCookieValue } from '../App';
import { AppThunk } from '../app/store';
import envConfig from '../utils/config';
import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { getStartAndEndDates } from '../utils/generalFunctions';
import { FilterByDate } from '../utils/type';
import { handleAxiosError } from '../assets/helper';

interface ReportingState {
  graphTransactionValues: any | null;
  graphPartnerEarnings: any | null;
  graphMemberEarnings: any | null;
  graphNewEnrolledCard: any | null;
  graphTotalEnrolledCard: any | null;
  loading: boolean;
  loadingStates: { [key: string]: boolean };
}
const initialState: ReportingState = {
  graphTransactionValues: null,
  graphPartnerEarnings: null,
  graphMemberEarnings: null,
  graphNewEnrolledCard: null,
  graphTotalEnrolledCard: null,
  loading: false,
  loadingStates: {},
};

export const setLoadingStatesFunction = (
  dispatch: Dispatch,
  loadingKey: string
) => {
  dispatch(setLoadingState({ key: loadingKey, value: false }));
};

const ReportingSlice = createSlice({
  name: 'partner',
  initialState,
  reducers: {
    setGraphTransactionValues: (state, action: PayloadAction<any>) => {
      state.graphTransactionValues = action.payload;
    },
    setGraphPartnerEarnings: (state, action: PayloadAction<any>) => {
      state.graphPartnerEarnings = action.payload;
    },
    setGraphMemberEarnings: (state, action: PayloadAction<any>) => {
      state.graphMemberEarnings = action.payload;
    },

    setGraphNewEnrolledCard: (state, action: PayloadAction<any>) => {
      state.graphNewEnrolledCard = action.payload;
    },
    setGraphTotalEnrolledCard: (state, action: PayloadAction<any>) => {
      state.graphTotalEnrolledCard = action.payload;
    },

    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setLoadingState: (
      state,
      action: PayloadAction<{ key: string; value: boolean }>
    ) => {
      state.loadingStates[action.payload.key] = action.payload.value;
    },
  },
});
export const {
  setLoading,
  setLoadingState,
  setGraphTransactionValues,
  setGraphPartnerEarnings,
  setGraphMemberEarnings,
  setGraphNewEnrolledCard,
  setGraphTotalEnrolledCard,
} = ReportingSlice.actions;

export const GetGraphTransactionValues =
  (date?: FilterByDate): AppThunk =>
  async (dispatch, getState) => {
    const loadingKey = 'GetGraphTransactionValues';
    try {
      const { reporting } = getState() as { reporting: ReportingState };
      const token = getCookieValue('token');
      if (!reporting.loadingStates[loadingKey] && token) {
        dispatch(setLoadingState({ key: loadingKey, value: true }));

        const response = await axios.post(
          `${envConfig.REACT_APP_ADMIN_BASE_URL}/rpt/t/v`,
          date ?? getStartAndEndDates(),
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'x-api-key': envConfig.REACT_APP_ADMIN_X_API_KEY,
            },
          }
        );

        if (response.status === 200) {
          dispatch(setGraphTransactionValues(response.data));
          setLoadingStatesFunction(dispatch, loadingKey);
        }
      }
    } catch (error) {
      handleAxiosError(error, dispatch);
      setLoadingStatesFunction(dispatch, loadingKey);

      return error;
    }
  };
export const GetGraphPartnerEarnings =
  (date?: FilterByDate): AppThunk =>
  async (dispatch, getState) => {
    const loadingKey = 'GetGraphPartnerEarnings';
    try {
      const { reporting } = getState() as { reporting: ReportingState };
      const token = getCookieValue('token');
      if (!reporting.loadingStates[loadingKey] && token) {
        dispatch(setLoadingState({ key: loadingKey, value: true }));

        const response = await axios.post(
          `${envConfig.REACT_APP_ADMIN_BASE_URL}/rpt/e/p`,
          date ?? getStartAndEndDates(),
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'x-api-key': envConfig.REACT_APP_ADMIN_X_API_KEY,
            },
          }
        );

        if (response.status === 200) {
          dispatch(setGraphPartnerEarnings(response.data));
          setLoadingStatesFunction(dispatch, loadingKey);
        }
      }
    } catch (error) {
      handleAxiosError(error, dispatch);
      setLoadingStatesFunction(dispatch, loadingKey);

      return error;
    }
  };
export const GetGraphMemberEarnings =
  (date?: FilterByDate): AppThunk =>
  async (dispatch, getState) => {
    const loadingKey = 'GetGraphMemberEarnings';
    try {
      const { reporting } = getState() as { reporting: ReportingState };
      const token = getCookieValue('token');
      if (!reporting.loadingStates[loadingKey] && token) {
        dispatch(setLoadingState({ key: loadingKey, value: true }));

        const response = await axios.post(
          `${envConfig.REACT_APP_ADMIN_BASE_URL}/rpt/e/m`,
          date ?? getStartAndEndDates(),
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'x-api-key': envConfig.REACT_APP_ADMIN_X_API_KEY,
            },
          }
        );

        if (response.status === 200) {
          dispatch(setGraphMemberEarnings(response.data));
          setLoadingStatesFunction(dispatch, loadingKey);
        }
      }
    } catch (error) {
      handleAxiosError(error, dispatch);
      setLoadingStatesFunction(dispatch, loadingKey);

      return error;
    }
  };
export const GetGraphNewEnrolledCard =
  (date?: FilterByDate): AppThunk =>
  async (dispatch, getState) => {
    const loadingKey = 'GetGraphNewEnrolledCard';
    try {
      const { reporting } = getState() as { reporting: ReportingState };
      const token = getCookieValue('token');
      if (!reporting.loadingStates[loadingKey] && token) {
        dispatch(setLoadingState({ key: loadingKey, value: true }));

        const response = await axios.post(
          `${envConfig.REACT_APP_ADMIN_BASE_URL}/rpt/c/n`,
          date ?? getStartAndEndDates(),
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'x-api-key': envConfig.REACT_APP_ADMIN_X_API_KEY,
            },
          }
        );

        if (response.status === 200) {
          dispatch(setGraphNewEnrolledCard(response.data));
          setLoadingStatesFunction(dispatch, loadingKey);
        }
      }
    } catch (error) {
      handleAxiosError(error, dispatch);
      setLoadingStatesFunction(dispatch, loadingKey);
      return error;
    }
  };
export const GetGraphTotalEnrolledCard =
  (date?: FilterByDate): AppThunk =>
  async (dispatch, getState) => {
    const loadingKey = 'GetGraphTotalEnrolledCard';
    try {
      const { reporting } = getState() as { reporting: ReportingState };
      const token = getCookieValue('token');
      if (!reporting.loadingStates[loadingKey] && token) {
        dispatch(setLoadingState({ key: loadingKey, value: true }));

        const response = await axios.post(
          `${envConfig.REACT_APP_ADMIN_BASE_URL}/rpt/c`,
          date ?? getStartAndEndDates(),
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'x-api-key': envConfig.REACT_APP_ADMIN_X_API_KEY,
            },
          }
        );

        if (response.status === 200) {
          dispatch(setGraphTotalEnrolledCard(response.data));
          setLoadingStatesFunction(dispatch, loadingKey);
        }
      }
    } catch (error) {
      handleAxiosError(error, dispatch);
      setLoadingStatesFunction(dispatch, loadingKey);
      return error;
    }
  };

export const ReportingReducer = ReportingSlice.reducer;
