import React, { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store'; // Adjust the path to your store
import Button from '../../components/button/Button';
import toast from 'react-hot-toast';

const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const phonePattern =
  /^\+?[0-9]{1,4}[\s-]?\(?[0-9]{1,5}\)?[\s-]?[0-9]{1,9}[\s-]?[0-9]{1,9}$/;

interface FormData {
  partnerId: string | null;
  firstName: string;
  lastName: string;
  phone: string;
  // salesAgentId: string;
  email: string;
}
const Profile = () => {
  const [partnerId, setPartnerId] = useState<string | null>(null);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      partnerId,
    },
  });
  // const isLoading = useSelector((state: RootState) => state.partner.loading);
  const userDetails = useSelector((state: RootState) => state.auth.userDetails);
  // const dispatch = useDispatch();
  useEffect(() => {
    if (userDetails) {
      console.log(userDetails);
      setPartnerId(userDetails.partnerId);
    }
  }, [userDetails]);
  console.log(partnerId);
  // Fetch userDetails from store and prefill form
  useEffect(() => {
    if (userDetails) {
      setValue('firstName', userDetails.firstName);
      setValue('lastName', userDetails.lastName);
      setValue('email', userDetails.email);
      setValue('phone', userDetails.phone);
      // setValue('salesAgentId', userDetails.partner.salesAgentId);
    }
  }, [userDetails, setValue]);

  const onSubmit: SubmitHandler<FormData> = async data => {
    try {
      if (!partnerId) return;

      // Create FormData for the required fields
      const userFormData = new FormData();

      // Append the partnerId and user details to the form
      userFormData.append('partnerId', partnerId);
      userFormData.append('firstName', data.firstName || '');
      userFormData.append('lastName', data.lastName || '');
      userFormData.append('email', data.email || '');
      userFormData.append('phone', data.phone || '');
      // userFormData.append('salesAgentId', data.salesAgentId || '');

      for (let [key, value] of userFormData.entries() as any) {
        console.log(`${key}: ${value}`);
      }
      // dispatch(updateUserDetails(partnerId, userFormData));
      toast.success('User details updated successfully');
      // dispatch(fetchUserDetails(partnerId));
    } catch (error) {
      console.error('Error updating user details:', error);
      toast.error('Failed to update user details. Please try again.');
    }
  };

  const [isEditing, setIsEditing] = useState(false);

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };
  return (
    <>
      {/* <PageShdowTitle title={'Profile'} /> */}
      <div className='flex justify-between items-center mb-6'>
        {/* <h2 className='text-xl font-semibold mt-2'>Profile</h2> */}
        <button
          onClick={handleEditToggle}
          className='text-[#d50000] hidden text-[1.25rem] pr-1 font-semibold underline'
        >
          {isEditing ? 'Cancel' : 'Edit'}
        </button>
      </div>

      <div className='primary-shadow rounded-lg pb-6 border border-gray-200'>
        <form onSubmit={handleSubmit(onSubmit)} className=' '>
          <h2 className=' text-white bg-primary p-4 px-8 rounded-t-lg text-lg font-semibold'>
            Profile
          </h2>
          <div className='m-8'>
            <div className='mb-0 mt-4'>
              <div className='grid md:grid-cols-2 gap-x-8 gap-y-4'>
                <div>
                  <label className='block text-[#000000] text-sm font-[500] mb-2'>
                    First Name *
                  </label>
                  <input
                    type='text'
                    className='register-input w-full'
                    {...register('firstName', {
                      required: 'First name is required',
                    })}
                    disabled={!isEditing}
                  />
                  {errors.firstName && (
                    <span className='text-red-600 text-[0.75rem] font-semibold'>
                      {errors.firstName.message}
                    </span>
                  )}
                </div>
                <div>
                  <label className='block text-[#000000] text-sm font-[500] mb-2'>
                    Last Name *
                  </label>
                  <input
                    type='text'
                    className='register-input w-full'
                    {...register('lastName', {
                      required: 'Last name is required',
                    })}
                    disabled={!isEditing}
                  />
                  {errors.lastName && (
                    <span className='text-red-600 text-[0.75rem] font-semibold'>
                      {errors.lastName.message}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className='mb-0 mt-4'>
              <div className='grid md:grid-cols-2 gap-x-8 gap-y-4'>
                <div>
                  <label className='block text-[#000000] text-sm font-[500] mb-2'>
                    Email *
                  </label>
                  <input
                    type='email'
                    className='register-input w-full'
                    {...register('email', {
                      required: 'Email is required',
                      pattern: {
                        value: emailPattern,
                        message: 'Invalid email address',
                      },
                    })}
                    disabled={!isEditing}
                  />
                  {errors.email && (
                    <span className='text-red-600 text-[0.75rem] font-semibold'>
                      {errors.email.message}
                    </span>
                  )}
                </div>

                <div>
                  <label className='block text-[#000000] text-sm font-[500] mb-2'>
                    Phone *
                  </label>
                  <input
                    type='text'
                    className='register-input w-full'
                    {...register('phone', {
                      required: 'Phone number is required',
                      pattern: {
                        value: phonePattern,
                        message: 'Invalid phone number',
                      },
                    })}
                    disabled={!isEditing}
                  />
                  {errors.phone && (
                    <span className='text-red-600 text-[0.75rem] font-semibold'>
                      {errors.phone.message}
                    </span>
                  )}
                </div>
              </div>
            </div>

            {isEditing && (
              <div className='w-full flex justify-end gap-4'>
                <Button
                  type='submit'
                  className={`w-full  text-sm px-5 text-white bg-primary border-primary min-w-[7rem] py-2 md:w-[7rem] rounded-[10px] font-semibold`}
                >
                  Save Changes
                </Button>
                {/* <Button
            type='submit'
             disabled
             className={`w-full  text-sm px-5 text-white bg-[#1d1d6661] border-[#1d1d6661] min-w-[7rem] py-2 md:w-[7rem] rounded-[10px] font-semibold`}
           >
             Submit
           </Button> */}
              </div>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default Profile;
