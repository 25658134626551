import React from 'react';

interface Props {
  history: string;
}
function HistoryRouting({ history }: Props) {
  return (
    <p className='mb-7'>
      <span>{history.split('/').slice(0, -1).join(' / ')} / </span>
      <strong>{history.split('/')[history.split('/').length - 1]}</strong>
    </p>
  );
}

export default HistoryRouting;
