import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../app/hooks';
import { RootState } from '../../app/store';
import Button from '../../components/button/Button';
import LineChartComp, {
  FilterByDateWithFlag,
} from '../../components/lineChart/LineChart';
import { GetDashboardInfo } from '../../features/partnerSlice';
import {
  GetGraphNewEnrolledCard,
  GetGraphTotalEnrolledCard,
  setGraphNewEnrolledCard,
  setGraphTotalEnrolledCard,
} from '../../features/reportingSlice';
import HistoryRouting from '../../components/HistoryRouting';
import Spinner from '../../components/spinner/Spinner';

const EnrolledCards = () => {
  const [activeTab, setActiveTab] = useState<'1' | '2'>('1');
  const [customDate, setCustomDate] = useState<FilterByDateWithFlag>({
    startDate: '',
    endDate: '',
    typeName: '',
  });
  const { dashboardInfo } = useSelector((state: RootState) => state.partner);
  const { graphNewEnrolledCard, graphTotalEnrolledCard, loadingStates } =
    useSelector((state: RootState) => state.reporting);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!dashboardInfo) {
      dispatch(GetDashboardInfo());
    }
  }, [dashboardInfo, dispatch]);

  useEffect(() => {
    const { typeName, ...date } = customDate;

    if (typeName === 'NewEnrolled') {
      dispatch(GetGraphNewEnrolledCard(date));
    } else if (!graphNewEnrolledCard && activeTab === '1') {
      dispatch(GetGraphNewEnrolledCard());
    }
    if (typeName === 'TotalEnrolled') {
      dispatch(GetGraphTotalEnrolledCard(date));
    } else if (!graphTotalEnrolledCard && activeTab === '2') {
      dispatch(GetGraphTotalEnrolledCard());
    }

    if (typeName) {
      setCustomDate(prev => ({ ...prev, typeName: '' }));
    }
  }, [
    activeTab,
    customDate,
    dispatch,
    graphNewEnrolledCard,
    graphTotalEnrolledCard,
  ]);
  useEffect(() => {
    return () => {
      dispatch(setGraphNewEnrolledCard(null));
      dispatch(setGraphTotalEnrolledCard(null));
    };
  }, [dispatch]);

  return (
    <div className='mb-8'>
      <HistoryRouting history='Reporting/Cards Data' />
      {/* Toggle Button */}
      <div className='space-y-10'>
        <div className='space-y-6 border border-gray-200 primary-shadow p-4'>
          <p>
            {/* <span className='font-medium text-2xl'>Reporting / </span> */}
            <span className='font-bold text-2xl'>
              Total Enrolled Cards To Date
            </span>
          </p>
          {loadingStates['GetDashboardInfo'] ? (
            <Spinner />
          ) : (
            <p className='text-xl font-semibold'>
              {' '}
              {dashboardInfo?.cardsCount ?? '0'}
            </p>
          )}
        </div>
        <div className='border border-gray-200 primary-shadow px-7 pt-7 pb-5'>
          <div className='flex gap-10'>
            <Button
              className={` ${
                activeTab === '1'
                  ? 'bg-[#00C389] text-white'
                  : 'text-black bg-white'
              }    w-full`}
              onClick={() => setActiveTab('1')}
            >
              New Enrolled Cards Per Month{' '}
            </Button>
            <Button
              className={` ${
                activeTab === '2'
                  ? 'bg-[#00C389] text-white'
                  : 'text-black bg-white'
              }    w-full`}
              onClick={() => setActiveTab('2')}
            >
              Total Enrolled Cards per Month{' '}
            </Button>
          </div>
          {/* Display Charts or List View */}
          <div className='grid grid-cols-1 '>
            {activeTab === '1' && (
              <LineChartComp
                data={graphNewEnrolledCard}
                isFiltering={true}
                name='New Enrolled Cards per Month'
                description='Monthly Count of Newly Enrolled Cards'
                chartName='NewEnrolled'
                setCustomDate={setCustomDate}
                isLoading={loadingStates['GetGraphNewEnrolledCard']}
                showExport
              />
            )}
            {activeTab === '2' && (
              <LineChartComp
                data={graphTotalEnrolledCard}
                isFiltering={true}
                name='Total Enrolled Cards per Month'
                dataKey='totalCards'
                description='Monthly Total Number of Enrolled Cards'
                chartName='TotalEnrolled'
                setCustomDate={setCustomDate}
                isLoading={loadingStates['GetGraphTotalEnrolledCard']}
                showExport
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnrolledCards;
