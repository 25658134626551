import React from 'react';
import Button from '../button/Button';

type PdfCardProps = {
  title: string;
  image?: string | undefined;
  onDownload: () => void;
};

const PdfCard: React.FC<PdfCardProps> = ({ title, onDownload, image }) => {
  return (
    <div className='w-full sm:w-[15rem] max-w-64 p-4 bg-white border border-gray-200 rounded-lg text-center'>
      <div className='flex flex-col justify-center items-center gap-4'>
        <div className='flex justify-center items-center'>
          <img
            src={image}
            alt='PDF Icon'
            className='object-cover min-w-[12.8rem] min-h-[15.3rem]'
          />
        </div>
        <h3 className='text-lg font-semibold text-gray-800'>{title}</h3>
        <Button
          onClick={onDownload}
          className='border border-[#B1B1B1] text-base font-medium mx-auto h-11'
        >
          Download
        </Button>
      </div>
    </div>
  );
};

export default PdfCard;
