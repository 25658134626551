import React, { useCallback, useEffect, useState } from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceArea,
  ReferenceDot,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { FilterByDate } from '../../utils/type';
import Spinner from '../spinner/Spinner';
import { BsFiletypeCsv } from 'react-icons/bs';

export interface FilterByDateWithFlag extends FilterByDate {
  typeName: string;
}
interface DataItem {
  name: string;
  [key: string]: string | number;
}
interface Props {
  title?: string;
  dataKey?: string;
  name?: string;
  description?: string;
  isFiltering?: boolean;
  data?: DataItem[];
  dollar?: boolean;
  defaualtFiltering?: string;
  chartName?: any;
  setCustomDate?: React.Dispatch<React.SetStateAction<FilterByDateWithFlag>>;
  isLoading?: boolean;
  isShadowBox?: boolean;
  showExport?: boolean;
}

const LineChartComp = ({
  title,
  description,
  isFiltering,
  dataKey = 'newCards',
  name = 'New Cards',
  data = [],
  dollar = false,
  defaualtFiltering = '9',
  setCustomDate,
  chartName,
  isLoading,
  isShadowBox,
  showExport,
}: Props) => {
  const [filter, setFilter] = useState(defaualtFiltering);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [dateError, setDateError] = useState<string | null>(null);
  const [maxData, setmaxData] = useState<number>();

  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFilter(e.target.value);
  };

  const handleExport = () => {
    const headers = [
      'Month',
      name ?? chartName.split(/(?=[A-Z])/).join(' ') ?? 'Value',
    ];
    const csvRows = [
      headers.join(','),
      ...data.map(row =>
        Object.keys(row)
          .filter(key => key !== 'amt')
          .map(key =>
            typeof row[key] === 'number' && dollar
              ? `$${(row[key] as number).toFixed(3)}`
              : row[key]
          )
          .join(',')
      ),
    ];

    // Combine rows into a single string
    const csvContent = csvRows.join('\n');

    // Create a Blob for the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv' });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a temporary link element
    const link = document.createElement('a');
    link.href = url;
    link.download = `${name || chartName || 'data'} ${
      data[data.length - 1].name
    } - ${data[0].name}.csv`;

    // Trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up the URL and link
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const validateDates = () => {
    if (fromDate && toDate) {
      const from = new Date(fromDate);
      const to = new Date(toDate);

      if (to <= from) {
        setDateError('The "To" date must be later than the "From" date.');
        return false;
      }
    }
    setDateError(null);
    return true;
  };

  const handleDateChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: 'from' | 'to'
  ) => {
    const value = e.target.value;
    if (type === 'from') {
      setFromDate(value);
    } else {
      setToDate(value);
    }
    validateDates();
  };

  // const filteredData = () => {
  //   const today = new Date();
  //   let startDate, endDate;

  //   if (filter === '3') {
  //     endDate = new Date(today.getFullYear(), today.getMonth(), 1);
  //     endDate.setHours(0, 0, 0, 0);
  //     startDate = new Date(today.getFullYear(), today.getMonth() - 3, 1);
  //     startDate.setHours(12, 0, 0, 0);
  //   } else if (filter === '6') {
  //     endDate = new Date(today.getFullYear(), today.getMonth(), 1);
  //     endDate.setHours(0, 0, 0, 0);
  //     startDate = new Date(today.getFullYear(), today.getMonth() - 6, 1);
  //     startDate.setHours(12, 0, 0, 0);
  //   } else if (filter === '9') {
  //     endDate = new Date(today.getFullYear(), today.getMonth(), 1);
  //     endDate.setHours(0, 0, 0, 0);
  //     startDate = new Date(today.getFullYear(), today.getMonth() - 9, 1);
  //     startDate.setHours(12, 0, 0, 0);
  //   } else if (filter === '12') {
  //     endDate = new Date(today.getFullYear(), today.getMonth(), 1);
  //     endDate.setHours(0, 0, 0, 0);
  //     startDate = new Date(today);
  //     startDate.setFullYear(today.getFullYear(), today.getMonth() - 12, 1);
  //     startDate.setHours(12, 0, 0, 0);
  //   }
  //   if (filter !== 'custom') {
  //     setCustomDate?.({
  //       endDate: endDate?.toISOString() || '',
  //       startDate: startDate?.toISOString() || '',
  //       typeName: chartName || '',
  //     });
  //   }
  // };

  const filteredData = useCallback(() => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    let endDate = today;
    let startDate;

    if (filter === '3') {
      startDate = new Date(
        tomorrow.getFullYear(),
        tomorrow.getMonth() - 2,
        tomorrow.getDate()
      );
    } else if (filter === '6') {
      startDate = new Date(
        tomorrow.getFullYear(),
        tomorrow.getMonth() - 5,
        tomorrow.getDate()
      );
    } else if (filter === '9') {
      startDate = new Date(
        tomorrow.getFullYear(),
        tomorrow.getMonth() - 8,
        tomorrow.getDate()
      );
    } else if (filter === '12') {
      startDate = new Date(today);
      startDate = new Date(
        tomorrow.getFullYear(),
        tomorrow.getMonth() - 11,
        tomorrow.getDate()
      );
    }
    if (filter !== 'custom') {
      setCustomDate?.({
        endDate: endDate?.toISOString().split('T')[0] || '',
        startDate: startDate?.toISOString().split('T')[0] || '',
        typeName: chartName || '',
      });
    }
  }, [filter, chartName, setCustomDate]);

  const handleSubmitDate = () => {
    if (!toDate || !fromDate) return;
    if (validateDates() && setCustomDate) {
      setCustomDate({
        endDate: toDate,
        startDate: fromDate,
        typeName: chartName,
      });
    }
  };
  useEffect(() => {
    filteredData();
  }, [filter, filteredData]);
  useEffect(() => {
    const max = data
      ? Math.max(...data?.map(item => Number(item[dataKey])))
      : 0;
    setmaxData(max);
  }, [data, dataKey]);
  return (
    <div
      className={`flex flex-col ${
        isShadowBox && 'border border-gray-200 primary-shadow px-7 pt-7 pb-5'
      }`}
    >
      {title && <h2 className='font-[600] text-xl text-left'>{title}</h2>}
      <div className='flex justify-between gap-4 my-8 flex-wrap'>
        {isFiltering && (
          // <div className='select-wrapper'>
          <select
            value={filter}
            onChange={handleFilterChange}
            className='p-2 border border-gray-300 rounded cursor-pointer pr-6 h-10'
          >
            <option value='3'>Last 3 Months</option>
            <option value='6'>Last 6 Months</option>
            <option value='9'>Last 9 Months</option>
            <option value='12'>Last 12 Months</option>
            <option value='custom'>Custom</option>
          </select>
          // </div>
        )}

        {filter === 'custom' && (
          <div className='flex flex-col gap-2'>
            <div className='flex items-center gap-2'>
              <label htmlFor='fromDate'>From</label>
              <input
                id='fromDate'
                type='date'
                value={fromDate}
                onChange={e => handleDateChange(e, 'from')}
                className='p-2 border border-gray-300 rounded'
              />
              <label htmlFor='toDate' className='ml-4'>
                To
              </label>

              <input
                type='date'
                id='toDate'
                value={toDate}
                onChange={e => handleDateChange(e, 'to')}
                className='p-2 border border-gray-300 rounded'
              />
              <button
                className={`border border-gray-300 px-4 py-1 rounded ml-2 h-full  whitespace-nowrap ${
                  !toDate || !fromDate
                    ? 'bg-[#ccc] text-black'
                    : 'bg-[#00C389] text-white'
                }`}
                type='button'
                onClick={handleSubmitDate}
                disabled={!toDate || !fromDate}
              >
                Submit Date
              </button>
            </div>
            {dateError && (
              <p className='text-red-500 text-sm text-center'>{dateError}</p>
            )}
          </div>
        )}
        {showExport && !isLoading && (
          <button
            onClick={handleExport}
            className='rounded-lg w-[7rem] h-10 text-white bg-[#333399] flex px-2 py-2 items-center justify-center mb-6'
          >
            <span>Export </span> <BsFiletypeCsv className='mx-2' size={22} />
          </button>
        )}
      </div>
      {isLoading ? (
        <div className='mx-auto my-5'>
          <Spinner />
        </div>
      ) : data ? (
        <>
          <ResponsiveContainer width='100%' height={300}>
            <LineChart
              data={[...data].reverse()}
              margin={{
                top: 5,
                right: maxData && maxData > 99999 ? 30 : 0,
                bottom: 5,
                left: maxData && maxData > 99999 ? 30 : 0,
              }}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='name' />
              <YAxis
                type='number'
                // tickFormatter={value => (dollar ? `${value}$` : value)
                tickFormatter={value =>
                  dollar
                    ? `$${new Intl.NumberFormat().format(value)}`
                    : new Intl.NumberFormat().format(value)
                }
              />
              <Tooltip
                formatter={value => {
                  const formattedValue = new Intl.NumberFormat('en-US').format(
                    +value
                  );
                  return dollar ? `$${formattedValue}` : formattedValue;
                }}
              />

              <Legend />
              {/* <Line
            strokeWidth={2}
            type='monotone'
            dataKey='successfulTransactions'
            stroke='#1133BB'
            activeDot={{ r: 8 }}
            name='Successful Transactions'
          />
          <Line
            strokeWidth={2}
            type='monotone'
            dataKey='pendingTransactions'
            stroke='#c2c2c2'
            activeDot={{ r: 8 }}
            name='Pending Transactions'
          /> */}
              <Line
                strokeWidth={2}
                type='monotone'
                dataKey={dataKey}
                stroke='#1133BB'
                activeDot={{ r: 8 }}
                name={name}
              />
              <ReferenceLine y={0} ifOverflow='extendDomain' />
              {/* <ReferenceLine
            segment={[
              {
                x: 'February',
                y: 100,
              },
              {
                x: 'March',
                y: 15000,
              },
            ]}
            ifOverflow='extendDomain'
          /> */}
              <ReferenceDot x='Page A' y={17000} ifOverflow='discard' />
              <ReferenceArea
                x1='Page D'
                x2='Page E'
                y1={105000}
                y2={17000}
                ifOverflow='visible'
              />
            </LineChart>
          </ResponsiveContainer>
          <p className='my-4 text-sm text-center mt-6'>{description}</p>
        </>
      ) : (
        <p className='italic text-gray-600 text-center'>No data available</p>
      )}
    </div>
  );
};

export default LineChartComp;
