import { FC, PropsWithChildren } from 'react';
import Spinner from '../spinner/Spinner';

interface Props {
  title: string;
  value: string;
  isLoading?: boolean;
  icon?: JSX.Element;
  onClick?: () => void;
}

const Card: FC<PropsWithChildren<Props>> = ({
  title,
  value,
  icon,
  onClick,
  isLoading,
}) => {
  return (
    <div
      onClick={onClick}
      className='flex flex-col gap-3 p-6 border border-gray-200 primary-shadow cursor-pointer bg-white'
    >
      {/* Icon */}
      <div className='w-10 h-10 flex items-center justify-center bg-primaryBg-100 rounded-full'>
        {icon}
      </div>
      {/* Value and Title */}
      <div>
        {isLoading ? (
          <Spinner />
        ) : (
          <p className='text-2xl font-semibold'>{value}</p>
        )}
        <p className='text-sm font-medium text-gray-500'>{title}</p>
      </div>
      {/* Percentage */}
      {/* <div className='text-sm text-green-600 flex items-center gap-1'>
        <span className='font-semibold'>{percentage}</span>
        <span>&uarr;</span>
      </div> */}
    </div>
  );
};

export default Card;
