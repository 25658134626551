import { useEffect } from 'react';
import { AiOutlineCreditCard, AiOutlineSwap } from 'react-icons/ai';
import {
  FaHandHoldingUsd,
  FaMoneyBillWave,
  FaMoneyCheckAlt,
} from 'react-icons/fa';
import { MdGroups } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import BarchartComp from '../../components/barChart/BarChart';
import Card from '../../components/card/Card';
import LineChartComp from '../../components/lineChart/LineChart';
import { NewsItem } from '../../components/news/News';
import {
  GetDashboardInfo,
  GetGraphMemberCounts,
  GetGraphTransactionsCounts,
} from '../../features/partnerSlice';
import PageShdowTitle from '../../components/pageShdowTitle';
export const LINE_CHART_DATA = [
  {
    name: 'Nov',
    uv: 0,
    newCards: 2807,
    amt: 2400,
  },
  {
    name: 'Dec',
    uv: 0,
    newCards: 4444,
    amt: 2400,
  },
  {
    name: 'Jan',
    uv: 0,
    newCards: 5732,
    amt: 2400,
  },
  {
    name: 'Feb',
    uv: 0,
    newCards: 6620,
    amt: 2400,
  },
  {
    name: 'Mar',
    uv: 1000,
    newCards: 5146,
    amt: 2210,
  },
  {
    name: 'Apr',
    uv: 2000,
    newCards: 5029,
    amt: 2290,
  },
  {
    name: 'May',
    uv: 3000,
    newCards: 6199,
    amt: 2000,
  },
  {
    name: 'Jun',
    uv: 4000,
    newCards: 7252,
    amt: 2181,
  },
  {
    name: 'Jul',
    uv: 5000,
    newCards: 7778,
    amt: 1000,
  },
  {
    name: 'Aug',
    uv: 6000,
    newCards: 8889,
    amt: 1000,
  },
  {
    name: 'Sep',
    uv: 0,
    newCards: 10409,
    amt: 2400,
  },
  {
    name: 'Oct',
    uv: 0,
    newCards: 8491,
    amt: 2400,
  },
];

interface CardPath {
  [key: string]: string; // Map card title (string) to route (string)
}
const newsData: NewsItem[] = [
  {
    id: 1,
    title: 'New Cashback Features Launched',
    content:
      'Partners can now enjoy an enhanced cashback system that provides better rewards for both customers and merchants. Explore the latest features to boost your sales!',
    date: 'October 10, 2024',
  },
  {
    id: 2,
    title: 'New US Brands Added to Our Merchants List',
    content:
      'Exciting news! We’ve partnered with top US brands to bring even more shopping options to your customers. Stay tuned for exclusive deals and promotions!',
    date: 'October 8, 2024',
  },
  {
    id: 3,
    title: 'Shopping Season Promotions',
    content:
      'Get ready for the upcoming holiday shopping season with exclusive promotions for your customers. Boost sales with seasonal cashback offers!',
    date: 'October 5, 2024',
  },
  {
    id: 4,
    title: 'Marketing Material Updates',
    content:
      'We’ve released new marketing materials to help you promote cashback offers and boost engagement. Download the latest assets from your admin panel now!',
    date: 'October 1, 2024',
  },
];

const cardPaths: CardPath = {
  'Total Customers': '/reporting/customers-data',
  'Total # Transactions': '/reporting/transactions-data',
  'Total Value Transactions': '/reporting/transactions-data',
  'Partner Earnings': '/reporting/earnings-data',
  'Customer total Earning': '/reporting/earnings-data',
  '# Enrolled Cards': '/reporting/cards-data',
};

const Dashboard = () => {
  const navigate = useNavigate();
  const {
    dashboardInfo,
    graphMemberCounts,
    graphTransactionCounts,
    loadingStates,
  } = useAppSelector((state: RootState) => state.partner);
  const dispatch = useAppDispatch();
  const handleCardClick = (title: string) => {
    const path = cardPaths[title];
    if (path) {
      navigate(path);
    }
  };
  useEffect(() => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    let endDate = today;
    let startDate;
    startDate = new Date(
      tomorrow.getFullYear(),
      tomorrow.getMonth() - 5,
      tomorrow.getDate()
    );
    const date = {
      startDate: startDate?.toISOString().split('T')[0] || '',
      endDate: endDate?.toISOString().split('T')[0] || '',
      typeName: '',
    };
    if (!dashboardInfo) dispatch(GetDashboardInfo());
    dispatch(GetGraphMemberCounts(date));
    dispatch(GetGraphTransactionsCounts(date));
  }, [dispatch, dashboardInfo]);

  return (
    <div className='flex flex-col gap-7 max-w-[85rem] mx-auto'>
      {/* <h3 className='font-bold text-2xl'>Program Overview</h3> */}
      <PageShdowTitle title='Program Overview' />
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5'>
        <Card
          title='Total Members To Date'
          isLoading={loadingStates['GetDashboardInfo']}
          value={dashboardInfo?.userCount ?? '0'}
          onClick={() => handleCardClick('Total Customers')}
          icon={<MdGroups color='#1133BB' size={22} />}
        />
        <Card
          title='Current Month Transaction Count'
          isLoading={loadingStates['GetDashboardInfo']}
          value={dashboardInfo?.txnCount ?? '0'}
          onClick={() => handleCardClick('Total # Transactions')}
          icon={<AiOutlineSwap color='#1133BB' size={22} />}
        />
        <Card
          title='Current Month Transaction Value'
          isLoading={loadingStates['GetDashboardInfo']}
          value={dashboardInfo?.totalValueTxn?.USD?.valueText ?? '0'}
          onClick={() => handleCardClick('Total # Transactions')}
          icon={<FaMoneyCheckAlt color='#1133BB' size={22} />}
        />
        <Card
          title='Total Enrolled Cards To Date'
          isLoading={loadingStates['GetDashboardInfo']}
          value={dashboardInfo?.cardsCount ?? '0'}
          onClick={() => handleCardClick('# Enrolled Cards')}
          icon={<AiOutlineCreditCard color='#1133BB' size={22} />}
        />
        <Card
          title='Current Month Partner Earnings'
          isLoading={loadingStates['GetDashboardInfo']}
          value={dashboardInfo?.totalPartnerEarnings?.USD?.valueText ?? '0'}
          onClick={() => handleCardClick('Partner Earnings')}
          icon={<FaMoneyBillWave color='#1133BB' size={22} />}
        />
        <Card
          title='Current Month Member Earnings'
          isLoading={loadingStates['GetDashboardInfo']}
          value={dashboardInfo?.totalCustomerEarnings?.USD?.valueText ?? '0'}
          onClick={() => handleCardClick('Partner Earnings')}
          icon={<FaHandHoldingUsd color='#1133BB' size={22} />}
        />
      </div>

      <div className='grid grid-cols-1 lg:grid-cols-2 gap-5'>
        <BarchartComp
          defaualtFiltering='6'
          title='Members Count'
          isOneColumn={false}
          // data={BAR_CHART_DATA}
          data={graphMemberCounts ?? []}
          description='Active vs. Inactive Members (Active members are those that have transacted in the respective month)'
          isShadowBox
        />
        <LineChartComp
          defaualtFiltering='6'
          dataKey='txnCount'
          title='Transactions Count'
          name='Transactions Count'
          data={graphTransactionCounts ?? []}
          description='Total number of reward transactions'
          isShadowBox
        />
      </div>

      {/* Admin Info Section */}
      <div className='py-4 md:py-6'>
        <h2 className='text-white bg-primary p-4 px-8 rounded-t-lg text-xl font-semibold'>
          Updates and Reminders
        </h2>
        <div className='p-4 border border-gray-300 rounded-b-lg shadow-md'>
         {/* <div className='mb-8 text-lg'>
            <strong>Next Payout Date:</strong> <span>December 25, 2024</span>
          </div>
          <div className='mb-8 text-lg'>
            <strong>Next Billing Date:</strong> <span>January 1, 2025</span>
          </div>*/}
          <div className='text-lg'>
            <strong>Notifications:</strong>
          </div>
          {newsData.map(item => (
            <div key={item.id} className='p-4 border-b last:border-b-0'>
              <h3 className='font-semibold'>{item.title}</h3>
              <p className='text-sm text-gray-600 mb-2'>{item.date}</p>
              <p className='text-gray-700 mb-4'>{item.content}</p>
            </div>
          ))}
        </div>
      </div>
      {/* <div className='mt-8  border border-gray-300 rounded-lg mb-8'>
        <h2 className='text-white bg-primary p-4 px-8 rounded-t-lg text-lg font-semibold'>
          Updates and Reminders
        </h2>
        <div className=' px-6 py-6 text-[1.25rem]'>
          <div className='mb-4'>
            <strong>Next Payout Date:</strong> <span>October 25, 2024</span>
          </div>
          <div className='mb-4'>
            <strong>Next Billing Date:</strong> <span>November 1, 2024</span>
          </div>
          <h3 className='text-md font-semibold mt-6'>Warnings/Errors/Issues</h3>
          <ul className='list-disc list-inside'>
            <li className='border-b border-gray-300 py-4'>
              Card issues: Please update your card information.
            </li>
            <li className='border-b border-gray-300 py-4'>
              Payment issues: Payment for last month has failed.
            </li>
            <li className='border-b border-gray-300 py-4'>
              Missed payment: You missed the payment due on October 15, 2024.
            </li>
            <li className='border-b border-gray-300 py-4'>
              Need to upgrade: Your current plan does not support more than 500
              enrolled cards.
            </li>
            <li className='border-b border-gray-300 py-4'>
              You exceeded the card enrollment limit: Upgrade your plan to
              enroll more cards.
            </li>
          </ul>
        </div>
      </div> */}
    </div>
  );
};

export default Dashboard;
