import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import Button from '../../components/button/Button';
import HistoryRouting from '../../components/HistoryRouting';
import PageShdowTitle from '../../components/pageShdowTitle';
import PdfCard from '../../components/PdfCard';
import { downloadImageHTTPS } from '../../utils/generalFunctions';

import flyer_1 from '../../assets/images/flyer-1/Flyer_1.png';
import flyer_2a from '../../assets/images/flyer-1/Flyer_2A.png';
import flyer_2b from '../../assets/images/flyer-1/Flyer_2B.png';
import flyer_3a from '../../assets/images/flyer-1/Flyer_3A.png';
import flyer_3b from '../../assets/images/flyer-1/Flyer_3B.png';
import flyer_4 from '../../assets/images/flyer-1/Flyer_4.png';
import flyer_5a from '../../assets/images/flyer-1/Flyer_5A.png';
import flyer_5b from '../../assets/images/flyer-1/Flyer_5B.png';
import flyer_5c from '../../assets/images/flyer-1/Flyer_5C.png';

const pdfFiles = [
  { name: 'Flyer_1.pdf', title: 'Half Letter - Flyer 1', image: flyer_1 },
  { name: 'Flyer_2A.pdf', title: 'Half Letter - Flyer 2A', image: flyer_2a },
  { name: 'Flyer_2B.pdf', title: 'Half Letter - Flyer 2B', image: flyer_2b },
  { name: 'Flyer_3A.pdf', title: 'Half Letter - Flyer 3A', image: flyer_3a },
  { name: 'Flyer_3B.pdf', title: 'Half Letter - Flyer 3B', image: flyer_3b },
  { name: 'Flyer_4.pdf', title: 'Half Letter - Flyer 4', image: flyer_4 },
  { name: 'Flyer_5A.pdf', title: 'Half Letter - Flyer 5A', image: flyer_5a },
  { name: 'Flyer_5B.pdf', title: 'Half Letter - Flyer 5B', image: flyer_5b },
  { name: 'Flyer_5C.pdf', title: 'Half Letter - Flyer 5C', image: flyer_5c },
];

function PrintMeterial() {
  const { brandInfo } = useSelector((state: RootState) => state.partner);
  const [activeTab, setActiveTab] = useState<'flyer1' | 'flyer2' | 'news'>(
    'flyer1'
  );

  const handleDownloadPDF = (fileName: string, title: string) => {
    const filePath = `../../pdf-files/${fileName}`;
    const link = document.createElement('a');
    link.href = filePath;
    link.download = `${title}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <section className='mb-16'>
      <HistoryRouting history='Marketing Material/Print Material' />
      <div className='mb-8'>
        <PageShdowTitle title={'Print Material'} />
      </div>

      <div className='space-y-6 mb-10 text-[15px]'>
        <p>
          When using our pre-designed flyers, you can download them and open
          them in Adobe Acrobat to add your company details in the designated
          fields. Additionally, you must include your{' '}
          <span
            className='cursor-pointer text-blue-700 font-medium'
            onClick={() => downloadImageHTTPS(brandInfo?.qrCode)}
          >
            unique QR code
          </span>
          , which is available in the Partner Portal under Marketing Material
          &gt; QR & Links.
        </p>
        <p>
          If you wish to make further customizations, such as changing colors or
          images, these edits must be done using Adobe Illustrator.
        </p>
        <p>
          For the best results, we recommend sharing the finalized files with a
          professional printing service to ensure high-quality production.
        </p>
      </div>
      <div className='flex gap-5 w-full flex-wrap'>
        <Button
          className={` ${
            activeTab === 'flyer1'
              ? 'bg-[#00C389] text-white'
              : 'text-black bg-white'
          } w-full md:w-fit font-semibold text-lg`}
          onClick={() => setActiveTab('flyer1')}
        >
          Flyer - US Half Letter
        </Button>
        {/* <Button
          className={` ${
            activeTab === 'flyer2'
              ? 'bg-[#00C389] text-white'
              : 'text-black bg-white'
          } w-full md:w-fit font-semibold text-lg`}
          onClick={() => setActiveTab('flyer2')}
        >
          Flyer - US Full Letter
        </Button>
        <Button
          className={` ${
            activeTab === 'news'
              ? 'bg-[#00C389] text-white'
              : 'text-black bg-white'
          } w-full md:w-fit font-semibold text-lg`}
          onClick={() => setActiveTab('news')}
        >
          Newsletters
        </Button> */}
      </div>

      {activeTab === 'flyer1' && (
        <div className='mt-11 flex justify-center sm:justify-start items-center gap-4 md:gap-9 flex-wrap'>
          {pdfFiles.map(file => (
            <PdfCard
              key={file.name}
              title={file.title}
              image={file.image}
              onDownload={() => handleDownloadPDF(file.name, file.title)}
            />
          ))}
        </div>
      )}

      {/* {activeTab === 'flyer2' && (
        <p className='text-xl font-bold text-center mt-12 italic text-gray-500'>
          Coming Soon
        </p>
      )}

      {activeTab === 'news' && (
        <p className='text-xl font-bold text-center mt-12 italic text-gray-500'>
          Coming Soon
        </p>
      )} */}
    </section>
  );
}

export default PrintMeterial;
