import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import toast from 'react-hot-toast';
import { getCookieValue } from '../App';
import { AppThunk } from '../app/store';
import { handleAxiosError } from '../assets/helper';
import { IUpdateUserManagement } from '../pages/userManagement/UserManagement';
import envConfig from '../utils/config';
import { getStartAndEndDates } from '../utils/generalFunctions';
import { FilterByDate } from '../utils/type';
import { fetchUserDetails } from './authSlice';

interface PartnerState {
  brandInfo: any | null;
  paymentInfo: any | null;
  brandInfoDraft: any | null;
  dashboardInfo: any | null;
  graphMemberCounts: any | null;
  graphNewMembersCounts: any | null;
  graphTransactionCounts: any | null;
  cardEnrollment: any | null;
  paymentInfoDraft: any | null;
  userManagement: any | null;
  primaryColor: string;
  secondaryColor: string;
  loading: boolean;
  loadingStates: { [key: string]: boolean };
}

const initialState: PartnerState = {
  brandInfo: null,
  paymentInfo: null,
  brandInfoDraft: null,
  dashboardInfo: null,
  graphMemberCounts: null,
  graphNewMembersCounts: null,
  graphTransactionCounts: null,
  cardEnrollment: null,
  paymentInfoDraft: null,
  userManagement: null,
  primaryColor: '#1133BB',
  secondaryColor: '#66CC99',
  loading: false,
  loadingStates: {},
};

export const setLoadingStatesFunction = (
  dispatch: Dispatch,
  loadingKey: string
) => {
  dispatch(setLoadingState({ key: loadingKey, value: false }));
};

const PartnerSlice = createSlice({
  name: 'partner',
  initialState,
  reducers: {
    setBrandInfo: (state, action: PayloadAction<any>) => {
      state.brandInfo = action.payload;
    },
    setPaymentInfo: (state, action: PayloadAction<any>) => {
      state.paymentInfo = action.payload;
    },
    setPrimaryColor: (state, action: PayloadAction<any>) => {
      state.primaryColor = action.payload;
    },
    setSecondaryColor: (state, action: PayloadAction<any>) => {
      state.primaryColor = action.payload;
    },
    setBrandInfoDraft: (state, action: PayloadAction<any>) => {
      state.brandInfoDraft = action.payload;
    },
    setDashboardInfo: (state, action: PayloadAction<any>) => {
      state.dashboardInfo = action.payload;
    },
    setGraphMemberCounts: (state, action: PayloadAction<any>) => {
      state.graphMemberCounts = action.payload;
    },
    setGraphTransactionsCounts: (state, action: PayloadAction<any>) => {
      state.graphTransactionCounts = action.payload;
    },

    setGraphNewMembersCounts: (state, action: PayloadAction<any>) => {
      state.graphNewMembersCounts = action.payload;
    },
    setCardEnrollment: (state, action: PayloadAction<any>) => {
      state.cardEnrollment = action.payload;
    },
    setPaymentInfoDraft: (state, action: PayloadAction<any>) => {
      state.paymentInfoDraft = action.payload;
    },
    setUserManagement: (state, action: PayloadAction<any>) => {
      state.userManagement = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setLoadingState: (
      state,
      action: PayloadAction<{ key: string; value: boolean }>
    ) => {
      state.loadingStates[action.payload.key] = action.payload.value;
    },
  },
});

export const {
  setBrandInfo,
  setPaymentInfo,
  setPrimaryColor,
  setSecondaryColor,
  setBrandInfoDraft,
  setPaymentInfoDraft,
  setUserManagement,
  setLoading,
  setLoadingState,
  setDashboardInfo,
  setGraphMemberCounts,
  setGraphNewMembersCounts,
  setCardEnrollment,
  setGraphTransactionsCounts,
} = PartnerSlice.actions;

export const PartnerReducer = PartnerSlice.reducer;

export const UpdateBrandInfo =
  (brandInfo: FormData): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setLoading(true)); // Start loading
    const loadingKey = 'UpdateBrandInfoState';
    try {
      const token = getCookieValue('token');
      const { partner } = getState() as { partner: PartnerState };
      if (!partner.loadingStates[loadingKey]) {
        dispatch(setLoadingState({ key: loadingKey, value: true }));
        const response = await axios.post(
          `${envConfig.REACT_APP_ADMIN_BASE_URL}/pmo/b/u`,
          brandInfo,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'x-api-key': envConfig.REACT_APP_ADMIN_X_API_KEY,
              'Content-Type': 'multipart/form-data',
              'Accept': '*/*',
            },
          }
        );
        if (response.status === 200) {
          toast.success('form saved successfully');
          console.log('brandInfo: ', brandInfo);
          const brandInfoField = brandInfo.get('brandInfo');
          console.log('brandInfoField: ', brandInfoField);
          // if (brandInfoField === 'completed') {
          // dispatch(setUserStatus('inreview'));
          // }
          dispatch(GetBrandInfo());
          dispatch(setLoading(false)); // Stop loading
          setLoadingStatesFunction(dispatch, loadingKey);
        }

        return response;
      }
    } catch (error) {
      handleAxiosError(error, dispatch);
      dispatch(setLoading(false)); // Stop loading
      setLoadingStatesFunction(dispatch, loadingKey);
      return error;
    }
  };

export const GetDashboardInfo = (): AppThunk => async (dispatch, getState) => {
  const loadingKey = 'GetDashboardInfo';
  try {
    const { partner } = getState() as { partner: PartnerState };
    const token = getCookieValue('token');
    if (!partner.loadingStates[loadingKey] && token) {
      dispatch(setLoadingState({ key: loadingKey, value: true }));

      const response = await axios.post(
        // need to review response
        `${envConfig.REACT_APP_ADMIN_BASE_URL}/pmo/d`,
        {},
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'x-api-key': envConfig.REACT_APP_ADMIN_X_API_KEY,
          },
        }
      );

      if (response.status === 200) {
        dispatch(setDashboardInfo(response.data));
        setLoadingStatesFunction(dispatch, loadingKey);
      }
    }
  } catch (error) {
    handleAxiosError(error, dispatch);
    setLoadingStatesFunction(dispatch, loadingKey);
    return error;
  }
};

export const GetGraphNewMembersCounts =
  (date?: FilterByDate): AppThunk =>
  async (dispatch, getState) => {
    const loadingKey = 'GetGraphNewMembersCounts';
    try {
      const { partner } = getState() as { partner: PartnerState };
      const token = getCookieValue('token');
      if (!partner.loadingStates[loadingKey] && token) {
        dispatch(setLoadingState({ key: loadingKey, value: true }));

        const response = await axios.post(
          `${envConfig.REACT_APP_ADMIN_BASE_URL}/rpt/m/n`,
          date ?? getStartAndEndDates(),
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'x-api-key': envConfig.REACT_APP_ADMIN_X_API_KEY,
            },
          }
        );

        if (response.status === 200) {
          dispatch(setGraphNewMembersCounts(response.data));
          setLoadingStatesFunction(dispatch, loadingKey);
        }
      }
    } catch (error) {
      handleAxiosError(error, dispatch);
      setLoadingStatesFunction(dispatch, loadingKey);

      return error;
    }
  };
export const GetGraphTransactionsCounts =
  (date?: FilterByDate): AppThunk =>
  async (dispatch, getState) => {
    const loadingKey = 'GetGraphTransactionsCounts';
    try {
      const { partner } = getState() as { partner: PartnerState };
      const token = getCookieValue('token');
      if (!partner.loadingStates[loadingKey] && token) {
        dispatch(setLoadingState({ key: loadingKey, value: true }));

        const response = await axios.post(
          `${envConfig.REACT_APP_ADMIN_BASE_URL}/rpt/t`,
          date ?? getStartAndEndDates(),
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'x-api-key': envConfig.REACT_APP_ADMIN_X_API_KEY,
            },
          }
        );

        if (response.status === 200) {
          dispatch(setGraphTransactionsCounts(response.data));
          setLoadingStatesFunction(dispatch, loadingKey);
        }
      }
    } catch (error) {
      handleAxiosError(error, dispatch);
      setLoadingStatesFunction(dispatch, loadingKey);

      return error;
    }
  };

export const GetCardEnrollment =
  (date?: FilterByDate): AppThunk =>
  async (dispatch, getState) => {
    const loadingKey = 'GetCardEnrollment';
    try {
      const { partner } = getState() as { partner: PartnerState };
      const token = getCookieValue('token');
      if (!partner.loadingStates[loadingKey] && token) {
        dispatch(setLoadingState({ key: loadingKey, value: true }));

        const response = await axios.post(
          `${envConfig.REACT_APP_ADMIN_BASE_URL}/rpt/m/c`,
          date ?? getStartAndEndDates(),
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'x-api-key': envConfig.REACT_APP_ADMIN_X_API_KEY,
            },
          }
        );

        if (response.status === 200) {
          dispatch(setCardEnrollment(response.data));
          setLoadingStatesFunction(dispatch, loadingKey);
        }
      }
    } catch (error) {
      handleAxiosError(error, dispatch);
      setLoadingStatesFunction(dispatch, loadingKey);

      return error;
    }
  };

export const GetGraphMemberCounts =
  (date?: FilterByDate): AppThunk =>
  async (dispatch, getState) => {
    const loadingKey = 'GetGraphMemberCounts';

    try {
      const { partner } = getState() as { partner: PartnerState };
      const token = getCookieValue('token');
      if (!partner.loadingStates[loadingKey] && token) {
        dispatch(setLoadingState({ key: loadingKey, value: true }));

        const response = await axios.post(
          `${envConfig.REACT_APP_ADMIN_BASE_URL}/rpt/m`,
          date ?? getStartAndEndDates(),
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'x-api-key': envConfig.REACT_APP_ADMIN_X_API_KEY,
            },
          }
        );

        if (response.status === 200) {
          dispatch(setGraphMemberCounts(response.data));
          setLoadingStatesFunction(dispatch, loadingKey);
        }
      }
    } catch (error) {
      handleAxiosError(error, dispatch);
      setLoadingStatesFunction(dispatch, loadingKey);
      return error;
    }
  };

export const GetBrandInfo = (): AppThunk => async (dispatch, getState) => {
  const loadingKey = 'GetBrandInfoState';
  try {
    const { partner } = getState() as { partner: PartnerState };
    const token = getCookieValue('token');
    if (!partner.loadingStates[loadingKey]) {
      dispatch(setLoadingState({ key: loadingKey, value: true }));
      const response = await axios.post(
        `${envConfig.REACT_APP_ADMIN_BASE_URL}/pmo/b/g`,
        {},
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'x-api-key': envConfig.REACT_APP_ADMIN_X_API_KEY,
          },
        }
      );
      if (response.status === 200) {
        dispatch(setBrandInfo(response.data));
        setLoadingStatesFunction(dispatch, loadingKey);
      }
    }
  } catch (error) {
    handleAxiosError(error, dispatch);
    setLoadingStatesFunction(dispatch, loadingKey);

    return error;
  }
};

export const UpdateBankInfo =
  (partnerId: string, payoutInfo: FormData): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setLoading(true)); // Start loading
    const loadingKey = 'UpdateBankInfoState';
    console.log('firstfirstfirstfirstfirstfirst11111');

    try {
      const { partner } = getState() as { partner: PartnerState };
      const token = getCookieValue('token');
      if (!partner.loadingStates[loadingKey]) {
        console.log('firstfirstfirstfirstfirstfirst22222');

        dispatch(setLoadingState({ key: loadingKey, value: true }));
        const response = await axios.post(
          `${envConfig.REACT_APP_ADMIN_BASE_URL}/pmo/a/b/a`,
          payoutInfo,
          {
            headers: {
              'x-api-key': envConfig.REACT_APP_ADMIN_X_API_KEY,
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          dispatch(setLoading(false)); // Stop loading
          setLoadingStatesFunction(dispatch, loadingKey);
          dispatch(GetBankInfo());
          if (window.location.href.includes('/payout-account')) {
            toast.success('Form saved successfully');
          }
        }

        return response;
      }
    } catch (error) {
      console.log('firstfirstfirstfirstfirstfirst3333');

      handleAxiosError(error, dispatch);
      dispatch(setLoading(false)); // Stop loading
      setLoadingStatesFunction(dispatch, loadingKey);
      return error;
    }
  };

export const GetBankInfo = (): AppThunk => async (dispatch, getState) => {
  const loadingKey = 'GetBankInfoState';
  try {
    const { partner } = getState() as { partner: PartnerState };
    const token = getCookieValue('token');
    if (!partner.loadingStates[loadingKey]) {
      dispatch(setLoadingState({ key: loadingKey, value: true }));
      const response = await axios.post(
        `${envConfig.REACT_APP_ADMIN_BASE_URL}/pmo/a/b/g`,
        {},
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'x-api-key': envConfig.REACT_APP_ADMIN_X_API_KEY,
          },
        }
      );

      if (response.status === 200) {
        dispatch(setPaymentInfo(response.data));
        setLoadingStatesFunction(dispatch, loadingKey);
      } else {
        console.log('Loading in progress, skipping dispatch.');
      }
    }
  } catch (error) {
    handleAxiosError(error, dispatch);
    setLoadingStatesFunction(dispatch, loadingKey);
    return error;
  }
};

export const ContactUs =
  (formData: any): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setLoading(true));
    const loadingKey = 'ContactUsState';
    try {
      const { partner } = getState() as { partner: PartnerState };
      if (!partner.loadingStates[loadingKey]) {
        dispatch(setLoadingState({ key: loadingKey, value: true }));
        const response = await axios.post(
          `${envConfig.REACT_APP_ADMIN_BASE_URL}/sys/sys/s`,
          formData,
          {
            headers: {
              'x-api-key': envConfig.REACT_APP_ADMIN_X_API_KEY,
              'Content-Type': 'application/json',
            },
          }
        );
        return response;
      }
    } catch (error) {
      console.log('error', error);
      return error;
    } finally {
      dispatch(setLoading(false));
      setLoadingStatesFunction(dispatch, loadingKey);
    }
  };

export const UpdatePartnerInfo =
  (userId: string, partnerInfo: FormData): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setLoading(true));
    const loadingKey = 'UpdatePartnerInfoState';
    partnerInfo.delete('zipcode');
    partnerInfo.delete('salesAgentId');
    try {
      const { partner } = getState() as { partner: PartnerState };
      const token = getCookieValue('token');
      if (!partner.loadingStates[loadingKey]) {
        dispatch(setLoadingState({ key: loadingKey, value: true }));
        const response = await axios.post(
          `${envConfig.REACT_APP_ADMIN_BASE_URL}/pmo/u`,
          partnerInfo,
          {
            headers: {
              'x-api-key': envConfig.REACT_APP_ADMIN_X_API_KEY,
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          dispatch(fetchUserDetails(userId));
          dispatch(setLoading(false));
          setLoadingStatesFunction(dispatch, loadingKey);
        }

        return response;
      }
    } catch (error) {
      handleAxiosError(error, dispatch);
      dispatch(setLoading(false));
      setLoadingStatesFunction(dispatch, loadingKey);
      return error;
    }
  };

export const GetUsersManagement =
  (): AppThunk => async (dispatch, getState) => {
    const loadingKey = 'GetUserManagement';
    try {
      const { partner } = getState() as { partner: PartnerState };
      const token = getCookieValue('token');
      if (!partner.loadingStates[loadingKey]) {
        dispatch(setLoadingState({ key: loadingKey, value: true }));
        const response = await axios.post(
          `${envConfig.REACT_APP_ADMIN_BASE_URL}/pmo/u/l`, // API endpoint
          {}, // No body is required
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'x-api-key': envConfig.REACT_APP_ADMIN_X_API_KEY,
            },
          }
        );

        if (response.status === 200) {
          dispatch(setUserManagement(response.data));
          setLoadingStatesFunction(dispatch, loadingKey);
        } else {
          console.log('Loading in progress, skipping dispatch.');
        }
      }
    } catch (error) {
      handleAxiosError(error, dispatch);
      setLoadingStatesFunction(dispatch, loadingKey);

      return error;
    }
  };

export const UpdateUserManagement =
  (userMangementInfo: IUpdateUserManagement): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setLoading(true));
    const loadingKey = 'UpdateUserManagement';

    try {
      const { partner } = getState() as { partner: PartnerState };
      const token = getCookieValue('token');
      if (!partner.loadingStates[loadingKey]) {
        dispatch(setLoadingState({ key: loadingKey, value: true }));
        const response = await axios.post(
          `${envConfig.REACT_APP_ADMIN_BASE_URL}/pmo/u/${
            userMangementInfo?.id ? 'u' : 'c'
          }`,
          userMangementInfo,
          {
            headers: {
              'x-api-key': envConfig.REACT_APP_ADMIN_X_API_KEY,
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          dispatch(GetUsersManagement());
          dispatch(setLoading(false));
          setLoadingStatesFunction(dispatch, loadingKey);
        }

        return response;
      }
    } catch (error) {
      handleAxiosError(error, dispatch);
      dispatch(setLoading(false));
      setLoadingStatesFunction(dispatch, loadingKey);
      return error;
    }
  };

export const DeleteUserManagement =
  (userId: string | number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setLoading(true));
    const loadingKey = 'DeleteUserManagement';

    try {
      const { partner } = getState() as { partner: PartnerState };
      const token = getCookieValue('token');
      if (!partner.loadingStates[loadingKey]) {
        dispatch(setLoadingState({ key: loadingKey, value: true }));
        const response = await axios.post(
          `${envConfig.REACT_APP_ADMIN_BASE_URL}/pmo/u/d`,
          { id: userId },
          {
            headers: {
              'x-api-key': envConfig.REACT_APP_ADMIN_X_API_KEY,
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          dispatch(GetUsersManagement());
          dispatch(setLoading(false));
          setLoadingStatesFunction(dispatch, loadingKey);
        }

        return response;
      }
    } catch (error) {
      handleAxiosError(error, dispatch);
      dispatch(setLoading(false));
      setLoadingStatesFunction(dispatch, loadingKey);
      return error;
    }
  };

export default PartnerSlice.reducer;
