import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icons } from '../../assets/SVG-Icons';
import Button from '../../components/button/Button';
import { images } from '../../constants/images';
import GetInTouchForm from './GetInTouchForm';
// import ClientReview from './ClientReview';
import Footer from './Footer';
import QuestionsDropdown from './QuestionsDropdown';
import PricingSection from '../../components/PricingSection';

const B2BLanding = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeStep, setActiveStep] = useState(1);

  // Function to render content based on active step
  // const renderContent = () => {
  //   switch (activeStep) {
  //     case 1:
  //       return <p className="mt-4">Content for Step 1 goes here.</p>;
  //     case 2:
  //       return <p className="mt-4">Content for Step 2 goes here.</p>;
  //     case 3:
  //       return <p className="mt-4">Content for Step 3 goes here.</p>;
  //     default:
  //       return null;
  //   }
  // };
  useEffect(() => {
    // Smooth scroll to the element if there's a hash in the URL
    if (location.hash) {
      const elementId = location.hash.substring(1);
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [location]);
  return (
    <>
      <div className='flex md:hidden flex-grow-0 bg-[#F8F9FF] align-middle '>
        <div className='flex items-center w-1/2 p-4 pr-0'>
          <h2 className=' text-[1.5rem] leading-[1.65rem] font-bold md:mb-4'>
            Reward your customers – Strengthen your brand.
          </h2>
        </div>
        <div className='w-1/2 md:p-4'>
          <img
            src={images.b2bBanner1}
            alt='Banner 1'
            className='w-full h-auto object-cover'
          />
        </div>
      </div>
      <div className=' bg-[#F8F9FF] flex justify-end'>
        {/* Mobile view */}

        <div className=' flex flex-col-reverse md:flex-row items-center'>
          {/* Left Section (Text) */}
          <div className='w-full md:w-1/2 p-4 md:ml-[8rem]'>
            <h2 className='hidden md:block text-[2.75rem] md:text-[3rem] leading-[3rem] font-bold mb-4'>
              Reward your customers – <br />
              Strengthen your brand.
            </h2>
            <p className='text-base md:text-[1.25rem] md:leading-[31px] font-[400] text-[#2D2D2D] w-full md:w-[90%]'>
              Savers App offers all market sectors and industries an opportunity
              to benefit from enhancing their brand by building customer loyalty
              through their own co-branded cashback app including tens of
              thousands of card-linked and online offers. Any company or
              organization can easily integrate our solution into their
              marketing platform without disrupting core business operations.
            </p>
            <Button
              primary
              className='px-10 my-8 mt-12 w-full md:w-[14rem] h-[3.75rem]'
              onClick={() => navigate('/signup')}
            >
              <span className='stroke-white hover:stroke-primary'>
                {Icons.rocket('currentColor')}
              </span>
              <span>Get Started</span>
            </Button>
          </div>

          {/* Right Section (Image) */}
          <div className='hidden md:block w-full md:w-1/2'>
            <img
              src={images.b2bBanner1}
              alt='Banner 1'
              className='w-full h-auto object-cover'
            />
          </div>
        </div>
      </div>
      <div className='flex flex-col xmd:flex-row justify-center items-center gap-3 xmd:gap-10 container-fluid md:container mx-auto mt-16 mb-10 xmd:mb-0'>
        <iframe
          // width='560'
          className='rounded-2xl w-full max-w-xl'
          height='315'
          src='https://www.youtube.com/embed/2weHYz4fxjA?si=6kvM8fdI_VGqOkCn'
          title='YouTube video player'
          style={{ border: 'none' }}
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          referrerPolicy='strict-origin-when-cross-origin'
          allowFullScreen
        ></iframe>
        <div className='flex flex-col justify-center items-center xmd:items-start w-full max-w-xl'>
          <p className='text-primary text-2xl md:text-5xl my-4 md:my-8 font-bold'>
            Watch Our Video
          </p>
          <p className='text-base md:text-xl text-center xmd:text-start leading-6'>
            See how our Loyalty Program app boosts customer engagement and
            drives sales.
          </p>
        </div>
      </div>
      <div className='bg-[#F8F9FF]'>
        <div className='container-fluid md:container md:my-8 md:mt-16 mx-auto text-left md:text-center '>
          <h2 className='text-2xl md:text-[2.375rem] my-4 md:my-8 font-bold text-[#1133BB]'>
            Leverage flexible and innovative tech.
          </h2>
          <p className='text-base md:text-[1.25rem] my-4 md:my-8 center w-full md:w-[70%] mx-auto leading-6'>
            You can trust on our cutting-edge technology to provide seamless and
            smarter shopping experiences for your customers, leading to greater
            success for your business. Proven at scale and built on the
            industry’s most comprehensive dataset, our technology also leverages
            AI and machine learning to become smarter and more powerful with
            each interaction.
          </p>
          <img
            src={images.b2bBanner2}
            alt='Banner 1'
            className='w-full md:w-[65%] mx-auto h-auto object-cover mt-12 mb-0 md:my-16'
          />
        </div>
      </div>

      <div className='container-fluid md:container mx-auto '>
        <h2 className='text-2xl md:text-[2.375rem] md:leading-10 md:ml-4 my-4 md:my-8 font-bold text-[#1133BB]'>
          A value-based{' '}
          <span className='md:hidden'>
            {' '}
            shopping <br /> model.
          </span>{' '}
          <span className='md:block hidden'> shopping model.</span>
        </h2>

        {/* Left Section (Text) */}
        <div className='flex flex-col md:flex-row items-center'>
          <div className='w-full md:w-1/2 md:p-4'>
            <p className='text-base md:text-[1.25rem] md:leading-[31px] font-[400] text-[#2D2D2D] md:w-[90%]'>
              The customized partner app offers its members (whether they are
              customers, employees, or community members) cashback directly to
              their Mastercard or Visa cards when used at any of over 20,000
              locations across the U.S. Through the app, members can also access
              cashback savings at more than 850,000 hotels worldwide, as well as
              book flights and take advantage of great travel deals.
              Additionally, they can shop online at leading brand-name stores
              that offer cashback rewards.
              <br />
              <br /> We deliver a value-driven shopping experience through a
              branded app that provides card-linked and online cashback offers
              tailored to each customer, meeting and exceeding their rewards
              expectations.
            </p>
            <Button
              primary
              className='px-10 my-8 mt-12 w-full md:w-[14rem] h-[3.75rem]'
              onClick={() => navigate('/signup')}
            >
              <span className='stroke-white hover:stroke-primary'>
                {Icons.rocket('currentColor')}
              </span>
              <span>Get Started</span>
            </Button>
          </div>

          {/* Right Section (Image) */}
          <div className='w-full md:w-1/2 p-4 md:flex justify-end hidden'>
            <img
              src={images.b2bBanner3}
              alt='Banner 1'
              className='w-[80%] h-auto object-cover mt-[-9rem]'
            />
          </div>
          <div className='w-full md:w-1/2 pr-8 pb-6 flex justify-end md:hidden'>
            <img
              src={images.b2bBanner3}
              alt='Banner 1'
              className=' h-auto object-cover'
            />
          </div>
        </div>
      </div>
      {/* <div className='hidden container md:flex center mx-auto'>
        <img src={images.b2bCompanies} alt='' className='w-full' />
      </div> */}
      {/* Mobile scrolling logos */}
      <div className='bg-[#F8F9FF]'>
        <div className='companies-image-container mt-4 block mx-auto'>
          <hr className='text-gray-500 w-full py-2 md:py-4 md:w-[90%] mx-auto' />
          <div className='companies-image-row md:justify-center'>
            <div className='company-images md:gap-4'>
              <img className='w-[6rem] md:w-[7rem]' src={images.ihop} alt='' />
              <img
                className='w-[6rem] md:w-[7rem]'
                src={images.applebees}
                alt=''
              />
              <img
                className='w-[6rem] md:w-[7rem]'
                src={images.booking}
                alt=''
              />
              <img className='w-[6rem] md:w-[7rem]' src={images.macys} alt='' />
              <img
                className='w-[6rem] md:w-[7rem]'
                src={images.walmart}
                alt=''
              />
              <img
                className='w-[6rem] md:w-[7rem]'
                src={images.expedia}
                alt=''
              />
              <img
                className='w-[6rem] md:w-[7rem]'
                src={images.riteAid}
                alt=''
              />
              <img
                className='w-[6rem] md:w-[7rem]'
                src={images.cvsPharmacy}
                alt=''
              />
              <img
                className='w-[6rem] md:w-[7rem]'
                src={images.newegg}
                alt=''
              />
            </div>
          </div>
          <div className='companies-image-row md:justify-center'>
            <div className='company-images md:gap-4'>
              <img className='w-[6rem]' src={images.bestBuy} alt='' />
              <img className='w-[6rem]' src={images.outback} alt='' />
              <img className='w-[6rem]' src={images.sephora} alt='' />
              <img className='w-[6rem]' src={images.temu} alt='' />
              <img className='w-[6rem]' src={images.samsClub} alt='' />
              <img className='w-[6rem]' src={images.target} alt='' />
              <img className='w-[6rem]' src={images.kroger} alt='' />
              <img className='w-[6rem]' src={images.apple} alt='' />
              <img className='w-[6rem]' src={images.hotels} alt='' />
              <img className='w-[6rem]' src={images.jd} alt='' />
            </div>
          </div>
          <hr className='text-gray-500 w-full mt-5 md:mt-5 md:w-[90%] mx-auto' />
        </div>
      </div>

      <div id='how-it-works'></div>
      <div className='container-fluid md:container mx-auto mb-16'>
        <h2 className='text-2xl md:text-[2.375rem] md:leading-3 mt-12 md:mt-20 mb-6 text-center font-bold text-[#1133BB]'>
          How it works.
        </h2>
        <p className='text-center text-[1.5rem] leading-5 mb-8 md:mb-12 font-medium'>
          It’s as easy as 1-2-3
        </p>

        <div className='text-center'>
          {/* Image Container */}
          <div className='hidden md:flex justify-center items-center space-x-4'>
            {/* Step 1 Image */}
            <img
              src={activeStep === 1 ? images.step1Expanded : images.step1}
              alt='Step 1'
              className='md:cursor-pointer'
              onClick={() => setActiveStep(1)}
            />

            {/* Step 2 Image */}
            <img
              src={activeStep === 2 ? images.step2Expanded : images.step2}
              alt='Step 2'
              className='md:cursor-pointer'
              onClick={() => setActiveStep(2)}
            />

            {/* Step 3 Image */}
            <img
              src={activeStep === 3 ? images.step3Expanded : images.step3}
              alt='Step 3'
              className='md:cursor-pointer'
              onClick={() => setActiveStep(3)}
            />
          </div>
          <div className='flex md:hidden flex-col'>
            <img className='py-2' src={images.step1Mobile} alt='' />
            <img className='py-2' src={images.step2Mobile} alt='' />
            <img className='py-2' src={images.step3Mobile} alt='' />
          </div>

          {/* Content Display */}
          {/* {renderContent()} */}
        </div>
      </div>
      <div className='bg-[#F8F9FF]'>
        <div className='container-fluid md:container mx-auto md:mb-16 py-8 md:py-8'>
          <h2 className='text-2xl md:text-[2.375rem] md:leading-3 md:mt-24 mb-2 md:mb-6 text-center font-bold text-[#1133BB]'>
            Why it’s safe.
          </h2>
          <p className='hidden md:block text-[1.375rem] leading-7 md:text-[1.75rem] md:leading-3 md:mt-8 md:mb-6 text-center font text-[#1133BB]'>
            Member privacy is important to us.
          </p>
          <p className='md:hidden block text-[1.375rem] leading-7 md:text-[1.75rem] md:leading-3 md:mt-8 md:mb-6 text-center font text-[#1133BB]'>
            Member privacy is important <br /> to us.
          </p>
          <p className='text-[1.375rem] leading-7 md:text-[1.75rem] mt-4 md:mt-16 md:mb-6 text-center font-medium text-[#00C389]'>
            We’re serious about security and protecting our members
          </p>
          <div className='my-8 md:my-20'>
            <div className='flex flex-col md:flex-row items-center mx-auto p-4 max-w-5xl'>
              <div className='w-full md:w-1/4 flex justify-center md:justify-center mb-4 md:mb-0'>
                <img
                  src={images.privacyIcon1}
                  alt='Description'
                  className='w-[70px] object-cover'
                />
              </div>

              {/* Text Section (3/4th on Desktop, Full Width on Mobile) */}
              <div className='w-full md:w-3/4 items-center md:items-start md:ml-4 text-center md:text-left'>
                {/* Heading and Description Wrapper */}
                {/* <div className="flex flex-col md:flex-row items-center"> */}
                {/* Heading */}
                <h2 className='text-xl font-bold md:mr-4 leading-8'>
                  We don’t store card details
                </h2>
                <p className='text-xl mt-2 md:mt-0 leading-8'>
                  We don’t store any credit or banking information. All credit
                  or debit card information is stored with Spreedly, a leading
                  platform that supports billions of global transactions via
                  their highly secure PCI-compliant vault systems.
                </p>
                {/* </div> */}
                {/* Description */}
              </div>
            </div>
            <div className='flex flex-col md:flex-row items-center mx-auto p-4 max-w-5xl'>
              <div className='w-full md:w-1/4 flex justify-center md:justify-center mb-4 md:mb-0'>
                <img
                  src={images.privacyIcon2}
                  alt='Description'
                  className='w-[70px] object-cover'
                />
              </div>

              {/* Text Section (3/4th on Desktop, Full Width on Mobile) */}
              <div className='w-full md:w-3/4 items-center md:items-start md:ml-4 text-center md:text-left'>
                {/* Heading and Description Wrapper */}
                {/* <div className="flex flex-col md:flex-row items-center"> */}
                {/* Heading */}
                <h2 className='text-xl font-bold md:mr-4 leading-8'>
                  We don’t sell the data
                </h2>
                <p className='text-xl mt-2 md:mt-0 leading-8'>
                  We don’t share any personal information for marketing purposes
                  – all data is anonymized so that it cannot be identified.
                </p>
                {/* </div> */}
                {/* Description */}
              </div>
            </div>
            <div className='flex flex-col md:flex-row items-center mx-auto p-4 max-w-5xl'>
              <div className='w-full md:w-1/4 flex justify-center md:justify-center mb-4 md:mb-0'>
                <img
                  src={images.privacyIcon3}
                  alt='Description'
                  className=' w-[70px] object-cover'
                />
              </div>

              {/* Text Section (3/4th on Desktop, Full Width on Mobile) */}
              <div className='w-full md:w-3/4 items-center md:items-start md:ml-4 text-center md:text-left'>
                {/* Heading and Description Wrapper */}
                {/* <div className="flex flex-col md:flex-row items-center"> */}
                {/* Heading */}
                <h2 className='text-xl font-bold md:mr-4 leading-8'>
                  We are secure
                </h2>
                <p className='text-xl mt-2 md:mt-0 leading-8'>
                  Spreedly uses bank-level security to ensure member information
                  is safe and secure. They are compliant with 3-D security to
                  prevent unauthorized access to member accounts, so their
                  wallet is safe.
                </p>
                {/* </div> */}
                {/* Description */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid md:container flex flex-col md:flex-row items-center md:items-center justify-center md:space-x-[5rem] mx-auto'>
        {/* Image Section */}
        <div className='w-full md:w-1/2 flex justify-center md:justify-start'>
          <img
            src={images.PersonalisedWebsite}
            alt=''
            className='w-[90%] h-auto'
          />
        </div>

        {/* Text Content Section */}
        <div className='w-full md:w-1/2 mt-8 md:mt-0 flex flex-col items-center md:items-start'>
          <h2 className='text-2xl md:text-[2.375rem] md:leading-3 md:mt-5 mb-2 md:mb-6 text-center md:text-left font-bold text-[#1133BB]'>
            Personalized website
          </h2>
          <p className='text-[1.125rem] leading-6 text-center md:text-left md:w-[90%] mt-4 md:mt-8'>
            As part of your program, you will also receive a fully personalized,
            branded website that serves as a powerful tool to highlight the
            unique features and exclusive rewards of your cashback app.
            <br />
            <br />
            This website will allow you to effectively engage your customer
            base, enhance brand visibility, and communicate the valuable
            benefits you provide.
          </p>

          <Button
            primary
            className='px-10 my-6 mt-12 w-full md:w-[14rem] h-[3.75rem]'
            onClick={() => navigate('/signup')}
          >
            <span className='stroke-white hover:stroke-primary'>
              {Icons.rocket('currentColor')}
            </span>
            <span>Get Started</span>
          </Button>
        </div>
      </div>

      <div className='bg-[#1133BB] w-full mx-auto center flex'>
        <h2 className='center mx-auto text-[38px] py-6 text-white font-bold'>
          Go-Live.
        </h2>
      </div>
      <p className='px-4 md:px-0 text-[1.125rem] leading-6 my-8 md:w-[70%] mx-auto md:my-16 font-semibold md:font-medium md:text-[27px] md:leading-[37px] text-center'>
        Easily launch and manage end-to-end app release configured to your
        specific brand look with this turnkey solution.
      </p>

      {/* New Section */}
      <div className='mx-4 md:mx-8 mb-12'>
        <div className='flex items-start md:justify-center'>
          <div className='flex flex-col md:flex-row justify-center md:hap-0 gap-4'>
            {/* Copy from here */}

            {/* Card 1 */}
            <div className='ml-4 mb-4 md:mb-0 md:mx-8'>
              <div className='flex flex-row items-start gap-4 md:gap-0'>
                <div className='text-4xl font-bold mb-2 text-[#0099FF] px-1 md:px-0'>
                  01
                </div>
                <div className='flex flex-col justify-center mx-auto'>
                  <div className='border-l-[4px] border-dashed border-black h-[13rem] md:h-[10rem] mx-auto'></div>

                  {/* Image */}
                  <img
                    src={images.GoLive1} // Replace with your image source
                    alt='Example'
                    className='mt-2 w-[5.5rem] hidden md:block'
                  />
                </div>
                <div className='px-4 md:px-0 flex flex-col gap-3 md:gap-0 md:w-[10rem] ml-0'>
                  <img
                    src={images.GoLive1} // Replace with your image source
                    alt='Example'
                    className='mt-2 w-[5rem] block md:hidden'
                  />
                  <h2 className='text-2xl font-bold text-gray-800 md:mb-2'>
                    Register
                  </h2>
                  <p className='text-[#000000] font-medium font-base text-[12px]'>
                    Complete the profile basic information, select the relevant
                    package and make the payment by credit card.
                  </p>
                </div>
              </div>
            </div>
            {/* Card 2 */}
            <div className='ml-4 mb-4 md:mb-0 md:mx-8'>
              <div className='flex flex-row items-start gap-4 md:gap-0 '>
                <div className='text-4xl font-bold mb-2 text-[#0099FF]'>02</div>
                <div className='flex flex-col justify-center mx-auto'>
                  <div className='border-l-[4px] border-dashed border-black h-[13rem] md:h-[10rem] mx-auto'></div>

                  {/* Image */}
                  <img
                    src={images.GoLive2} // Replace with your image source
                    alt='Example'
                    className='mt-2 w-[5.5rem] hidden md:block'
                  />
                </div>
                <div className='px-4 md:px-0 flex flex-col gap-3 md:gap-0 md:w-[10rem] ml-0'>
                  <img
                    src={images.GoLive2} // Replace with your image source
                    alt='Example'
                    className='mt-2 w-[5rem] block md:hidden'
                  />
                  <h2 className='text-2xl font-bold text-gray-800 md:mb-2'>
                    Activate
                  </h2>
                  <p className='text-[#000000] font-medium font-base text-[12px]'>
                    Upload the company logo, select colors and other settings
                    and submit for approval.
                  </p>
                </div>
              </div>
            </div>
            {/* Card 3 */}
            <div className='ml-4 mb-4 md:mb-0 md:mx-8'>
              <div className='flex flex-row items-start gap-4 md:gap-0 '>
                <div className='text-4xl font-bold mb-2 text-[#0099FF]'>03</div>
                <div className='flex flex-col justify-center mx-auto'>
                  <div className='border-l-[4px] border-dashed border-black h-[13rem] md:h-[10rem] mx-auto'></div>

                  {/* Image */}
                  <img
                    src={images.GoLive3} // Replace with your image source
                    alt='Example'
                    className='mt-2 w-[5.5rem] hidden md:block'
                  />
                </div>
                <div className='px-4 md:px-0 flex flex-col gap-3 md:gap-0 md:w-[10rem] ml-0'>
                  <img
                    src={images.GoLive3} // Replace with your image source
                    alt='Example'
                    className='mt-2 w-[5rem] block md:hidden'
                  />
                  <h2 className='text-2xl font-bold text-gray-800 md:mb-2'>
                    Review
                  </h2>
                  <p className='text-[#000000] font-medium font-base text-[12px]'>
                    Submission will be reviewed and we will contact you if
                    further information is required.
                  </p>
                </div>
              </div>
            </div>
            {/* Card 4 */}
            <div className='ml-4 mb-4 md:mb-0 md:mx-8'>
              <div className='flex flex-row items-start gap-4 md:gap-0'>
                <div className='text-[2.1rem] font-bold mb-2 text-[#0099FF]'>
                  04
                </div>
                <div className='flex flex-col justify-center mx-auto'>
                  <div className='border-l-[4px] border-dashed border-black h-[13rem] md:h-[10rem] mx-auto'></div>

                  {/* Image */}
                  <img
                    src={images.GoLive4} // Replace with your image source
                    alt='Example'
                    className='mt-2 w-[5.5rem] hidden md:block'
                  />
                </div>
                <div className='px-4 md:px-0 flex flex-col gap-3 md:gap-0 md:w-[10rem] ml-0'>
                  <img
                    src={images.GoLive4} // Replace with your image source
                    alt='Example'
                    className='mt-2 w-[5rem] block md:hidden'
                  />
                  <h2 className='text-2xl font-bold text-gray-800 md:mb-2'>
                    Go-Live
                  </h2>
                  <p className='text-[#000000] font-medium font-base text-[12px]'>
                    You will be notified once your program is live and the app
                    is available for download.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr className='bg-[#1133BB] w-full h-1 md:mb-6' />

      <div className='bg-[#F8F9FF]'>
        <div className='w-full flex justify-end'>
          {/* Left Section (Text) */}
          <div className='flex flex-col-reverse md:flex-row items-center'>
            <div
              className='w-full md:w-1/2 p-4 md:ml-[7rem]'
              id='partner-with-us'
            >
              <h2 className='text-2xl md:text-[2.375rem] md:leading-10 my-4 md:my-8 font-bold text-[#1133BB]'>
                Partner with us.
              </h2>

              <p className='text-[1.25rem] leading-[31px]  text-[#2D2D2D] font-bold md:w-[90%]'>
                Monetize your customer database to its full potential and help
                them improve their daily savings. <br /> It is a win-win all
                around.
              </p>
              <br />
              <p className='text-[1.25rem] leading-[31px] font-[400] text-[#2D2D2D] md:w-[90%]'>
                Boost your brand and foster customer loyalty with a co-branded
                cashback app that offers your customers valuable cashback
                rewards, helping them make their dollars go further. Strengthen
                your bottom line by engaging customers through a rewarding and
                interactive shopping experience.
              </p>
              <Button
                primary
                className='px-10 my-8 mt-12 w-full md:w-[14rem] h-[3.75rem]'
                onClick={() => navigate('/signup')}
              >
                <span className='stroke-white hover:stroke-primary'>
                  {Icons.rocket('currentColor')}
                </span>
                <span>Get Started</span>
              </Button>
            </div>

            {/* Right Section (Image) */}
            <div className='w-full md:w-1/2 flex justify-end'>
              <img
                src={images.b2bBanner4}
                alt='Banner 1'
                className='w-[40rem] pt-[10rem] h-auto object-cover mt-[-9rem]'
              />
            </div>
          </div>
        </div>
      </div>
      <div id='pricing'></div>
      <PricingSection />
      {/* <ClientReview /> */}
      <QuestionsDropdown />
      <GetInTouchForm
        title={`Let's get in touch`}
        paragraph='Got questions about the Savers App? Our team is here to help. Contact
          us for quick and friendly support.'
      />
      <Footer />
    </>
  );
};

export default B2BLanding;
