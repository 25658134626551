import React from 'react';
import Button from '../button/Button';
import { Icons } from '../../assets/SVG-Icons';
import { useLocation, useNavigate } from 'react-router';

interface Props {
  toggleMenu: () => void;
  isMenuOpen: boolean;
}
function AuthButtons({ toggleMenu, isMenuOpen }: Props) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const localUserData: any = localStorage.getItem('userDetails');
  const userDetails = JSON.parse(localUserData);
  return (
    <>
      {userDetails ? (
        <h5
          className='text-[1.25rem] text-primary font-semibold hover:underline cursor-pointer whitespace-nowrap'
          onClick={() => navigate('/dashboard')}
        >
          {userDetails
            ? `${userDetails?.firstName} ${userDetails?.lastName}`
            : ''}
        </h5>
      ) : (
        pathname !== '/signup' && (
          <div className='flex flex-col-reverse md:flex-row gap-4'>
            <button
              onClick={() => {
                if (isMenuOpen) {
                  toggleMenu();
                }
                navigate('/signin');
              }}
              className='text-center whitespace-nowrap font-semibold border-[3px] border-secondary-400 rounded-[10px] smd:px-6 xmd:px-8 py-2 xmd:py-3 px-[4.75rem] md:px-20 border-secondary text-secondary'
            >
              LOG IN
            </button>
            <Button
              primary
              className='px-[3rem] md:px-6 xmd:px-8'
              onClick={() => navigate('/signup')}
            >
              <span className='stroke-white hover:stroke-primary'>
                {Icons.rocket('currentColor')}
              </span>
              <span>Get Started</span>
            </Button>
          </div>
        )
      )}
    </>
  );
}

export default AuthButtons;
